import React from 'react'
import { Link } from 'react-router-dom'
function Airsterilizer() {
    return (
        <div className='vid'>
            <div className="aidetails mt-0 mt-md-0 pb-2 ">
                <div className="container mt-0 pt-0 pt-md-0" >
                    <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                        <div className="container mt-5 pt-20 pt-md-0">
                            <div className="row ">
                                <div className="col-lg-5 me-0 me-md-5">
                                    <img className='img-fluid pt-2' src='https://e.huawei.com/-/mediae/EBG/Images/ProductV2/fixed-network/transport/e6600/e6600.png' data-aos="fade-right" data-aos-duration="2000" />
                                </div>
                                <div className="col-lg-6 mt-2">
                                    <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Huawei OptiXtrans E6600</h1>
                                    <h1 className='font-semibold pt-3' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">MS-OTN Platform</h1>
                                    <h2 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '18px' }} data-aos="fade-down" data-aos-duration="2000">Simplified, ultra-broadband, and intelligent.</h2>
                                    <p className='pt-2' data-aos="fade-right" data-aos-duration="2000">Based on the MS-OTN architecture, the highly integrated optical-electrical convergence platform supports access of PCM, PDH, SDH, PKT and OTN services. With powerful unified cross-connect capabilities, the platform implements integrated multi-service bearing. The OptiXtrans E6600 is capable of evolving to the fgOTN technology defined by the ITU-T.</p>
                                    <p className='pt-3' data-aos="fade-right" data-aos-duration="2000">This MS-OTN Platform is an efficient transport solution for industries, in particular the energy, transportation, government, and banking sectors.</p>
                                    <div className="w-full md:w-3/4">
                                        <ul>
                                            <div data-aos="fade-right" data-aos-duration="2000">
                                                <li className="mb-1">
                                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Simplified: 5-in-1</strong>
                                                    <p className="text-sm ">5 in 1，PCM/PDH/SDH/PKT/OTN</p>
                                                </li>
                                            </div>
                                            <div data-aos="fade-right" data-aos-duration="2500">
                                                <li className="mb-1">
                                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Ultra-Broadband: High Bandwidth</strong>
                                                    <p className="text-sm ">2 Mbit/s ~ 100 Gbit/s services access</p>
                                                </li>
                                            </div>
                                            <div data-aos="fade-right" data-aos-duration="2600">
                                                <li className="mb-1">
                                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  Intelligent: Proactive O&M</strong>
                                                    <p className="text-sm ">Optical network health assurance & intelligent incident management</p>
                                                </li>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="aidetails  pb-5 pt-md-0">
                        <div className="container  pt-md-0">
                            <div className="row mt-5 ">
                                <div className="col-lg-6 pt-5">
                                    <h1 className='font-semibold pt-3' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Huawei OptiXtrans E9600</h1>
                                    <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Intelligent Optical Transmission Platform</h1>
                                    <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '18px' }} data-aos="fade-down" data-aos-duration="2000">Ultra-broadband, simplified, and intelligent.</h1>

                                    <p className='pt-3' style={{ color: '#1E1E1E', textAlign: 'justify' }} data-aos="fade-right" data-aos-duration="2000">Huawei OptiXtrans E9600 is a large-capacity, intelligent, optical-electrical integrated, next generation Multi-Service Optical Transport Network (MS-OTN) platform for enterprise. The new-generation terabit cross-connect platform can process OTN, Virtual Circuit (VC), and packet services of different granularities . High integration, and low power consumption, access of services is supported at any rate, from 100 Mbit/s to 400 GE. Per-wavelength capacity can be adjusted between 100 Gbit/s to 800 Gbit/s as needed. AI-enabled Operations and Maintenance (O&M) achieves autonomous-driving networks to cope with the increase in network scale and O&M complexity.</p>
                                    <ul>
                                        <div data-aos="fade-right" data-aos-duration="2000">
                                            <li className="mb-1">
                                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Ultra-Broadband: New Rate + New Spectrum</strong>
                                                <p className="text-sm mb-1">Optical+Electrical flexible grooming, creating a 3D-mesh high-speed interconnection network.</p>
                                            </li>
                                        </div>
                                        <div data-aos="fade-right" data-aos-duration="2500">
                                            <li className="mb-1">
                                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Simplified: MS-OTN Platform</strong>
                                                <p className="text-sm mb-1">Simplifying network architecture and improving bandwidth utilization.</p>
                                            </li>
                                        </div>
                                        <div data-aos="fade-right" data-aos-duration="2600">
                                            <li className="mb-1">
                                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Intelligent O&M</strong>
                                                <p className="text-sm mb-1">Shifting O&M mode from reactive to proactive, improving service quality, and reducing OPEX.</p>
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                                <div className="col-lg-5  me-0 me-md-5">
                                    <img className='img-fluid ' src='https://e.huawei.com/-/mediae/EBG/Images/ProductV2/fixed-network/transport/E9600/OptiXtrans-E9600.png' data-aos="fade-left" data-aos-duration="2000" />
                                </div>
                            </div>
                        </div>

                    </div>
           

                   

                </div>


            </div>
            
        </div>
    )
}

export default Airsterilizer
