import React from 'react';
import { Link } from 'react-router-dom'

function SolarandEV() {
  return (
    <div className='vid'>
      <div className="aimainsp5 container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">UAVs (Unmanned Aerial Vehicles)</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">Explore the fascinating world of UAVs (Unmanned Aerial Vehicles) through our website, dedicated to showcasing the latest advancements and applications in drone technology. Discover how we design and manufacture UAVs equipped with the latest technology for surveillance, combat, and commercial use. Our drones ensure superior performance and reliability, incorporating cutting-edge innovations to meet the highest standards in the industry.</p>
                        <div className="container mt-8" >
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/drone-delivery.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Commercial Drones</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/camera-drone.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Consumer Drones</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/ye.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Security Drones</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-0 mt-md-5 pb-5 pt-md-0">
                <div className="container mt-0 mt-md-5" >
                    <div className="row mt-5 pt-5" data-aos="fade-left" data-aos-duration="2000">
                        <div className="col-lg-6 mt-5 pt-5 order-2 order-lg-1 text-center text-lg-start">   
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Drone Technology & Innovation</h1>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>From state-of-the-art surveillance systems and high-performance combat drones to versatile commercial applications, our content highlights the remarkable technological strides being made in the drone industry.</p>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Discover the forefront of drone technology and innovation through our dedicated platform.We delve into the latest advancements in UAV (Unmanned Aerial Vehicle) design and functionality, showcasing how cutting-edge developments are transforming industries and enhancing capabilities across various sectors. </p>
                            <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5" ><Link to='/Drone-Technology'>Discover</Link></button>
                        </div>
                        <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5 order-1 order-lg-2 text-center text-lg-start">
                            <img className='img-fluid' src='/dronemain.png'/>
                        </div>
                    </div>
                    <div className="row mt-5 pt-5" data-aos="fade-right" data-aos-duration="2000">
                        <div className="col-lg-5 mt-5 pt-5 me-0 me-md-5" >
                        <div className="video-container" >
                    <iframe src='https://www.youtube.com/embed/KuF03h8x9Po?si=dNbRQwiXDx2ZuHw9' className="responsive-iframe" allowfullscreen data-aos="fade-down" data-aos-duration="2000" autoplay ></iframe>
                </div>
                        </div>
                        <div className="col-lg-6 mt-5 pt-5 order-2 order-lg-1 text-center text-lg-start"> 
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Commercial Drones: Welcoming Future with Hi-Tech Delivery Solutions</h1>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Embrace the future of logistics with our hi-tech delivery drones, setting new standards in the industry and paving the way for a more efficient and connected world.</p>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Discover how our drones are being utilized to streamline supply chains, reduce delivery times, and lower operational costs. From e-commerce giants to local businesses, our UAVs provide innovative solutions that meet the demands of modern consumers and businesses alike.</p>
                             <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5" ><Link to='/Automotives'>Discover</Link></button>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="smartcity container-fluid">   
                <div className=" row mt-5 pt-5">
                    <div className="container">
                        <div className="row">

                        
                        <div className="col-lg-6 mt-0 mt-md-5 pt-0 pt-md-3 pb-3 order-2 order-lg-1 text-center text-lg-start" data-aos="fade-right" data-aos-duration="2500">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }}>Consumer Drones: Fulfilling Aerial Experience</h1>
                            <p className='pt-3'style={{color:'white', textAlign:'justify'}}>Explore a diverse range of drones designed for every skill level, from beginner-friendly models to advanced aerial platforms. </p>
                            <p className='pt-3 pb-3' style={{color:'white',textAlign:'justify'}}>Immerse yourself in stunning panoramic views, capture cinematic footage, or simply enjoy the exhilarating sensation of flight. Whether you're exploring scenic landscapes, documenting special moments, or unleashing your creativity from above, our drones empower you to elevate your perspective and unlock new possibilities.</p>
                            <Link to='/Consumer-Drones' className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5 mb-5">Discover</Link>
                        </div>
                        <div className="col-lg-5 mt-3 pb-5 ms-0 ms-md-5 order-1 order-lg-2 text-center text-lg-start">
                            <img className='img-fluid' src='/consumer.jpg' data-aos="fade-left" data-aos-duration="2500"/>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
                <div className="aidetails mt-0 mt-md-5  pb-1  pb-5">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5">
                        <img className='img-fluid' src='/defensedrone.png' data-aos="fade-right" data-aos-duration="2000"/>
                        </div>
                        <div className="col-lg-6 pt-3 text-center text-lg-start">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Security Surveillance & Combat Drones</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">Experience the pinnacle of security and defense technology with our state-of-the-art surveillance and combat drones. Designed to meet the most demanding security challenges, our drones offer unparalleled capabilities in monitoring, reconnaissance, and protection.
                            With advanced combat capabilities, our drones are at the forefront of defense technology. From precision strikes to tactical reconnaissance, our combat drones deliver unmatched performance and reliability in the most challenging environments. Equipped with sophisticated weaponry and autonomous capabilities, they provide strategic advantages on the battlefield.</p>
<button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3" ><Link to='/AutonomousAI/applications'>Discover</Link></button>
                        </div>
                    </div>
                </div>

            </div>
                
                
    </div>
  )
}

export default SolarandEV

