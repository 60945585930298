import React from 'react'

function autoarticle() {
  return (
    <div> 
      <div className="aimainsp8 container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5 pb-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">What Is a Smart City? How AI Is Going Uptown Around the Globe</h1>
                        <p className='pt-3' style={{ color: 'white' ,textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">Every city aims to be a great place to live, often embracing broad initiatives to connect citizens to the latest 5G and fiber optic networks, expand digital literacy, and enhance digital services. Many agree that a significant part of being smart involves using technology to make cities more self-aware, automated, and efficient.</p>
                        
                    </div>
                </div>
                
            </div>
        

            

            <div className="container mt-3 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">
                    
                    <div className="col-lg-8 mt-2">
                        <h1 className='font-semibold pt-2 pb-3' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">What is a Smart City?</h1>
                        <p className='bordercolor  mb-5 border-start ps-3' style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000"><i>“We define a smart city as a place applying advanced technology to improve the quality of life for people who live in it,”</i> said Sokwoo Rhee, an associate director for cyber-physical systems innovation at the U.S. National Institute of Standards and Technology (NIST), who has worked on more than 200 smart city projects in 25 countries.</p>
                    
                        <p className='pt-5' data-aos="fade-left" data-aos-duration="2000"  style={{textAlign:'justify'}}>A smart city is typically a kind of municipal Internet of Things—a network of cameras and sensors that can see, hear, and even smell. These sensors, especially video cameras, generate massive amounts of data that can serve many civic purposes, such as helping traffic flow smoothly. Cities around the globe are turning to AI to sift through that data in real time for actionable insights. Increasingly, smart cities build realistic 3D simulations of themselves, known as digital twins, to test out future scenarios.</p>
                    </div>
                    
                </div>
            </div>
            <div className="container mt-2  pt-md-0">
                <div className="row pt-5 justify-content-center">
                    
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">U.S., London Issue Smart Cities Guidebooks</h1>
                        <p className='pt-4 pb-5' data-aos="fade-left" data-aos-duration="2000"  style={{textAlign:'justify'}}>At NIST, Rhee oversees work on a guide for building smart cities, which will eventually include reports on issues and case studies in more than two dozen areas, from public safety to water management systems. London has a 60-page document detailing its ambitious smart city goals. Like other smart cities from Dubai to San Jose in Silicon Valley, it’s a metro-sized work in progress.</p>
                        <img className='img-fluid' src='https://i.imgur.com/sUemMb6.jpeg' data-aos="fade-down" data-aos-duration="2500"/>
                    </div>
                    
                </div>
            </div>
            
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">
                    
                    <div className="col-lg-8  pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Living Labs for AI</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000"  style={{textAlign:'justify'}}>In a way, smart cities are both comprehensive technology hubs and living labs. They host everything from air-quality monitoring systems to repositories of data cleared for use in shared AI projects. The London Datastore, for example, already contains more than 700 publicly available datasets. One market researcher tracks 13 broad areas that define a smart city, from smart streetlights to connected garbage cans.</p>
                        <img className='img-fluid pt-4' src='https://i.imgur.com/jyxZwG8.jpeg' data-aos="fade-down" data-aos-duration="2500"/>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000"  style={{textAlign:'justify'}}>Because it’s broad, it’s also a big market. Market watchers at Navigant Research expect the global market for smart-city gear to grow from $97.4 billion in annual revenue in 2019 to $265.4 billion by 2028, at a compound annual growth rate of 11.8 percent. However, it's still early days. In a January 2019 survey of nearly 40 U.S. local and state government managers, more than 80 percent thought a municipal Internet of Things would have a significant impact on their operations, but most were still in a planning phase, with less than 10 percent having active projects.</p>
                    </div>
                    
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Smart Streetlights for Smart Cities</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000"  style={{textAlign:'justify'}}>Streetlights are expected to be an important part of tomorrow’s smart city infrastructure. Currently, only a few hundred are outfitted with various mixes of sensors and Wi-Fi and cellular base stations. However, the big wave is yet to come as the estimated 360 million posts around the world slowly upgrade to energy-saving LED lights.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Air Traffic Control and Smart City Initiatives</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000"  style={{textAlign:'justify'}}>Many smart cities start with traffic control. London uses digital signs to post speed limits that change to optimize traffic flow and employs license-plate recognition to charge tolls for entering a low-emission zone in the city center. Cities in Belgium and France are considering similar systems, and Singapore is testing autonomous shuttles on a 5.7-mile stretch of its streets. Nearby, Jakarta uses a traffic-monitoring system that taps AI to recognize license plates of vehicles with unpaid taxes.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                    <img className='img-fluid pt-3 pb-3' src='https://i.imgur.com/6dUNKAV.png' data-aos="fade-down" data-aos-duration="2500"/>
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Drones to the Rescue in Maryland</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000"  style={{textAlign:'justify'}}>Even smaller cities can become smart. Seat Pleasant, Maryland, a Washington, D.C. suburb of less than 5,000 people, launched a digital hub for city services in August 2017. Since then, it has installed intelligent lighting, connected waste cans, home health monitors, and video analytics to save money, improve traffic safety, and reduce crime. It's also become the first U.S. city to use drones for public safety, including plans for life-saving delivery of emergency medicines.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Simulating a New Generation of Smart Cities</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000"  style={{textAlign:'justify'}}>Some smart cities are building digital twins, 3D simulations that serve many purposes. Zurich and Nashville, for example, will let citizens and city officials don goggles at virtual town halls to see simulated impacts of proposed developments. Cities like Singapore and Rennes, France, have built digital twins to study walkability and other urban planning challenges.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Europe Keeps an AI on Traffic</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000"  style={{textAlign:'justify'}}>European cities are actively incorporating AI into their traffic management systems. For instance, London’s dynamic speed limit signs and toll charges for low-emission zones are just the beginning. Cities in Belgium and France are exploring similar initiatives, while Singapore and Jakarta use AI for traffic monitoring and managing autonomous shuttles.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-5">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Conclusion</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000"  style={{textAlign:'justify'}}>Smart cities are evolving rapidly, driven by advances in AI and IoT technologies. From large metropolises to small towns, the drive to improve urban living through technology is transforming cities into more efficient, sustainable, and livable spaces. The integration of AI in traffic management, public safety, and urban planning is just the beginning of this global movement towards smarter cities.</p>
                    </div>
                </div>
            </div>

        </div>
            

  )
}

export default autoarticle
