import React from 'react';
import './style.css'; // Importing CSS file

const Project = () => {
    return (
        <div className="flex flex-col-reverse md:flex-row">
    {/* Block 2 */}
    <div className="relative md:w-1/2">
        <div className="absolute left-0 bottom-0 bg-orange-500 p-4 flex flex-col justify-center items-center md:p-8" style={{ height: '270px', width: '380px', maxWidth: '90%' }}>
            
            <h3 className="text-white text-3xl font-semibold mb-2">
            <div data-aos="zoom-in" data-aos-duration="1500">
                <img src='/system.png' alt='pro' className='inline' style={{ height: '70px', width: '70px' }}/> 6,123 Project
                </div>
            </h3>
            <div data-aos="zoom-in" data-aos-duration="2000">
            <p className="text-white text-sm">From groundbreaking innovations to intricate solutions, our track record of completion underscores our ability to surpass expectations and achieve tangible results.</p>
            </div>
        </div>

        <img src="/bg-pro.png" alt="Background" className="w-full h-full object-cover header-container" style={{ height: 'auto', width: '100%' }} />
    </div>

    {/* Block 1 */}
    <div className="p-4 bg-orange-500 md:w-1/2">
    <div data-aos="fade-down" data-aos-duration="2500">
        <p className="text-white text-lg mb-2 mt-5">World's Leading Industry Corporation!</p>
        <h2 className="text-white text-3xl font-bold mb-4">Providing Innovative and Sustainable Solutions</h2>
        <p className="text-white">Our dedicated team of experts is driven by innovation, constantly pushing the boundaries of technology to deliver cutting-edge solutions tailored to meet the evolving needs of our clients. With a customer-centric approach, we strive to forge long-lasting partnerships built on trust, reliability, and exceptional quality.</p>
        </div>
        <div className="container mt-8">
    <div className="flex flex-wrap justify-center md:justify-between text-orange-500 font-semibold text-center">
    <div data-aos="zoom-in" data-aos-duration="1000">
        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0" style={{ width: '200px', height: '200px' }}>
            <img src="/quality.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
            <p className="text-xl"> Quality</p>
        </div>
        </div>
        <div data-aos="zoom-in" data-aos-duration="1500">
        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0" style={{ width: '200px', height: '200px' }}>
        
            <img src="/innovation.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
            <p className="text-xl">Innovation</p>
            </div>
        </div>
        <div data-aos="zoom-in" data-aos-duration="3000">
        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0" style={{ width: '200px', height: '200px' }}>
       
            <img src="/reliabile.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
            <p className="text-xl">Reliability</p>
            </div>
        </div>
    </div>
</div>


    </div>
</div>

    );
};

export default Project;
