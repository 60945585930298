import React from 'react';
import { Link } from 'react-router-dom';

function VisionAI() {
    return (
        <div className='vid'>
            <div className="aimain container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Embarking a new Era with DeepAI Technology</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">Embrace the future  with cutting-edge 3D vision and AI technology. Our innovative solutions are transforming industries, enhancing efficiency, and unlocking new possibilities. Explore the power of Deep AI to drive unprecedented advancements in manufacturing, logistics, and beyond.</p>
                        <div className="container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-6">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/smart.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Smart City</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-6">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/retail.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Retail & Logistics</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-6">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/industrial.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Industrial Solutions </p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-6">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/healthcare.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Health Care </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container  pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">

                            <img className='img-fluid pt-3' src='https://i.imgur.com/PS7lxiI.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                            <img className='img-fluid ' src='https://i.imgur.com/sUemMb6.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />

                        </div>

                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Smart Cities & Spaces</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">Enhancing city infrastructure encompasses a multifaceted approach, targeting improvements in transportation, urban planning, sustainability, and technology integration. DeepAI, a leader in artificial intelligence and machine learning solutions, offers innovative tools and applications to facilitate these advancements.</p>
                            <div className="w-full md:w-3/4">
                        <ul>
                        <div data-aos="fade-right" data-aos-duration="2000">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Intelligent Traffic Systems</strong>
                                <p className="text-sm mb-1">Intelligent Traffic Systems (ITS) leverage artificial intelligence to optimize and manage urban traffic flow. </p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2500">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Access Control</strong>
                                <p className="text-sm mb-1"> AI-powered access control systems employ facial recognition, biometric authentication, and machine learning algorithms to enhance security and streamline the process of granting access.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Public Transit</strong>
                                <p className="text-sm mb-1">AI enhances public transit systems by optimizing routes, schedules, and resource allocation. By analyzing data from various sources such as passenger flow, traffic conditions, and historical patterns, AI algorithms can predict demand, reduce wait times, and improve service reliability.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Smart Buildings</strong>
                                <p className="text-sm mb-1">Smart buildings utilize AI to enhance operational efficiency, sustainability, and occupant comfort. AI-driven systems monitor and manage various building functions such as heating, ventilation, air conditioning (HVAC), lighting, and security. </p>
                            </li>
                            </div>
                        </ul>
                    </div>
                            <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000"><Link to='/SmartCity'>Read case study</Link></button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails pt-md-0 pt-3  pt-md-0 pb-5">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-6">
                            <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Retail and Logistics</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">Improving customer satisfaction, in-store analytics, and business efficiency through the integration of AI technologies offers businesses a powerful toolset to optimize operations and enhance the overall shopping experience.</p>
                            <div className="w-full md:w-3/4">
                        <ul>
                        <div data-aos="fade-right" data-aos-duration="2000">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Frictionless Checkout</strong>
                                <p className="text-sm mb-1">Frictionless checkout, enhanced by AI technologies, revolutionizes the retail experience by streamlining the payment process and eliminating traditional pain points for customers.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2500">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Loss Prevention</strong>
                                <p className="text-sm mb-1">Loss prevention, bolstered by AI advancements, is a critical aspect of retail security aimed at reducing theft, fraud, and operational errors. </p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Truck and Goods Routing</strong>
                                <p className="text-sm mb-1">Truck and goods routing, empowered by AI, revolutionizes logistics operations by optimizing routes, minimizing costs, and maximizing efficiency.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Inventory Management</strong>
                                <p className="text-sm mb-1">Inventory management, with the aid of AI, undergoes a transformative evolution, optimizing stock levels, reducing costs, and enhancing operational efficiency.</p>
                            </li>
                            </div>
                        </ul>
                    </div>
                            <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000"><Link to='/case-studies/Retail&AI'>Read case study</Link></button>
                            
                            {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000"><Link to='/3D-Vision-AI-Casestudy'>Read More</Link></button> */}
                        </div>
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3' src='https://i.imgur.com/fPAJ9xf.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                            <img className='img-fluid ' src='https://i.imgur.com/Z74uhKm.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container  pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">

                            <img className='img-fluid pt-3' src='https://d3lkc3n5th01x7.cloudfront.net/wp-content/uploads/2024/02/07022712/AI-for-manufacturing.png' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                            <img className='img-fluid ' src='https://neurosys.com/wp-content/webp-express/webp-images/uploads/2023/09/ai-manufacturing-predictive-maintenance.png.webp' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />

                        </div>

                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Industrial Solutions</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">Analyze industrial data from machines, systems, sensors, and industrial operations to detect abnormal events. Have trouble detecting anomalies on your equipment? We specialize in identifying such problems and providing industrial analytics software with predictive analytics to support you in data processing.</p>
                            <div className="w-full md:w-3/4">
                        <ul>
                        <div data-aos="fade-right" data-aos-duration="2000">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Understand your historical data</strong>
                                <p className="text-sm mb-1">Unlock the potential of your OT data and create a data-driven culture based on machine learning platform. </p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2500">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Identify abnormal events</strong>
                                <p className="text-sm mb-1">Analyze industrial data from machines, systems, sensors, and industrial operations to detect abnormal events.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Better process control</strong>
                                <p className="text-sm mb-1">Improve accuracy and engineering productivity in process quality control using artificial intelligence and ML technologies.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Predict equipment failure</strong>
                                <p className="text-sm mb-1">Combine data by utilizing complex algorithms and deep learning, this solution can reliably predict potential equipment failures before they occur, reducing downtime and improving productivity. </p>
                            </li>
                            </div>
                        </ul>
                    </div>
                            <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000"><Link to='/case-studies/industrial'>Read case study</Link></button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails pt-md-0 pt-3  pt-md-0 pb-5">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-6">
                            <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Healthcare & AI</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">Large Language Models (LLMs) and Generative Artificial Intelligence (AI) have captured the public imagination and have potential to drive significant change in healthcare.</p>
                            <div className="w-full md:w-3/4">
                        <ul>
                        <div data-aos="fade-right" data-aos-duration="2000">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Diagnosis</strong>
                                <p className="text-sm mb-1">AI’s prowess in harnessing large multimodal reservoirs of health data accelerates accurate diagnoses, thereby reducing misdiagnosis rates and easing clinician workload.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2500">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Precision Medicine</strong>
                                <p className="text-sm mb-1">With AI as an ally, precision medicine transitions from generic treatment models to a more patient-centric approach, managing extensive data sets to formulate personalized treatment plans, thus enhancing patient care and resource allocation.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Prediction Models</strong>
                                <p className="text-sm mb-1">Leveraging prediction models, clinicians can perform comparative analyses aiding in precise prognostics, which are instrumental in creating patient-specific care plans, mitigating risks, and optimizing resource utilization.</p>
                            </li>
                            </div>
                        </ul>
                    </div>
                            <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5 mb-5" data-aos="fade-down" data-aos-duration="2000"><Link to='/case-studies/healthcare'>Read case study</Link></button>
                            
                            {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000"><Link to='/3D-Vision-AI-Casestudy'>Read More</Link></button> */}
                        </div>
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3' src='https://www.future-processing.com/blog/wp-content/uploads/2022/09/AI_Healthcare-01.jpg' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                            <img className='img-fluid ' src='https://www.mckinsey.com/~/media/mckinsey/industries/healthcare%20systems%20and%20services/our%20insights/tackling%20healthcares%20biggest%20burdens%20with%20generative%20ai/tackling-1224658211-thumb-1536x1536.jpg?mw=677&car=42:25' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default VisionAI;
