import React from 'react'
import { Link } from 'react-router-dom'
function SpecializedElectornicsMainpage() {
  return (
    <div className='vid'>
        <div className="aimainsp4 container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Electronic Solutions</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">Unlock new possibilities with industry-tailored electronics solutions meticulously crafted to enhance efficiency and productivity. Whether you operate in automotive, aerospace, medical, or industrial automation sectors, our solutions are designed to seamlessly integrate into your operations and deliver unparalleled performance.</p>
                        <div className="container mt-8" >
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/circuit.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Custom Solutions</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/thunderbolt.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Hi-tech Solutions</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-0 pt-md-0 pt-20 pb-5 pt-md-5">
                <div className="container " >
                    <div className="row mt-0 mt-md-5 " data-aos="fade-left" data-aos-duration="2000">
                        <div className="col-lg-6 mt-5 order-2 order-lg-1 text-center text-lg-start">   
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Cutting-edge Embedded Systems: Powering Tomorrow's Technology</h1>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Explore our range of advanced embedded systems that offer powerful solutions for controlling technology in automotive, robotics, and consumer electronics.</p>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>These systems provide efficient processing capabilities, minimal power consumption, and robust operational reliability. Whether you are working on a personal project or developing large-scale industrial applications, our embedded systems are designed to meet diverse needs.</p>
                            <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3" ><Link to='/BMS-Solutions'>Discover</Link></button>
                        </div>
                        <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5 order-1 order-lg-2 ">
                            <img className='img-fluid' src='/pi.png'/>
                        </div>
                    </div>
                    <div className="row mt-5 pt-5" data-aos="fade-right" data-aos-duration="2000">
                        <div className="col-lg-5 mt-5 pt-5 me-0 me-md-5 order-1 order-lg-2">
                            <img className='img-fluid' src='/modem.png'/>
                        </div>
                        <div className="col-lg-6 mt-5 pt-5 order-2 order-lg-1 text-center text-lg-start"> 
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>State of Art Communication Modules</h1>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Experience the future of connectivity with our advanced communication modules, meticulously crafted to elevate your electronic applications.</p>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>From WiFi to Bluetooth and cellular capabilities, these modules are designed to seamlessly integrate into a diverse range of devices, from consumer electronics to enterprise systems. Stay ahead of the curve with cutting-edge technology that ensures reliable and efficient communication in any environment, empowering you to innovate without limits.</p>
                             <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3" ><Link to='/Routers/applications'>Discover</Link></button>
                        </div>
                    </div>
                    <div className="row mt-5 pt-5" data-aos="fade-left" data-aos-duration="2000">
                        <div className="col-lg-6 mt-5 pt-5 order-2 order-lg-1 text-center text-lg-start">   
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Hi-Tech Semi Conductors & Microchip Technology</h1>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Explore a diverse range of high-tech semiconductors meticulously engineered to power the latest advancements in electronics. </p>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>From cutting-edge microprocessors to advanced integrated circuits, our technology drives progress across industries, from automotive to telecommunications. Experience the transformative potential of our semiconductor solutions, designed to push the boundaries of what's possible in the digital age.</p>
                            <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3" ><Link to='/AirSterilizers'>Discover</Link></button>
                        </div>
                        <div className="col-lg-5 mt-md-5 mt-0 pt-0 pt-md-5 ms-0 ms-md-5 order-1 order-lg-2 text-center text-lg-start">
                            <img className='img-fluid' src='/semi.png'/>
                        </div>
                    </div>
                    <div className="row mt-5 pt-5" data-aos="fade-right" data-aos-duration="2000">
                        <div className="col-lg-5 mt-5 pt-5 me-0 me-md-5 order-1 order-lg-2 text-center text-lg-start">
                            <img className='img-fluid' src='/opto.png'/>
                        </div>
                        <div className="col-lg-6 mt-5 pt-5 order-2 order-lg-1 text-center text-lg-start"> 
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Optoelectronic Components</h1>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Discover our comprehensive range of optoelectronic components, bridging the gap between electrical signals and photon signals to revolutionize various applications.</p>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>From vibrant digital displays to precision medical equipment and seamless telecommunications, our cutting-edge components deliver unparalleled performance and reliability. Experience the power of light in transforming data transmission and visualization, unlocking new possibilities for your projects.</p>
                             <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3" ><Link to='/Optoelectronic/applications'>Discover</Link></button>
                        </div>
                    </div>
                </div>
                
            </div>
           
      
    </div>
  )
}

export default SpecializedElectornicsMainpage
