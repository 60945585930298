import Header from "./header";
import Special from "./special";
import AboutUs from "./about";
import Services from "./services";
import Project from "./project";
import Feature from "./freature";
import Testimonials from "./testimonials";
import Footer from "./Footer";
import Contactform from "./Contactform";
export default function Home() {
    return (
        <>
            <Header />
            <Special />
            <AboutUs/>
            <Services/>
            <Project/>
            <Feature/>
            <Testimonials/>
            <Contactform/>
            <Footer/>
        </>
    )
}