import React from 'react';
import { Link } from 'react-router-dom';

function VisionAI() {
  return (
    <div className='vid'>
      <div className="aimain container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-6 pt-5 mt-5">
            <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Revolutionize Industries with 3D Vision AI Technology</h1>
            <p className='pt-3' style={{ color: 'white',textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">Embrace the future of automation with cutting-edge 3D vision AI technology. Our innovative solutions are transforming industries, enhancing efficiency, and unlocking new possibilities. Explore the power of 3D vision AI to drive unprecedented advancements in manufacturing, logistics, and beyond.</p>
            <div className="container mt-8">
              <div className="row justify-content-center">
                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                  <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                    <img src="/deadline.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                    <p className="text-lg text-center">Speed</p>
                  </div>
                </div>
                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                  <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                    <img src="/simplicity.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                    <p className="text-lg text-center">Simplicity</p>
                  </div>
                </div>
                <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-4">
                  <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                    <img src="/reliable.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                    <p className="text-lg text-center">Reliability </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
        <div className="container  pt-20 pt-md-0">
          <div className="row ">
            <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
            <div className="video-container" >
            <iframe src='https://www.youtube.com/embed/nFGYqX_WkCg?si=fNVFTSa0JlzH_vqa' className="responsive-iframe" allowfullscreen data-aos="fade-down" data-aos-duration="2000" autoplay loop></iframe>
            </div>
            </div>

            <div className="col-lg-6 mt-1 order-1 order-lg-2">
              <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px',textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">AI-powered 3D inspection system delivers fast deployment, ease of use, and reliability</h1>
              <p className='pt-2' data-aos="fade-left" data-aos-duration="2000" style={{textAlign:'justify'}}>Backed by artificial intelligence, our product is a next-generation 3D vision system that allows manufacturers to perform highly reliable inspections. Using a combination of powerful optics, industry-proven vision technology, and embedded AI, the system captures detailed 3D images and analyzes depth information to detect subtle features and measure them against pass/fail thresholds.    </p>
            </div>
          </div>
        </div>
      </div>

      <div className="aidetails pt-md-0 pt-3 pb-1 pt-md-0">
        <div className="container pt-20 pt-md-0">
          <div className="row ">
            <div className="col-lg-6 text-center text-lg-start">
              <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px',textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">AI and 3D technology deliver reliable inspection results</h1>
              <p className='pt-2' data-aos="fade-left" data-aos-duration="2000" style={{textAlign:'justify'}}>Using advanced AI and 3D imaging technology, the In-Sight L38 identifies subtle and variable features, and measures variances against defined tolerances. With each use, the embedded AI learns and adapts, improving the ability to detect even previously unseen anomalies to ensure continuous improvement and operational efficiency.</p>
              <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000"><Link to='/3dvision/applications'>Read More</Link></button>
            </div>
            <div className="col-lg-5 me-0 me-md-5">
              <img className='img-fluid pt-3' src='/cog.gif' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
            </div>
          </div>
        </div>
      </div>

      <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
        <div className="container   pt-md-0">
          <div className="row justify-content-center">
            <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px',textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">Unique, high-powered laser line quickly scans parts for higher throughput</h1>
            <img className='img-fluid pt-3' src='https://i.imgur.com/TidoM3E.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
            <div className="w-full md:w-3/4">
                        <ul>
                        <div data-aos="fade-right" data-aos-duration="2000">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Speed</strong>
                                <p className="text-sm mb-1">Delivers fast acquisition rates to support high line speeds </p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2500">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Saftey</strong>
                                <p className="text-sm mb-1">Meets Class 2 safety standards, eliminating the need for expensive enclosures and allowing users to make adjustments without stopping the line.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  Performance</strong>
                                <p className="text-sm mb-1">Offers 5X more light than conventional sensors to enable image acquisition at greater distances.</p>
                            </li>
                            </div>
                        </ul>
                    </div>
            </div>

            <div className="col-lg-5 mt-0 order-1 order-lg-2">
            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Patented, speckle-free laser reduces optical noise to create high-contrast images </h1>
            <img className='img-fluid pt-3' src='https://i.imgur.com/62pIcST.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
            <div className="w-full md:w-3/4">
                        <ul>
                        <div data-aos="fade-right" data-aos-duration="2000">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Reliable</strong>
                                <p className="text-sm mb-1">Minimizes speckle and glare, common problems for 3D laser systems.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2500">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Resolution</strong>
                                <p className="text-sm mb-1">Captures higher resolution images than traditional laser displacement sensors.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  Quality</strong>
                                <p className="text-sm mb-1">Delivers the most consistent laser line in the industry for more reliable scanning.</p>
                            </li>
                            </div>
                        </ul>
                    </div>
            </div>
          </div>
        </div>
      </div>

      <div className="aidetails pt-md-0 pb-5 pt-3 pb-1 pt-md-0">
        <div className="container pt-20 pt-md-0">
          <div className="row ">
            <div className="col-lg-6">
              <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px',textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">Full suite of 2D and 3D tools meets a range of automation needs </h1>
              <p className='pt-2' data-aos="fade-left" data-aos-duration="2000" style={{textAlign:'justify'}}>Whether users need 3D depth perception for complex tasks or the simplicity and speed of 2D vision for standard applications, our product has both. The integration of 2D and 3D technology increases capabilities, while delivering detailed results, for flexible performance and reliable error-proofing. </p>
              
            </div>
            <div className="col-lg-5 me-0 me-md-5">
              <img className='img-fluid pt-5' src='https://i.imgur.com/2RbVgL5.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisionAI;
