import React from 'react'

function Underdevelopment() {
  return (
    <div className='development'>
      <div className="smartcity container-fluid">   
                <div className=" row mt-5 pt-5">
                    <div className="container">
                        <div className="row">

                        PzXOq74l3#
                        <div className="col-lg-6 mt-5 pt-5" data-aos="fade-right" data-aos-duration="2500">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }}>Innovative Precision: 3D and Laser Printing Solutions</h1>
                            <p className='pt-3'style={{color:'white', textAlign:'justify'}}>Experience the future of manufacturing with our cutting-edge 3D and laser printing solutions. Utilizing advanced additive manufacturing technologies, we bring your concepts to life with unparalleled precision and speed. </p>
                            <p className='pt-3' style={{color:'white',textAlign:'justify'}}>From intricate prototypes to functional components, our 3D printing capabilities enable limitless creativity and customization. Meanwhile, our state-of-the-art laser printing services offer unmatched accuracy and detail, perfect for intricate designs and engraving tasks. Harnessing the power of digital fabrication, we empower you to realize your visions with unrivaled efficiency and quality. Explore the possibilities of tomorrow today with our 3D and laser printing solutions.</p>
                            <button className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5 ">Discover 3D Printing Solutions</button>
                        </div>
                        <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                            <img className='img-fluid' src='/print.png' data-aos="fade-left" data-aos-duration="2500"/>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
    </div>
  )
}

export default Underdevelopment
