import React from 'react'
import { Link } from 'react-router-dom'
function Solar() {
  return (
    <div className='vid'>
      <div className="aimain container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500"> Batteries for Electric Vehicles</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">Energy storage systems, typically in the form of batteries, are vital for the operation of all-electric vehicles (EVs), plug-in hybrid electric vehicles (PHEVs), and hybrid electric vehicles (HEVs). These batteries provide the necessary power to drive electric motors, enhance performance through quick energy delivery, and enable features like regenerative braking, which captures and stores energy during deceleration. In all-electric vehicles, batteries are the sole power source, while in PHEVs and HEVs, they work alongside internal combustion engines to improve fuel efficiency and reduce emissions, thereby playing a crucial role in the overall functionality and environmental benefits of these advanced vehicles.</p>
                        <div className="container mt-8" >
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="https://i.imgur.com/IIDvEjp.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Lithium-Ion Batteries</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="https://i.imgur.com/OaKMFuZ.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Nickel-Metal Batteries</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="https://i.imgur.com/Asg2iEz.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Lead-Acid Batteries</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-5 mt-md-0  pb-5">
                <div className="container mt-5 pt-md-0" >
                    <div className="row mt-5 pt-5" data-aos="fade-left" data-aos-duration="2000">
                        <div className="col-lg-6 mt-5 pt-5">   
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Lithium-Ion Batteries</h1>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Lithium-ion batteries are widely used in portable consumer electronics like cell phones and laptops due to their high energy density, both by mass and volume, compared to other energy storage systems.</p>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}> They boast a high power-to-weight ratio, excellent energy efficiency, good performance at high temperatures, long lifespan, and low self-discharge rates. While most components of lithium-ion batteries are recyclable, the cost of material recovery poses a significant challenge for the industry. Today's all-electric vehicles (EVs) and plug-in hybrid electric vehicles (PHEVs) predominantly use lithium-ion batteries, although their chemistry often differs from those used in consumer electronics. Ongoing research and development efforts focus on reducing costs, extending battery life, minimizing cobalt use, and addressing safety concerns under various fault conditions.</p>
                            <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3" ><Link to='/Battery-Solutions/Lithium-ion'>Discover</Link></button>
                        </div>
                        <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                            <img className='img-fluid pt-5' src='https://www.consultancy.eu/illustrations/news/detail/2023-09-04-011758688-Europe_to_see_250_new_Li-ion_battery_plants_in_next_10_years.jpg'/>
                        </div>
                    </div>
                    <div className="row mt-5 " data-aos="fade-right" data-aos-duration="2000">
                        <div className="col-lg-5 mt-5 pt-5 me-0 me-md-5" >
                       
                <img className='img-fluid' src='https://carfromjapan.com/wp-content/uploads/2018/09/hybrid-battery-pack.jpg'/>
                        </div>
                        <div className="col-lg-6 mt-5 pt-5"> 
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Nickel-Metal Hydride Batteries</h1>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Nickel-metal hydride (NiMH) batteries, commonly used in computer and medical equipment, provide reasonable specific energy and specific power capabilities. </p>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>They have a significantly longer life cycle compared to lead-acid batteries and are known for their safety and tolerance to abuse. NiMH batteries have been extensively utilized in hybrid electric vehicles (HEVs). However, they face several challenges, including high costs, a high self-discharge rate, heat generation at elevated temperatures, and the necessity of controlling hydrogen loss.</p>
                             <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3" ><Link to='/Battery-Solutions/Hevs'>What are HEVs?</Link></button>
                        </div>
                        <div className="row mt-5 " data-aos="fade-left" data-aos-duration="2000">
                        <div className="col-lg-6 mt-5 pt-5">   
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Lead-Acid Batteries</h1>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Lead-acid batteries are inexpensive, safe, reliable, and recyclable, with high power capabilities. </p>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>However, their low specific energy, poor performance in cold temperatures, and short lifespan limit their use. Advanced high-power versions are being developed, but currently, they are mainly used in electric-drive vehicles for ancillary loads and in internal combustion engine vehicles for stop-start systems to reduce idling and fuel consumption.</p>
                        
                        </div>
                        <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                            <img className='img-fluid pt-5' src='https://www.gme-recycling.com/wp-content/uploads/2023/04/lead-acid-batterie.jpg'/>
                        </div>
                    </div>
                    </div>
                </div>
                
            </div>
           
                
                
    </div>
  )
}

export default Solar
