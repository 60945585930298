import React from 'react'

function autoarticle() {
  return (
    <div> 
      <div className="aimain container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5 pb-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Transforming Manufacturing Efficiency with Automation Services</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">ABC Manufacturing, a leading producer of automotive components, operates multiple factories worldwide. Despite being an industry leader, ABC Manufacturing faced challenges with production inefficiencies, high operational costs, and inconsistent product quality. To remain competitive and meet increasing market demands, ABC Manufacturing sought to leverage advanced automation technologies.</p>
                        
                    </div>
                </div>
                
            </div>
            
            <div className="container mt-5 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">
                    
                    <div className="col-lg-8 mt-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Challenges</h1>
                        <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">Despite its status as an industry leader, ABC Manufacturing grappled with production inefficiencies, elevated operational costs, and variable product quality.</p>
                        <div className="w-full md:w-4/4">
                        <ul >
                        <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                            <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Production Inefficiencies: </strong>
                            <p className="text-md">The automation of manual processes and reduction in downtime allowed ABC Manufacturing to meet increasing market demands and take on larger orders.</p>
                        </div>
                        <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                            <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> High Operational Costs</strong>
                            <p className="text-md">Maintaining a large workforce and managing energy consumption were driving up operational costs. </p>
                        </div>
                        <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2600">
                            <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Inconsistent Product Quality:</strong>
                            <p className="text-md">Human error and variability in manual processes led to inconsistent product quality, resulting in increased scrap rates and rework costs.</p>
                        </div>
                        </ul>
                </div>
                    </div>
                    
                </div>
            </div>

            

            <div className="container mt-5 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">
                    
                    <div className="col-lg-8 mt-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Solution</h1>
                        <p className='pt-4 pb-5' data-aos="fade-left" data-aos-duration="2000">In response to these challenges, ABC Manufacturing partnered with our automation services to deploy a comprehensive automation strategy across their production lines. This strategy involved integrating robotics, AI-driven quality control systems, predictive maintenance technologies, and energy management systems.</p>
                      
                    <img className='img-fluid' src='https://i.imgur.com/2M3wURm.png' data-aos="fade-down" data-aos-duration="2500"/>
                    
              
                        <p className='pt-5' data-aos="fade-left" data-aos-duration="2000">Robotic Process Automation (RPA) played a crucial role in streamlining production processes. Industrial robots were installed on the assembly lines to automate repetitive tasks such as welding, painting, and material handling. This automation significantly increased production speed and reduced human error, leading to a remarkable 40% increase in throughput.</p>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000">AI-Driven Quality Control systems were implemented to inspect components in real-time, identifying defects with high precision. This technology improved product quality consistency, resulting in a notable 30% reduction in scrap rates and a 25% decrease in rework costs.</p>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000">Predictive Maintenance technologies, powered by sensors and IoT devices, monitored machinery performance to detect potential failures before they occurred. This proactive approach minimized unplanned downtime by 50% and extended the lifespan of machinery, leading to significant cost savings on repairs and replacements.</p>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000">Energy Management Systems were also deployed to optimize energy usage across the factories. These automated systems reduced energy consumption by 20%, resulting in lower operational costs and a smaller environmental footprint.</p>
                    </div>
                    
                </div>
            </div>
            <div className="container mt-3 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">
                    
                    <div className="col-lg-8 mt-2 pb-5">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Conclusion</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000">Overall, the implementation of automation services led to substantial improvements in ABC Manufacturing's operations. The company experienced increased production capacity, cost savings, enhanced product quality, and improved sustainability. This partnership with our automation services has positioned ABC Manufacturing for future growth and success in a competitive market.</p>
                    </div>
                    
                </div>
            </div>

        </div>
            

  )
}

export default autoarticle
