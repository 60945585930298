import React from 'react';
import { Link } from 'react-router-dom';

function VisionAI() {
    return (
        <div className='vid'>
            <div className="aimain container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Discover the Skies: Unleash Your Creativity with Consumer Drones</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">Consumer drones are unmanned aerial vehicles (UAVs) designed for the general public, catering to a wide range of recreational and practical purposes. These drones are typically characterized by their user-friendly design, affordability, and versatility. </p>
                        <div className="container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/camera-drone.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Hobbyist Drones</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/pilot.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Educational</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container  pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">

                            <img className='img-fluid pt-3 shadow' src='/drone2.gif'  alt="3D Vision AI Technology" />

                        </div>

                        <div className="col-lg-6 mt-1 order-1 order-lg-2 pb-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Photography & Videography Drone</h1>
                            <h1 className='font-semibold ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">DJI Mini 4 Pro</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">DJI Mini 4 Pro is our most advanced mini-camera drone to date. It integrates powerful imaging capabilities, omnidirectional obstacle sensing, ActiveTrack 360° with the new Manual Mode, and 20km FHD video transmission, bringing even more things to love for pros and beginners alike.</p>
                            <div className="w-full md:w-4/4">
                            <div className="row">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Under 249g</strong>
                                <p className="text-sm">Mini 4 Pro was designed for convenience on the go.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Lasting Battery</strong>
                                <p className="text-sm">Enjoy up to 34 minutes of flight time.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Obstacle Sensing</strong>
                                <p className="text-sm">Omnidirectional obstacle sensing ensures flight safety.</p>
                            </div>
                            </div>
                    </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails pt-md-0 pt-3 pb-1 pt-md-0">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-6">
                        <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Racing Drone</h1>
                            <h1 className='font-semibold ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">DJI FPV</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">Sleek and aerodynamic, the DJI FPV aircraft delivers powerful propulsion, battery life, and wind resistance, making this system as capable as it is visually stunning.</p>
                            <div className="row">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Fast Flight</strong>
                                <p className="text-sm">Take your flight experience to new heights with the DJI Motion</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> 4K/60fps FOV</strong>
                                <p className="text-sm">With this immersive aerial platform, get ready to go into the beyond.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Emergency Hover</strong>
                                <p className="text-sm"> DJI FPV comes with an emergency brake and hover feature. </p>
                            </div>
                            </div>
                            {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000"><Link to='/3D-Vision-AI-Casestudy'>Read More</Link></button> */}
                        </div>
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3 ' src='/wow.gif' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container  pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">

                            <img className='img-fluid pt-3 ' src='https://i.imgur.com/ev6ms6t.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />

                        </div>

                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Drone Kits & DIV</h1>
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">DJI Goggles Integra</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">DJI Goggles Integra features an integrated design, adopts two 1080p Micro-OLED screens with up to 100Hz refresh rate, and supports ultra-low-latency video transmission, delivering a more immersive flight experience.</p>
                            <div className="row">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3 shadow' src='https://i.imgur.com/xeXKnHF.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                                    <p className='pt-1 text-sm'>Front View</p>
                                </div>
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3 shadow' src='https://i.imgur.com/0OrbxBF.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                                    <p className='pt-1 text-sm'>Included items</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails pt-md-0 pb-5 pt-3 pb-1 pt-md-0">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-6">
                            <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">STEM kit for schools </h1>
                            <h1 className='font-semibold ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Tello EDU </h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">Tello EDU is an impressive and programmable drone perfect for education. You can easily learn programming languages such as Scratch, Python, and Swift. With an upgraded SDK 2.0, Tello EDU comes with more advanced commands and increased data interfaces. Complete with DJI’s flight control technology, Tello EDU also supports Electronic Image Stabilization. Write code to command multiple Tello EDUs to fly in a swarm, and develop amazing AI functions. Programming has never been this fun with Tello EDU!</p>
                            <div className="row">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Coding Simplified</strong>
                                <p className="text-sm">Tello EDU supports block programming. Simply drag coding blocks on the screen to program Tello EDU and enable certain movements.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Discover with AI</strong>
                                <p className="text-sm">The new SDK 2.0 allows you to develop Tello EDU further, realizing more AI functions such as object recognition and tracking 3D reconstruction.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Drone Swarm</strong>
                                <p className="text-sm">Program multiples Tello EDUs simultaneously through one device. Use code to control a swarm of Tello EDUs, and fly over Mission Pads. </p>
                            </div>
                            
                            </div>
                            
                        </div>
                        <div className="col-lg-5 me-0 me-md-5">
                            <img className='img-fluid pt-5' src='https://i.imgur.com/0J9ESrG.png' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VisionAI;
