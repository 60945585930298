import React from 'react'

function SteelFabrication() {
  return (
    <div>
      <div className="baiduaimain container-fluid">
                <div className=" baidubg row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Aluminum and Steel Fabrication</h1>
                        <p className='pt-3' style={{ color: 'black' }} data-aos="fade-down" data-aos-duration="2000">With expertise in aluminum welding, cutting-edge CNC machining, and precision forming techniques, we offer unparalleled versatility and precision in transforming raw aluminum materials into custom components and structures. </p>
                        <div className=" container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/authentication.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Design & Engineering Support</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/ocr.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Quality Assurance & Compliance</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/pic.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Custom Fabrication Solutions</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/autonomous.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Industrial Welding </p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/nlp.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Precision Machining</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="2000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/gear.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">24/7 Customer Support</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="smartcity container-fluid pb-5 mb-5">
                <div className=" row mt-5 pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mt-5 pt-5">
                                <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Customized Fabrication Solutions for Diverse Industries</h1>
                                <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2500">Collaborate with our skilled design and engineering team to bring your vision to life. We offer innovative solutions and technical expertise from concept to completion. </p>
                                <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="3000">Explore tailored fabrication solutions to meet the unique needs of various industries. From aerospace and automotive to construction and manufacturing, our expertise spans a wide range of applications. Whether you require precision components, structural assemblies, or custom prototypes, our solutions are designed to exceed your expectations and drive success in your industry.</p>
                            </div>
                            <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                                <img className='img-fluid' src='/cam.png' data-aos="fade-down" data-aos-duration="2500"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           

            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5">
                        <img className='img-fluid' src='/securityimg.png' data-aos="fade-right" data-aos-duration="2000"/>
                        </div>
                        <div className="col-lg-6 mt-5 pt-5">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Discover Our Solutions</h1>
                            <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Design & Engineering Support</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">From concept to creation, our dedicated team provides comprehensive design and engineering support tailored to your fabrication needs. Whether you're starting from scratch or refining existing designs, we offer expertise in conceptualization, CAD modeling, structural analysis, and design optimization. With a focus on innovation and problem-solving, we collaborate closely with clients to ensure their vision is brought to life with precision and efficiency. Trust us to deliver customized solutions that meet your specifications and exceed your expectations.</p>
                        </div>
                    </div>
                </div>

            </div>
           
            <div className="aidetails  pb-5 pt-md-0">
                <div className="container  pt-md-0">
                    <div className="row mt-5 pt-5">     
                        <div className="col-lg-6 pt-5">
                        <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Quality Assurance & Compliance</h1>
                            <p className='pt-3' style={{ color: '#1E1E1E', textAlign: 'justify' }} data-aos="fade-right" data-aos-duration="2000">At our firm, meticulous attention to detail is paramount. We adhere strictly to industry standards and regulations, ensuring every product meets rigorous quality control measures. Our ISO-certified processes guarantee consistency, reliability, and safety throughout fabrication. From initial inspection to final delivery, trust in our commitment to delivering excellence, every step of the way.</p>
                        </div>
                        <div className="col-lg-5  me-0 me-md-5">
                            <img className='img-fluid' src='/recrtimg.png' data-aos="fade-left" data-aos-duration="2000" />
                        </div>
                    </div>
                </div>

            </div>
            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5">
                            <img className='img-fluid' src='/drvimg.png' data-aos="fade-left" data-aos-duration="2000"/>
                        </div>
                        <div className="col-lg-6 mt-5 pt-5">
                            <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Custom Fabrication Solutions</h1>
                            <p className='pt-2' data-aos="fade-right" data-aos-duration="2000">Our tailored fabrication services are specifically designed for steel and aluminum sheet materials. Our expertise extends to precision cutting, welding, bending, and shaping of steel and aluminum sheets to meet your unique requirements. From architectural elements to industrial components, our custom solutions deliver quality craftsmanship and precision engineering for projects of any scale. Collaborate with us to transform your ideas into reality and achieve unparalleled results with steel and aluminum sheet fabrication.</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="aidetails  pb-5 pt-md-0">
                <div className="container  pt-md-0">
                    <div className="row mt-5 pt-5">
                        <div className="col-lg-6 pt-5">
                        <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Industrial Welding</h1>
                            <p className='pt-3' style={{ color: '#1E1E1E', textAlign: 'justify' }} data-aos="fade-right" data-aos-duration="2000">Harnessing the power of precision and strength, our industrial welding services provide reliable solutions for a variety of applications. With skilled welders and advanced equipment, we specialize in joining metal components with unparalleled accuracy and durability. From MIG and TIG welding to spot welding and beyond, trust us to deliver impeccable welds that meet the demands of your industrial projects. Whether it's structural fabrication, machinery assembly, or custom components, our welding expertise ensures the integrity and performance of your products.</p>
                        </div>
                        <div className="col-lg-5  me-0 me-md-5">
                            <img className='img-fluid' src='/sports.png' data-aos="fade-left" data-aos-duration="2000"/>
                        </div>
                    </div>
                </div>

            </div>
            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5">
                            <img className='img-fluid' src='/drvimg.png' data-aos="fade-left" data-aos-duration="2000"/>
                        </div>
                        <div className="col-lg-6 mt-5 pt-5">
                            <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Precision Machining</h1>
                            <p className='pt-2' data-aos="fade-right" data-aos-duration="2000">Experience the pinnacle of accuracy and efficiency with our precision machining services. Utilizing state-of-the-art CNC technology and skilled machinists, we specialize in producing intricate components with tight tolerances and impeccable surface finishes. From milling and turning to grinding and drilling, our precision machining capabilities cater to a wide range of industries and applications. Whether you require prototype development or high-volume production, trust us to deliver precision-engineered solutions that meet your exact specifications and exceed your expectations.</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="aidetails  pb-5 pt-md-0">
                <div className="container  pt-md-0">
                    <div className="row mt-5 pt-5">
                        <div className="col-lg-6 pt-5">
                        <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">24/7 Customer Support</h1>
                            <p className='pt-3' style={{ color: '#1E1E1E', textAlign: 'justify' }} data-aos="fade-right" data-aos-duration="2000">Our commitment to you goes beyond fabrication. With round-the-clock customer support, we're here whenever you need us. Whether you have questions, concerns, or need assistance, our dedicated team is available day and night to provide prompt and personalized assistance. We understand the importance of responsiveness in keeping your projects on track, and we're always ready to lend a helping hand whenever you need it. Your satisfaction is our priority, and we're here to support you every step of the way.</p>
                        </div>
                        <div className="col-lg-5  me-0 me-md-5">
                            <img className='img-fluid' src='/sports.png' data-aos="fade-left" data-aos-duration="2000"/>
                        </div>
                    </div>
                </div>

            </div>

            <div className="specialcont container-fluid  pt-md-0 pb-5 ">
                    <div className="row mt-5 pt-5 justify-content-center">
                        
                        <div className="col-lg-4 pe-3">
                        <h1 className='font-semibold pt-5' style={{ color: 'white', fontSize: '25px' }} data-aos="fade-left" data-aos-duration="2000">Conceptualization</h1>
                            <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-left" data-aos-duration="2000">During the conceptualization phase, the design team collaborates with clients to brainstorm ideas, define project goals, and establish design requirements. This stage involves creative exploration and problem-solving to develop innovative concepts that meet the client's needs.</p>
                            
                        </div>
                        <div className="col-lg-4  pe-3">
                        <h1 className='font-semibold pt-5' style={{ color: 'white', fontSize: '25px' }} data-aos="fade-right" data-aos-duration="2000">CAD Modeling</h1>
                            <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-right" data-aos-duration="2000">Computer-Aided Design (CAD) modeling is used to create detailed digital representations of components or structures. CAD software allows designers to visualize concepts, perform virtual simulations, and refine designs before fabrication begins.</p>
                        </div>
                    </div>
                </div>
    </div>
  )
}

export default SteelFabrication
