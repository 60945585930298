import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function AIsolutionsMainPage() {
    return (
        <div className='vid'>
            <div className="aimainsp3 container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Shaping the Future with AI-driven Ingenuity</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">Our visionary approach is shaping the future landscape with unparalleled ingenuity. Through cutting-edge technologies and strategic foresight, we are driving unprecedented advancements across industries, revolutionizing the way we work, live, and interact.</p>
                        <div className="container mt-8">
                            <div className="row justify-content-center text-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4 mb-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md">
                                        <img src="https://i.imgur.com/l1RwSbj.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Industry</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4 mb-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md">
                                        <img src="https://i.imgur.com/MH1F6Gg.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Agriculture</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-4 mb-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md">
                                        <img src="https://i.imgur.com/xTSddrA.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Defense</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails pt-md-5 pt-0 pb-5">
                <div className="container pt-20 pt-md-0">
                    <div className="row pt-3" data-aos="fade-left" data-aos-duration="2000">
                        <div className="col-lg-5 order-1 order-lg-2 ms-0 ms-md-5 pb-5 pb-md-0">
                            <img className='img-fluid' src='https://i.imgur.com/PEB8Mea.png' alt="AI Illustration" />
                        </div>
                        <div className="col-lg-6 order-2 order-lg-1">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Elevating Industries with Unbeatable Artificial Intelligence.</h1>
                            <p className='pt-3' style={{ textAlign: 'justify' }}>Step into a world of unparalleled efficiency and innovation. Our AI solutions aren't just transformative, they're the driving force behind industry evolution.</p>
                            <p className='pt-3' style={{ color: '#1E1E1E', textAlign: 'justify' }}>Experience the power of data-driven insights, streamlined operations, and precision decision-making. From optimizing workflows to unlocking hidden opportunities, our AI solutions are tailored to elevate your business to new heights. Join the ranks of industry leaders who trust us to deliver tangible results and unlock the full potential of AI technology. Welcome to a future where every challenge is met with a solution, and success is inevitable.</p>
                        </div>
                    </div>
                    <div className="row mt-0 mt-md-5 pt-0 pt-md-5" data-aos="fade-right" data-aos-duration="2000">
                        <div className="col-lg-5 mt-5 pt-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid' src='https://i.imgur.com/uMle5HY.png' alt="AI Manufacturing" />
                        </div>
                        <div className="col-lg-6 mt-0 mt-md-5 pt-5 order-2 order-lg-2 text-center text-lg-start">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>AI Manufacturing & Factory Automation</h1>
                            <p className='pt-3' style={{ color: '#1E1E1E', textAlign: 'justify' }}>By integrating cutting-edge AI technologies, we enable factories to optimize production lines, reduce operational costs, and enhance product quality. Our expertise spans predictive maintenance, real-time monitoring, and adaptive control systems, ensuring that your manufacturing operations are always running at peak performance. Embrace the future of manufacturing with us and experience unprecedented levels of productivity and precision.</p>
                            <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5"><Link to='/baiduai'>Discover Manufacturing AI</Link></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="agri container-fluid pb-0 pb-md-5">
                <div className="row pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mt-5 text-center text-lg-start" data-aos="fade-right" data-aos-duration="2500">
                                <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }}>Smart Agriculture with Artificial Intelligence</h1>
                                <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }}>Discover the future of farming with our advanced AI-powered solutions designed to revolutionize agriculture.</p>
                                <p className='pt-3 pb-4' style={{ color: 'white', textAlign: 'justify' }}>Utilize AI to monitor crop health through drones and satellite images, enabling early detection of diseases, pest infestations, and nutrient deficiencies across vast areas. Our cutting-edge technology empowers farmers with real-time data, ensuring timely interventions and healthier crops. Inspired by pioneers in UAV solutions like AeroVironment, Inc., satellite imaging experts like Planet Labs, and drone technology leaders like DJI Agriculture, our solutions provide a comprehensive approach to modern farming challenges.</p>
                                <Link to='/smartcitytech' className="mainbtn2 border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5">Discover Agriculture AI</Link>
                            </div>
                            <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                                <img className='img-fluid' src='https://i.imgur.com/AzXHRjE.png' data-aos="fade-left" data-aos-duration="2500" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="aidetails mt-0 mt-md-5 pt-md-0  pb-5 pt-md-0">
                <div className="container mt-0 mt-md-5  pt-md-0">
                    <div className="row mt-5 pt-0 pt-md-5" data-aos="fade-left" data-aos-duration="2500">
                        <div className="col-lg-5 mt-5 pt-5 me-0 me-md-5">
                            <img className='img-fluid' src='/laser.png' />
                        </div>
                        <div className="col-lg-6 mt-0 mt-md-5 pt-5 text-center text-lg-start">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Artificial Intelligence & Defense</h1>
                            <p className='pt-3' style={{ textAlign: 'justify' }}>Harness the power of AI to bolster national security and defense capabilities with our state-of-the-art solutions.</p>
                            <p className='pt-3 pb-4' style={{ color: '#1E1E1E', textAlign: 'justify' }}>
                                Enhance national security with our AI-powered surveillance and reconnaissance technologies. Our solutions feature advanced data analytics for superior threat detection and operational intelligence, providing unparalleled situational awareness. Inspired by industry leaders like BAE Systems, Lockheed Martin, and Raytheon Technologies, our technologies are designed to keep you a step ahead in the ever-evolving defense landscape.
                            </p>
                            <Link to='/3D-Vision' className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5">Discover Defense AI</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AIsolutionsMainPage;
