import React from 'react'
import { Link } from 'react-router-dom'

function Visionpage() {
  return (
    <div className='vid'>
        <div className="baiduaimain container-fluid">
                <div className=" baidubg row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'black', fontSize: '35px', textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">Artifical Intelligence & Defence</h1>
                        <p className='pt-3' style={{ color: 'black', textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">Enhance national security with our AI-powered surveillance and reconnaissance technologies, featuring advanced data analytics for threat detection and operational intelligence. Our cutting-edge solutions leverage machine learning algorithms and real-time data processing to provide comprehensive situational awareness and predictive insights. </p>
                        <div className=" container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/authentication.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Surveillance AI</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/defense.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Defense AI</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
           

            <div className="aidetails mt-0 mt-md-5 pb-1 ">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5">
                        <img className='img-fluid' src='/sur.png' data-aos="fade-right" data-aos-duration="2000"/>
                        </div>
                        <div className="col-lg-6 mt-5 text-center text-lg-start">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px', textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">AI in Surveillance and Reconnaissance</h1>
                            <p className='pt-2 pb-4' data-aos="fade-left" data-aos-duration="2000">By integrating high-resolution imaging, automated anomaly detection, and multi-source data fusion, our technologies enable rapid identification and assessment of potential threats. With capabilities inspired by industry leaders such as BAE Systems, Lockheed Martin, and Raytheon Technologies, our AI-driven surveillance systems offer unparalleled precision and reliability.</p>
                            <Link to='/AI-Defense/applications' className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 " data-aos="fade-down" data-aos-duration="2000">Discover Surveillance AI</Link>
                        </div>
                    </div>
                </div>

            </div>
           
            <div className="aidetails  pb-5 pt-md-0">
                <div className="container  pt-md-0">
                    <div className="row mt-5 pt-5">     
                        <div className="col-lg-6 pt-5 order-2 order-lg-1 text-center text-lg-start">
                        <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Autonomous Defense Systems</h1>
                            <p className='pt-3 pb-4' style={{ color: '#1E1E1E', textAlign: 'justify' }} data-aos="fade-right" data-aos-duration="2000">Immerse yourself in captivating virtual environments and interactive experiences with our 3D vision solutions. From augmented reality applications that overlay digital content onto the physical world to virtual reality simulations that transport you to new realms, the possibilities are endless.</p>
                            <Link to='/AutonomousAI/applications' className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 " data-aos="fade-down" data-aos-duration="2000">Discover Defense Systems</Link>
                        </div>
                        <div className="col-lg-5  me-0 me-md-5 order-1 order-lg-2">
                            <img className='img-fluid' src='/bae.png' data-aos="fade-left" data-aos-duration="2000" />
                        </div>
                    </div>
                </div>

            </div>
            

            <div className="specialcont container-fluid  pt-md-0 pb-5 ">
                    <div className="row mt-5 pt-5 justify-content-center">
                        
                        <div className="col-lg-6  text-center">
                        <h1 className='font-semibold pt-5 ' style={{ color: 'white', fontSize: '25px' }} data-aos="fade-right" data-aos-duration="2000">Safe Guarding Our Community with Smart Tech.</h1>
                            <p className='pt-3 ' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-right" data-aos-duration="2000">Improve emergency response times and coordination with our intelligent systems. Our solutions integrate data from various sources to provide real-time situational awareness, enabling first responders to make informed decisions quickly. By optimizing resource allocation and communication, we enhance the effectiveness of emergency operations, ultimately saving lives and property.


</p>
                        </div>
                    </div>
                </div>
                
      
    </div>
  )
}

export default Visionpage
