import React from 'react'
import { Link, useLocation } from 'react-router-dom';
function AI_automation() {
  return (
    <div className='vid'>
   
        <div className="aimain container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Invest in digital transformation and industrial automation to achieve critical business outcomes</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">Our visionary approach is shaping the future landscape with unparalleled ingenuity. Through cutting-edge technologies and strategic foresight, we are driving unprecedented advancements across industries, revolutionizing the way we work, live, and interact.</p>
                        <div className="container mt-8" >
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/robotic.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center"> Workforce</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/chip.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center"> Business</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/shield.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center"> Productivity</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
            <div className="container mt-5 pt-20 pt-md-0">
                <div className="row ">
                    <div className="col-lg-5 me-0 me-md-5">
                    <img className='img-fluid' src='https://i.imgur.com/vpiZw08.png' data-aos="fade-down" data-aos-duration="2500"/>
                    
                    </div>
                    <div className="col-lg-6 mt-5">
                        <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Totally Integrated Automation Portal – Always ready for tomorrow</h1>
                        <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">Regardless of what you need to engineer innovative machines, you’ll find it in Totally Integrated Automation Portal (TIA Portal). It gives you unrestricted access to the complete range of digitalized automation services, from digital planning to integrated engineering and transparent operation. With TIA Portal you gain valuable competitive advantages!</p>
                       
                    </div>
                </div>
            </div>

        </div>
       
        <div className="aidetails mt-5 pt-md-5 pt-0 pb-1 pt-md-0">
            <div className="container mt-5 pt-20 pt-md-0">
                <div className="row pt-5">
                    
                    <div className="col-lg-6 mt-2 order-2 order-lg-1">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">TIA Portal – More than an Engineering Framework</h1>
                        <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">More flexible, faster, and more productive: Innovative simulation tools, seamlessly integrated engineering, and transparent plant operation work perfectly together in TIA Portal. The new options benefit system integrators and machine builders as well as plant operators, making TIA Portal your perfect gateway to automation in the Digital Enterprise.</p>
                        <div className="w-full md:w-4/4">
                        <div className="row">
                        <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                            <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Advanced Engineering</strong>
                            <p className="text-sm">Access everything you need to engineer innovative machines with the Totally Integrated Automation Portal (TIA Portal).</p>
                        </div>
                        <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                            <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Digital Integration</strong>
                            <p className="text-sm">Utilize a complete range of digitalized automation services, from digital planning to integrated engineering.</p>
                        </div>
                        <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                            <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  Competitive Edge</strong>
                            <p className="text-sm">Gain valuable competitive advantages with TIA Portal's unrestricted access and advanced features.</p>
                        </div>
                        </div>
                </div>
                    </div>
                    
                   
                    <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-2">
                    <img className='img-fluid' src='https://i.imgur.com/Z7HUPZ4.png' data-aos="fade-down" data-aos-duration="2500"/>
                    
                    </div>
                    
                </div>
            </div>

        </div>
        
        <div className="aidetails mt-0 mt-md-5 pt-md-5 pt-0 pb-1 pt-md-0 pb-5">
            <div className="container mt-5 pt-0 pt-md-5">
                <div className="row ">
                    <div className="col-lg-5 me-0 me-md-5 ">
                    <div className="video-container" >
                <iframe src='/bgvideoauto.mp4' className="responsive-iframe" allowfullscreen data-aos="fade-down" data-aos-duration="2000" autoplay loop></iframe>
            </div>
                    </div>
                    <div className="col-lg-6 text-center text-lg-start">
                    <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' ,textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">Our Automation Case Study </h1>
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px',textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">How we help the industry?</h1>
                        <p className='pt-2' data-aos="fade-right" data-aos-duration="2000" style={{textAlign:'justify'}}>Our automation services stand at the forefront of this transformation, offering solutions that not only streamline operations but also unlock new levels of productivity and growth. Here's how our automation services are helping various industries achieve their critical business outcomes.</p>
                        <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3" data-aos="fade-down" data-aos-duration="2000" autoplay><Link to='/Automation-Casestudy'>Read More</Link></button>
                    </div>
                </div>
            </div>

        </div>

 
        
        

        
        
  
</div>
  )
}

export default AI_automation
