import React from 'react'
import { Link } from 'react-router-dom'
function HitechManufacturing() {
  return (
    <div className='vid'>
      <div className="aimainsp6 container-fluid pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-6 pt-5 mt-3  ">
            <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Leading the Future in Hi-Tech Manufacturing</h1>
            <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">We are at the forefront of hi-tech manufacturing, driving innovation and excellence in every aspect of our operations. Through cutting-edge technologies and strategic vision, we are shaping the future of manufacturing, revolutionizing industries, and unlocking new possibilities.</p>
          </div>
        </div>
      </div>

      <div className=" container mt-8">
                <div className="row justify-content-center">
                <h1 className='font-semibold text-center mb-5 mt-5' style={{ color: 'black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Featured Solutions</h1>
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0 text-center">
                            <img src="/3d-pri.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                            <p className="text-sm text-center">Additive Manufacturing (3D Printing)</p>
                            <p className='pt-3 pb-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>We offer comprehensive Additive Manufacturing (3D Printing) services designed to bring your innovative ideas to life. Utilizing cutting-edge technology, we produce complex, customized, and functional parts with precision and efficiency.</p>
                            <Link to='/3D-Manufacturing' className="mainbtn2  border  rounded-full py-1 px-4 mr-4 hover:bg-orange-600 mt-2">View More</Link>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1500" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0 text-center">
                            <img src="/robotic-arm.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                            <p className="text-sm text-center">Robotics and Automation</p>
                            <p className='pt-3 pb-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>Our automation solutions encompass a range of technologies such as robotic arms, automated guided vehicles (AGVs), and collaborative robots (cobots) that work alongside human operators to enhance workplace safety and efficiency.</p>
                            <Link to='/Robotics&Automation' className="mainbtn2  border  rounded-full py-1 px-4 mr-4 hover:bg-orange-600 mt-2">View More</Link>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1500" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0 text-center">
                            <img src="/face.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                            <p className="text-sm text-center">Precision Engineering</p>
                            <p className='pt-3 pb-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>We offer exceptional Precision Engineering services that cater to the intricate demands of various industries, ensuring high accuracy and consistency in every project. Our precision engineering solutions are designed to meet the highest standards of quality.</p>
                            <Link to='/Precision-Engineering' className="mainbtn2  border  rounded-full py-1 px-4 mr-4 hover:bg-orange-600 mt-2">View More</Link>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1500" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0 text-center">
                            <img src="/membrane.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto tezt-center" />
                            <p className="text-sm text-center">Advanced Materials</p>
                            <p className='pt-3 pb-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>We offer comprehensive solutions in Advanced Materials, providing cutting-edge materials that enhance performance, durability, and sustainability across various industries. Our advanced materials services are designed to meet the evolving needs of modern engineering.</p>
                            <Link to='/Advanced-Materials' className="mainbtn2  border  rounded-full py-1 px-4 mr-4 hover:bg-orange-600 mt-2">View More</Link>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1500" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0 text-center">
                            <img src="/manufacturing.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto text-center" />
                            <p className="text-sm text-center">Digital Manufacturing Technologies</p>
                            <p className='pt-3 pb-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>We offer comprehensive solutions in Digital Manufacturing Technologies, enabling businesses to optimize their production processes through the integration of advanced digital tools and techniques. Our services are designed to enhance efficiency and flexibility. </p>
                            <Link to='/Digital-Manufacturing-Technologies' className="mainbtn2  border  rounded-full py-1 px-4 mr-4 hover:bg-orange-600 mt-2">View More</Link>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1500" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0 text-center">
                            <img src="/planet-earth.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto " />
                            <p className="text-sm text-center">Sustainable Manufacturing</p>
                            <p className='pt-3 pb-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>We offer comprehensive Sustainable Manufacturing solutions designed to minimize environmental impact while maximizing efficiency and profitability. Our services focus on integrating eco-friendly practices and technologies into manufacturing processes.</p>
                            <Link to='/Sustainable-Manufacturing' className="mainbtn2  border  rounded-full py-1 px-4 mr-4 hover:bg-orange-600 mt-2">View More</Link>
                        </div>
                    </div>
                </div>
            </div>


      

    </div>
  )
}

export default HitechManufacturing
