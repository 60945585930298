import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from "./components/navbar";
import Home from "./components/home";
import AIsolutionsMainPage from "./pages/AIsolutionsMainPage";
import BaiduAi from "./pages/BaiduAi";
import SmartCityTech from './pages/SmartCityTech';
import DmsMainPage from './pages/DmsMainPage';
import Underdevelopment from './pages/Underdevelopment';
import Visionpage from './pages/Visionpage';
import CustomizedFurniture from './pages/CustomizedFurniture';
import SteelFabrication from './pages/SteelFabrication';
import Printing from './pages/Printing';
import SpecializedElectornicsMainpage from './pages/SpecializedElectornicsMainpage';
import SolarandEV from './pages/SolarandEV';
import HitechManufacturing from './pages/HitechManufacturing';
import Partners from './pages/Partners';
import Airsterilizer from './pages/Airsterilizer';
import BMS from './pages/BMS';
import Drones from './pages/Drones';
import GreenEnergy from './pages/GreenEnergy';
import Automotives from './pages/Automotives';
import Automationsolutions from './pages/automationsolutions';
import Ev from './components/ev';
import Solar from './pages/Solar';
import AI_automation from './pages/AI_automation';
import Autoarticle from './pages/autoarticle';
import Aivision from './pages/aivision';
import Deepai from './pages/deepai';
import Smartcityarticle from './pages/SmartCityArticle';
import ConsumerDrones from './pages/Consumerdrones';
import Dronerules from './pages/Dronerules';
import Dronetech from './pages/Dronetech';
import Dtech from './pages/3dmanufacturing';
import Robotics from './pages/Robotics';
import PrecisionEngineering from './pages/Engineering';
import AdvancedMaterials from './pages/Advancedmaterials';
import DigitalManufacturingTechnologies from './pages/Digitaltech';
import SustainableManufacturing from './pages/Sustainable';
import Electricvehical from './pages/ElectricVehicals';
import Evbattery from './pages/Evbattery';
import Chargingsolution from './pages/Chargingsolution';
import Techev from './pages/technologyEV';
import MarketTrends from './pages/Marketrends';
import Retail from './pages/case-studies/retailcasestudy';
import Industrial from './pages/case-studies/industrialcasestudy';
import Healthcare from './pages/case-studies/healthcasestudy';
import Cognex from './pages/cognexapplication';
import Agrigps from './pages/agriculturegps';
import Aicasestudy from './pages/case-studies/aicrophealthcasestudy';
import AIsurv from './pages/case-studies/aisurv';
import AIdefense from './pages/aidefense';
import Modems from './pages/modems';
import Optitech from './pages/optitech';
import Gcasestudy from './pages/case-studies/5gcasestudy';
import PCB from './pages/case-studies/pcb';
import Minedrone from './pages/Miningdrone';
import Miningsurvey from './pages/Miningsurvey';
import Energydrone from './pages/case-studies/coboltcasestudy';
import Cobolt from './pages/case-studies/coboltcasestudy';
import Articulaterobo from './pages/Articulatedrobo';
import Prrinting from './pages/Prrinting';
import EBM from './pages/EBM';
import Micro from './pages/Micromachining';
import CNC from './pages/CNC';
import Shape from './pages/Shapememoryalloys';
import Highperformancealloys from './pages/Highperformancealloys';
import Nano from './pages/Nano';
import CAD from './pages/CAD';
import IOT from './pages/IOT';
import Energyeff from './pages/Clean';
import Waste from './pages/Wastereduction';
import Lithium from './pages/Lithium';
import HEV from './pages/HEV'
import Solarcash from './pages/Solar-expensive'







import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

export default function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aisolutionsmainpage" element={<AIsolutionsMainPage />} />
        <Route path="/baiduai" element={<BaiduAi/>} />
        <Route path="/smartcitytech" element={<SmartCityTech/>} />
        <Route path="/Dmssolutions" element={<DmsMainPage/>} />
        <Route path="/underdevelopment" element={<Underdevelopment/>} />
        <Route path="/3D-Vision" element={<Visionpage/>} />
        <Route path="/CustomizedFurniture" element={<CustomizedFurniture/>} />
        <Route path="/Aluminium&Steel" element={<SteelFabrication/>} />
        <Route path="/3D-Printing" element={<Printing/>}/>
        <Route path="/Specialized-Electronics" element={<SpecializedElectornicsMainpage/>}/>
        <Route path="/Solar&EV" element={<SolarandEV/>}/>
        <Route path="/Hitech-Manufacturing" element={<HitechManufacturing/>}/>
        <Route path="/Partners" element={<Partners/>}/>
        <Route path="/AirSterilizers" element={<Airsterilizer/>}/>
        <Route path="/BMS-Solutions" element={<BMS/>}/>
        <Route path="/GreenEnergy" element={<GreenEnergy/>}/>
        <Route path="/Automotives" element={<Automotives/>}/>
        <Route path="/Drones-UAVs" element={<Drones/>}/>
        <Route path="/Automationsolutions" element={<Automationsolutions/>}/>
        <Route path="/Ev-technology" element={<Ev/>}/>
        <Route path="/Solar" element={<Solar/>}/>
        <Route path="/Factory-Automation" element={<AI_automation/>}/>
        <Route path="/Automation-Casestudy" element={<Autoarticle/>}/>
        <Route path="/AI-Vision" element={<Aivision/>}/>
        <Route path='/DeepAI' element={<Deepai/>}/>
        <Route path='/SmartCity' element={<Smartcityarticle/>}/>
        <Route path='/Consumer-Drones' element={<ConsumerDrones/>}/>
        <Route path='/Drone-Regulations' element={<Dronerules/>}/>
        <Route path='/Drone-Technology' element={<Dronetech/>}/>
        <Route path='/3D-manufacturing' element={<Dtech/>}/>
        <Route path='/Robotics&Automation' element={<Robotics/>}/>
        <Route path='/Precision-Engineering' element={<PrecisionEngineering/>}/>
        <Route path='/Advanced-Materials' element={<AdvancedMaterials/>}/>
        <Route path='/Digital-Manufacturing-Technologies' element={<DigitalManufacturingTechnologies/>}/>
        <Route path='/Sustainable-Manufacturing' element={<SustainableManufacturing/>}/>
        <Route path='/Electric-Vehicals' element={<Electricvehical/>}/>
        <Route path='/Electric-Battery' element={<Evbattery/>}/>
        <Route path='/Charging-Solution' element={<Chargingsolution/>}/>
        <Route path='/Latest-EVTech' element={<Techev/>}/>
        <Route path='/Market-Trends' element={<MarketTrends/>}/>
        <Route path='/case-studies/Retail&AI' element={<Retail/>}/>
        <Route path='/case-studies/industrial' element={<Industrial/>}/>
        <Route path='/case-studies/healthcare' element={<Healthcare/>}/>
        <Route path='/3dvision/applications' element={<Cognex/>}/>
        <Route path='/Agriculture-AI/applications' element={<Agrigps/>}/>
        <Route path='/case-studies/AIhealthcare' element={<Aicasestudy/>}/>
        <Route path='/AI-Defense/applications' element={<AIsurv/>}/>
        <Route path='/AutonomousAI/applications' element={<AIdefense/>}/>
        <Route path='/Routers/applications' element={<Modems/>}/>
        <Route path='/Optoelectronic/applications' element={<Optitech/>}/>
        <Route path='/case-studies/5G-technology' element={<Gcasestudy/>}/>
        <Route path='/case-studies/PCB-Manufacturing' element={<PCB/>}/>
        <Route path='/Industrial-drones/applications' element={<Minedrone/>}/>
        <Route path='/Industrial-drones/how-to-survey' element={<Miningsurvey/>}/>
        <Route path='/Industrial-drones/Energy-Managment' element={<Energydrone/>}/>
        <Route path='/case-studies/Cobot' element={<Cobolt/>}/>
        <Route path='/Robotic-arms/articulated-robotic-arms' element={<Articulaterobo/>}/>
        <Route path='/3D-printing/DMLS' element={<Prrinting/>}/>
        <Route path='/3D-printing/EBM' element={<EBM/>}/>
        <Route path='/Precision-Engineering/Micromachining' element={<Micro/>}/>
        <Route path='/Precision-Engineering/CNC' element={<CNC/>}/>
        <Route path='/Advanced-Materials/shape-memory-alloys' element={<Shape/>}/>
        <Route path='/Advanced-Materials/high-performance-alloys' element={<Highperformancealloys/>}/>
        <Route path='/Advanced-Materials/nano-materials' element={<Nano/>}/>
        <Route path='/Digital-technology/CAD-CAM' element={<CAD/>}/>
        <Route path='/Digital-technology/IOT' element={<IOT/>}/>
        <Route path='/Sustainable-Manufacturing/Energy-optimization' element={<Energyeff/>}/>
        <Route path='/Sustainable-Manufacturing/waste-reduction' element={<Waste/>}/>
        <Route path='/Battery-Solutions/Lithium-ion' element={<Lithium/>}/>
        <Route path='/Battery-Solutions/Hevs' element={<HEV/>}/>
        <Route path='/Solar/are-solarpanel-expensive?' element={<Solarcash/>}/>











      </Routes>
    </Router>
  );
}
