import React from 'react';
import { Link } from 'react-router-dom';

function AdvancedMaterials() {
    return (
        <div className='vid'>
            <div className="aimain container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Advanced Materials</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">
                            Advanced materials encompass a wide range of innovative substances designed to achieve superior performance in a variety of applications. These materials include smart materials, high-performance alloys, and nanomaterials, which exhibit exceptional properties and enable groundbreaking advancements in technology and industry.
                        </p>
                        <div className="container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/membrane.png" alt="Smart Materials" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Smart Materials</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="https://i.imgur.com/OcHkxLO.png" alt="High-Performance Alloys" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">High-Tech Alloys</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="2000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="https://i.imgur.com/AeBpgaq.png" alt="Nanomaterials" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Nanomaterials</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* The thing I do for a perfect background image */}
            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3' src='https://cdn.pixabay.com/photo/2017/11/09/10/18/metallurgy-2932944_960_720.jpg' data-aos="fade-down" data-aos-duration="2500" alt="Smart Materials Technology" />
                        </div>
                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Smart Materials</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">
                                Smart materials can change their properties in response to external stimuli such as temperature, light, pressure, or magnetic fields, offering versatile solutions for various applications.
                            </p>
                            <div className="row pb-3">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://media.istockphoto.com/id/1152207860/photo/the-interior-of-a-victorian-armchair.jpg?s=612x612&w=0&k=20&c=ENqVeuEcG-KpZxHbJzavfUJ0IHVS2G6DPMm1QOOTdDk=' data-aos="fade-down" data-aos-duration="2500" alt="Shape Memory Alloys" />
                                    <p className='pt-1 text-sm'>Shape Memory Alloys</p>
                                </div>
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://cdn.pixabay.com/photo/2022/01/23/18/37/aluminum-foil-6961638_640.jpg' data-aos="fade-down" data-aos-duration="2500" alt="Piezoelectric Materials" />
                                    <p className='pt-1 text-sm'>Piezoelectric Materials</p>
                                </div>
                            </div>
                            <Link to='/Advanced-Materials/shape-memory-alloys' className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section for detailed information about High-Performance Alloys */}
            <div className="aidetails pt-md-0 pt-3 pb-1 pt-md-0">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3' src='https://cdn.pixabay.com/photo/2017/11/09/10/18/metallurgy-2932947_640.jpg' data-aos="fade-down" data-aos-duration="2500" alt="High-Performance Alloys" />
                        </div>
                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">High-Performance Alloys</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">
                                High-performance alloys like Inconel and titanium alloys are engineered to withstand extreme environments and stresses, making them ideal for aerospace, automotive, and energy applications.
                            </p>
                            <div className="row pb-3">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEWQ6UxtWlk4juVUM5vBOIaLsXAyWjk4A1Cw&s' data-aos="fade-down" data-aos-duration="2500" alt="Inconel" />
                                    <p className='pt-1 text-sm'>Inconel</p>
                                </div>
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://titek.co.uk/wp-content/uploads/2018/12/Commercially-pure-titanium-Vs.-titanium-alloys-1024x683.jpg' data-aos="fade-down" data-aos-duration="2500" alt="Titanium Alloys" />
                                    <p className='pt-1 text-sm'>Titanium Alloys</p>
                                </div>
                            </div>
                            <Link to='/Advanced-Materials/high-performance-alloys' className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section for detailed information about Nanomaterials */}
            <div className="aidetails pt-md-0 pt-3 pb-1 pt-md-0 pb-5">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3' src='https://media.istockphoto.com/id/2149366342/photo/bottom-view-of-the-computer-processor-with-gold-plated-pins.jpg?s=612x612&w=0&k=20&c=hhj0cZTeyLgvhdWTUS12hOTxwyKqpAAxUIrDL_ElVfw=' data-aos="fade-down" data-aos-duration="2500" alt="Nanomaterials" />
                        </div>
                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Nanomaterials</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">
                                Nanomaterials like carbon nanotubes and graphene offer remarkable mechanical, electrical, and thermal properties, paving the way for advancements in electronics, materials science, and energy storage.
                            </p>
                            <div className="row pb-3">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3 ' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHVFtAFX1k8LFWol5uAh_UbcYuHv0deJLRwA&s' data-aos="fade-down" data-aos-duration="2500" alt="Carbon Nanotubes" />
                                    <p className='pt-1 text-sm'>Carbon Nanotubes</p>
                                </div>
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1xxsn0cNWrtRXjX05CxV6QpugRrjdYuqWwg&s' data-aos="fade-down" data-aos-duration="2500" alt="Graphene" />
                                    <p className='pt-1 text-sm'>Graphene</p>
                                </div>
                            </div>
                            <Link to='/Advanced-Materials/nano-materials' className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdvancedMaterials;
