import React from 'react';
import { Link } from 'react-router-dom';

const Feature = () => {
    return (
        <div className="py-12 text-center">
            <h2 className="text-4xl font-bold mb-4">Our Featured Articles</h2>
            <p className="text-orange-500 text-lg mb-8">Discover Our Recent Work!</p>
            <div className="flex flex-wrap justify-center">
                {/* Card 1 */}
                <div data-aos="zoom-in" data-aos-duration="1500">
                <div className="bg-white p-3 rounded-lg shadow-md mx-2 mb-8" style={{ maxWidth: '300px' ,maxHeight:'600px'}}>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/79/Shops_inside_South_Melbourne_Market.jpg/2560px-Shops_inside_South_Melbourne_Market.jpg" alt="Article 1" className="img-fluid mb-4" />
                    <h3 className="text-xl font-semibold mb-2" style={{textAlign:'start'}}>AI in Supply Chain: A Real-world Case Study on Harnessing AI’s Potential</h3>
                    <p className="text-gray-700 mb-4" style={{textAlign:'justify'}}>Drones have transformed the realms of photography, recreation, and many professional fields. However, flying a drone responsibly requires... </p>
                    <Link to='/case-studies/Retail&AI' className="bg-orange-500 text-white rounded-full py-2 px-6 hover:bg-orange-600">Explore</Link>
                </div>
                </div>
                {/* Card 2 */}
                <div data-aos="zoom-in" data-aos-duration="2500">
                <div className="bg-white p-3 rounded-lg shadow-md mx-2 mb-8" style={{ maxWidth: '300px' ,maxHeight:'600px' }}>
                    <img src="https://robex.us/wp-content/uploads/2021/12/shutterstock_2075069899-scaled.jpg"  alt="Article 2" className="img-fluid mb-4" />
                    <h3 className="text-xl font-semibold mb-2" style={{textAlign:'start'}}>The Rise of Autonomous Vehicles in China: A Technological Revolution</h3>
                    <p className="text-gray-700 mb-4" style={{textAlign:'justify'}}>The imminent mainstream adoption of autonomous driving technology in China by 2025 represents a monumental...</p>
                    <Link to='/Latest-EVTech' className="bg-orange-500 text-white rounded-full py-2 px-6 hover:bg-orange-600">Explore</Link>
                </div>
                </div>
                {/* Card 3 */}
                <div data-aos="zoom-in" data-aos-duration="3000">
                <div className="bg-white p-3 rounded-lg shadow-md mx-2 mb-8" style={{ maxWidth: '300px' ,maxHeight:'600px'}}>
                    <img src="https://img.goodfon.com/original/2560x1440/a/ee/nurse-doctor-medicine.jpg" alt="Article 3" className="img-fluid mb-4" />
                    <h3 className="text-xl font-semibold mb-2" style={{textAlign:'start'}}>Self-Diagnosis of Disease With a Chatbot In Your Hand: The Case of "Left Hand Doctor"</h3>
                    <p className="text-gray-700 mb-4" style={{textAlign:'justify'}}>Intelligent driving technology is poised to become mainstream in China by 2025, marking a significant... </p>
                    <Link to='/case-studies/healthcare' className="bg-orange-500 text-white rounded-full py-2 px-6 hover:bg-orange-600">Explore</Link>
                </div>
                </div>
                {/* Card 4 */}
                <div data-aos="zoom-in" data-aos-duration="3500">
                <div className="bg-white p-3 rounded-lg shadow-md mx-2 mb-8" style={{ maxWidth: '300px',maxHeight:'600px' }}>
                    <img src="https://i0.wp.com/thedronelifenj.com/wp-content/uploads/2022/08/DSC02262-scaled.jpg" alt="Article 4" className="img-fluid mb-4" />
                    <h3 className="text-xl font-semibold mb-2" style={{textAlign:'start'}}>Embracing The Future: New trends in drones & drone technologies and their applications</h3>
                    <p className="text-gray-700 mb-4" style={{textAlign:'justify'}}>As we soar into a new era of technological innovation, drones are rapidly becoming integral to...</p>
                    <Link to='/Drone-Technology' className="bg-orange-500 text-white rounded-full py-2 px-6 hover:bg-orange-600">Explore</Link>
                </div>
                </div>
            </div>
            <p className="text-center">Innovative & Sustainable Solutions. <span className='text-orange-500 border-b-3 border-orange-500'> Find Your Solution?</span></p>
        </div>
    );
};

export default Feature;
