import React from 'react'
import { Link } from 'react-router-dom'
function Printing() {
  return (
    <div className='vid'>
      <div className="aidetails mt-5 pt-md-0 pt-20 pb-5 pt-md-0">
                <div className="container mt-5 pt-20 pt-md-0" >
                    <div className="row mt-5 pt-5" data-aos="fade-left" data-aos-duration="2000">
                        <div className="col-lg-6 mt-5 pt-5">   
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Precision Engineering with 3D Printing Technology</h1>
                            <p className='pt-3'style={{textAlign:'justify'}}>Our advanced 3D printing solutions harness the latest additive manufacturing techniques to achieve precision engineering with unparalleled accuracy.</p>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Using a layer-by-layer approach, our printers can fabricate intricate components with micron-level precision, allowing for the creation of complex geometries and intricate details that traditional manufacturing methods struggle to replicate. We offer a diverse selection of high-performance materials tailored to meet the demands of various industries and applications. </p>
                            <div className="container mt-8" >
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/ai1.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-lg text-center">Baidu AI</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/smart.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-lg text-center">Smart City</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/vision.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-lg text-center">3D Vision</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                            <img className='img-fluid' src='/Pattern2.png'/>
                        </div>
                    </div>
                    <div className="row mt-5 pt-5" data-aos="fade-right" data-aos-duration="2000">
                        <div className="col-lg-5 mt-5 pt-5 me-0 me-md-5">
                            <img className='img-fluid' src='/Pattern.png'/>
                        </div>
                        <div className="col-lg-6 mt-5 pt-5"> 
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Customization and Prototyping</h1>   
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Our materials range from standard thermoplastics like ABS and PLA to engineering-grade polymers, resins, and even metal powders. Each material boasts unique properties such as strength, flexibility, heat resistance, and chemical resistance, ensuring suitability for a wide range of projects, from rapid prototyping to end-use parts production.</p>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Our 3D printing services empower you to bring your designs to life quickly and cost-effectively. Whether you're prototyping a new product, customizing components for a specific application, or producing small batches of specialized parts, our rapid prototyping capabilities enable fast iteration and validation of designs. With minimal setup costs and lead times, you can accelerate your product development cycle and get to market faster than ever before.</p>
                             <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5" ><Link to='/baiduai'>Discover BaiduAI</Link></button>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="smartcity container-fluid">   
                <div className=" row mt-5 pt-5">
                    <div className="container">
                        <div className="row">

                        
                        <div className="col-lg-6 mt-5 pt-5" data-aos="fade-right" data-aos-duration="2500">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }}>Precision Laser Cutting and Engraving</h1>
                            <p className='pt-3'style={{color:'white', textAlign:'justify'}}>Our laser printing solutions offer unmatched precision and versatility for cutting and engraving a wide range of materials. </p>
                            <p className='pt-3' style={{color:'white',textAlign:'justify'}}>Equipped with high-powered lasers and advanced control systems, our laser machines can cut through thick materials with precision and efficiency while maintaining sharp edges and intricate details. Whether you need precise cuts for intricate parts or detailed engraving for branding and personalization, our laser systems deliver exceptional results with consistency and reliability.</p>
                            <button className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5 ">Discover Smart City Solutions</button>
                        </div>
                        <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                            <img className='img-fluid' src='/smartimg.png' data-aos="fade-left" data-aos-duration="2500"/>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
               
    </div>
  )
}

export default Printing
