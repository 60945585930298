import React from 'react';
import { Link } from 'react-router-dom';

function DigitalManufacturingTechnologies() {
    return (
        <div className='vid'>
            <div className="aimain container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Digital Manufacturing Technologies</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">
                            Digital manufacturing technologies encompass a range of cutting-edge tools and processes that integrate digital systems into manufacturing operations. This includes CAD/CAM integration, Internet of Things (IoT), and artificial intelligence (AI), all aimed at enhancing efficiency, precision, and flexibility in the manufacturing environment.
                        </p>
                        <div className="container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="https://i.imgur.com/idGENIw.png" alt="CAD/CAM Integration" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">CAD/CAM Integration</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/iot.png" alt="Internet of Things (IoT)" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Internet of Things (IoT)</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="2000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/ai1.png" alt="AI & Machine Learning" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">AI & Machine Learning</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section for detailed information about CAD/CAM Integration */}
            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3' src='https://www.loadpointprecisionengineering.co.uk/wp-content/uploads/2016/03/What-is-CAD-CAM.jpg' data-aos="fade-down" data-aos-duration="2500" alt="CAD/CAM Integration" />
                        </div>
                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">CAD/CAM Integration</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">
                                CAD/CAM integration involves the seamless connection between computer-aided design (CAD) and computer-aided manufacturing (CAM) systems, streamlining the process from product design to manufacturing.
                            </p>
                            <div className="row pb-3">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://damassets.autodesk.net/content/dam/autodesk/www/collections/fy24/product-design-manufacturing/included-software/images/software-plan-optimize-manufacturing-video-thumb-1172x660.jpg' data-aos="fade-down" data-aos-duration="2500" alt="Software for Integrated Product Design and Manufacturing" />
                                    <p className='pt-1 text-sm'>Software for Integrated Product Design and Manufacturing</p>
                                </div>
                            </div>
                            <Link to='/Digital-technology/CAD-CAM' className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section for detailed information about Internet of Things (IoT) */}
            <div className="aidetails pt-md-0 pt-3 pb-1 pt-md-0 pb-5">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3' src='https://omdia.tech.informa.com/-/media/tech/omdia/omdia-website-enhancement-oct-2023/iot-coverage-2.jpg?rev=4fa2eb28f841448c9d126f911019398f' data-aos="fade-down" data-aos-duration="2500" alt="Internet of Things (IoT)" />
                        </div>
                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Internet of Things (IoT)</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">
                                IoT integrates sensors and networks into manufacturing processes, enabling real-time monitoring and smart factory implementations that enhance efficiency and decision-making.
                            </p>
                            <div className="row pb-3">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://partners.sigfox.com/assets/medias/ko9uv193.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="Sensors and Networks for Real-time Monitoring" />
                                    <p className='pt-1 text-sm'>Sensors and Networks for Real-time Monitoring</p>
                                </div>
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://d12oja0ew7x0i8.cloudfront.net/images/Article_Images/ImageForArticle_22236_1669302434643603.jpg' data-aos="fade-down" data-aos-duration="2500" alt="Smart Factories" />
                                    <p className='pt-1 text-sm'>Smart Factories</p>
                                </div>
                            </div>
                            <Link to='/Digital-technology/IOT' className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section for detailed information about AI & Machine Learning */}
            
        </div>
    );
}

export default DigitalManufacturingTechnologies;
