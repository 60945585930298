import React from 'react'

function Partners() {
  return (
    <div className='vid'>
      <div className="smartcity container-fluid pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-6 pt-5 mt-3  ">
            <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Our Cooperation Universities: Collaborating for a Better Future</h1>
            <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">We collaborate with leading universities to drive innovation in metallurgy, technology, and agriculture, fostering a rich environment for research and development. These partnerships help cultivate the next generation of experts, ensuring a continuous influx of top-tier talent into our projects. Joint ventures with academia enable us to stay ahead of technological advancements and bring revolutionary products and solutions to market.</p>
            {/* <button className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3">Discover More Universities</button> */}
          </div>
        </div>
      </div>

      <div className=" container mt-8">
                <div className="row justify-content-center">
                <h1 className='font-semibold text-center mb-5 mt-5' style={{ color: 'black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Our Partners</h1>
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0 text-center">
                            <img src="/3d-pri.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                            <p className="text-sm text-center">Tsinghua University </p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>Located in Beijing, it is one of China’s most prestigious universities, especially renowned for its engineering and technological programs, including materials science.</p>
                            <button  className="mainbtn2  border  rounded-full py-1 px-4 mr-4 hover:bg-orange-600 mt-2"><a href='https://www.tsinghua.edu.cn/en/'>Visit website</a></button>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1500" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0 text-center">
                            <img src="/robotic-arm.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                            <p className="text-sm text-center">University of Science and Technology Beijing (USTB) </p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>Known for its strong emphasis on materials sciences and metallurgy, USTB offers extensive research and development programs in iron and steel metallurgy.</p>
                            <button className="mainbtn2  border  rounded-full py-1 px-4 mr-4 hover:bg-orange-600 mt-2"><a href='https://en.ustb.edu.cn'>Visit website</a></button>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1500" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0 text-center">
                            <img src="/face.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                            <p className="text-sm text-center">Northeastern University (Shenyang) </p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>Specializes in materials science and engineering, providing comprehensive programs in metallurgy with a focus on practical and theoretical aspects.</p>
                            <button className="mainbtn2  border  rounded-full py-1 px-4 mr-4 hover:bg-orange-600 mt-2"><a href='https://english.neu.edu.cn'>Visit website</a></button>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1500" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0 text-center">
                            <img src="/membrane.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto tezt-center" />
                            <p className="text-sm text-center">Shanghai Jiao Tong University</p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>A top player in research and innovation, particularly in the fields of engineering and technology.</p>
                            <button className="mainbtn2  border  rounded-full py-1 px-4 mr-4 hover:bg-orange-600 mt-2"><a href='https://en.sjtu.edu.cn'>Visit website</a></button>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1500" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0 text-center">
                            <img src="/manufacturing.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto text-center" />
                            <p className="text-sm text-center">Harbin Institute of Technology </p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>Known for its robust research programs in science and technology, including aerospace and robotics. </p>
                            <button className="mainbtn2  border  rounded-full py-1 px-4 mr-4 hover:bg-orange-600 mt-2"><a href='https://en.hit.edu.cn'>Visit website</a></button>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1500" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0 text-center">
                            <img src="/planet-earth.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto " />
                            <p className="text-sm text-center">Zhejiang University </p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>Offers a diverse array of engineering programs, with strong emphasis on research and innovation.</p>
                            <button className="mainbtn2  border  rounded-full py-1 px-4 mr-4 hover:bg-orange-600 mt-2"><a href='https://www.zju.edu.cn/english/'>Visit website</a></button>
                        </div>
                    </div>
                </div>
            </div>


      

    </div>
  )
}

export default Partners
