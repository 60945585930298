import React from 'react';

function autoarticle() {
    return (
        <div className='vid'>
            <div className="aimainsp25 container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5 pb-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Direct Metal Laser Sintering (DMLS) 3D Printing: A Comprehensive Overview</h1>
                        <p className='pt-4' style={{ color: 'white',textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">This article provides an in-depth look into Direct Metal Laser Sintering (DMLS) technology: its operational principles, compatible materials, advantages, and limitations as a 3D printing technique.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-5 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">What is Direct Metal Laser Sintering?</h1>
                        <p className='pt-4' style={{textAlign:'justify'}} data-aos="fade-left" data-aos-duration="2000">Direct Metal Laser Sintering (DMLS) falls under the Powder Bed Fusion (PBF) category of 3D printing. While it shares similarities with Selective Laser Sintering (SLS) technology, DMLS utilizes metal powders instead of plastic, enabling the production of both functional prototypes and end-use parts. DMLS is often compared to Selective Laser Melting (SLM), but a key difference lies in their processes: SLM fully melts metal powder into a liquid, whereas DMLS sinters metal particles, causing their surfaces to fuse without fully melting.</p>
                        <div className="video-container mt-5" > 
                    <iframe src='https://www.youtube.com/embed/FxzFzbi0wF4' className="responsive-iframe" allowfullscreen data-aos="fade-down" data-aos-duration="2000" autoplay></iframe>
                </div>
                    </div>
                </div>
            </div>
           

            <div className="container mt-3 pt-md-0">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">How Does DMLS Work?</h1>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-right" data-aos-duration="2000">The DMLS process is composed of six fundamental steps:</p>
                        <div className="w-full md:w-4/4">
                            <ul >
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium' ><span className='text-orange-500  text-6xl'>.</span> Layer Slicing: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>The 3D design CAD file is sliced into ultra-thin layers, creating a 2D model for each layer.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Laser Preparation:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>A high-powered laser operates within an inert gas-filled build chamber. </p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Powder Dispensing:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>A material dispensing platform and a build platform, along with a roller, move new powder over the build platform layer by layer.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Selective Sintering: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>The laser selectively sinters the powder into a solid as each new layer of powder is laid down.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Post-Processing:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}> After printing, the loose metal powder is removed, and support structures are taken off.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Further Processing:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}> DMLS parts undergo additional treatments like machining, heat treatment, or surface finishing, similar to conventionally manufactured metal parts.</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-3 pt-md-0">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Compatible Materials for DMLS</h1>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-right" data-aos-duration="2000">Common materials used in DMLS include:</p>                        <div className="w-full md:w-4/4">
                            <ul>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium' ><span className='text-orange-500  text-6xl'>.</span> Aluminum: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>AlSiMg</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Steel:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Tool Steel MS1, Stainless Steel 17-4, Stainless Steel 316L</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Inconel:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Inconel 718</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

  
           

       

            <div className="container mt-3 pt-md-0 ">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Advantages of DMLS Technology</h1>
                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Enabling Complex Designs</h2>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">DMLS excels in producing parts with intricate geometries that are challenging or costly to achieve with traditional manufacturing methods. It allows for the creation of integrated fastening features, long channels, and mesh structures, simplifying assemblies by combining multiple components into a single design.</p>

                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Quick Turnaround Time</h2>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">Unlike conventional manufacturing, which requires extensive setup and tooling, DMLS prints parts on demand, reducing lead time significantly. This rapid prototyping capability accelerates the development process.</p>

                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Lightweight and Durable Components</h2>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">DMLS produces lightweight yet durable components from superalloys like Inconel 718 and aluminum alloys such as AlSi10Mg. For example, GE’s 3D-printed fuel nozzles for its LEAP engines, made as a single piece, are 25% lighter and five times stronger than their traditionally manufactured counterparts.</p>
                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Minimizing Waste</h2>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">Unused metal powder in DMLS can be recycled and reused, resulting in significantly less waste compared to traditional machining methods, which generate considerable scrap metal.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-3 pt-md-0 ">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Considerations and Limitations of DMLS Technology</h1>
                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Necessity of Support Structures</h2>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">DMLS requires support structures that need to be removed in post-processing. This step is crucial but adds to the overall production time and complexity.</p>

                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Grainy Surface Finish</h2>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">DMLS-printed parts often have a rough surface finish, necessitating additional post-processing to achieve a smoother and more aesthetically pleasing appearance.</p>

                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Expensive Serial Production</h2>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">While DMLS is advantageous for prototyping and small-batch production, it cannot compete with the speed and efficiency of conventional mass production methods.</p>
                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Limited Material Selection</h2>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">The range of materials available for DMLS is narrower compared to traditional manufacturing, which can be a constraint for specific applications requiring unique material properties.</p>
                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Limited Build Volume</h2>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">DMLS is best suited for smaller parts, with a typical build volume of up to 250 x 250 x 325 mm. For larger components, traditional CNC machining is more appropriate, accommodating sizes up to 2000 x 800 x 1000 mm.</p>
                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Shrinkage and Limited Repeatability</h2>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">Producing identical parts with DMLS can be challenging due to natural shrinkage during the process. Variations can occur between batches, affecting dimensional accuracy, especially in the vertical (Z) direction.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-3 pt-md-0 pb-5">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Conclusion</h1>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">Direct Metal Laser Sintering (DMLS) offers significant advantages for producing complex, durable, and lightweight metal parts with reduced lead times and minimal waste. However, it also presents challenges, including the need for post-processing, limited material options, and higher costs for mass production. Understanding these aspects is crucial for leveraging DMLS effectively in various industrial applications.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default autoarticle;
