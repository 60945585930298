import React from 'react'

function autoarticle() {
    return (
        <div>
            <div className="aimainsp21 container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5 pb-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">How to conduct a drone mining survey in 5 steps</h1>
                        <p className='pt-4' style={{ color: 'white',textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">Conducting a drone mining survey involves several critical steps to ensure accurate and actionable data is collected for effective mine management. This process leverages advanced drone technology to capture detailed aerial imagery and data, which can then be used for various applications such as mapping, monitoring, and analyzing mining sites. Here's an introduction to the five essential steps to conduct a successful drone mining survey.</p>
                    </div>
                </div>

            </div>

            <div className="container mt-5 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">

                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Choose a durable drone unaffected by dust and rocky ground</h1>
                        <p className='pt-4' style={{textAlign:'justify'}} data-aos="fade-left" data-aos-duration="2000">First make sure your drone will get all the data you need without setbacks or damage.</p>
                        <img className='img-fluid pt-5' src='https://cdn-focjb.nitrocdn.com/RzUKmdHdCIStbdZtPxHaJLNXjEKZQBeM/assets/images/optimized/rev-6e26a50/wingtra.com/wp-content/uploads/202210-Wingtra-Mining-Page3.jpg'/>

                        

                    </div>

                </div>
            </div>



            <div className="container mt-3 pt-md-0">
                <div className="row  justify-content-center">

                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Make sure your mining drone collects good data over changing heights</h1>
                        <img className='img-fluid pt-5' src='https://www.thedroneu.com/wp-content/uploads/2023/02/DroneU-Terrain-Mapping-e1627474095495.jpg'/>

                        <p className='pt-4 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">For open pit mining inspection applications, you’ll need an aerial survey that offers enough accuracy from the deepest part of your site.</p>
                    </div>

                </div>
            </div>
            <div className="container mt-3 pt-md-0">
                <div className="row  justify-content-center">

                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Set up your active site ground control</h1>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">Depending on the drone you choose, your setup will be more or less involved. A mine drone with a post-processed kinematic (PPK) receiver will cut down your ground control significantly, because the technology corrects the data so you will only need a few checkpoints to verify accuracy.</p>
                    </div>

                </div>
            </div>
            <div className="container mt-3 pt-md-0">
                <div className="row  justify-content-center">

                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Fly the drone</h1>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">Good drones are easy to operate and see from a distance as you capture data. They will also get the most data in the least amount of flights and time so that you can take advantage of good weather and free up your time for other projects.</p>
                        <img className='img-fluid pt-5' src='https://cdn-focjb.nitrocdn.com/RzUKmdHdCIStbdZtPxHaJLNXjEKZQBeM/assets/images/optimized/rev-6e26a50/wingtra.com/wp-content/uploads/drones-for-mining-coverage-comparison-2.jpg'/>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">As a real-world example, John Adjaye, Unit Survey Manager at Gold Fields Ghana’s Tarkwa Mine, commented about the WingtraOne VTOL aerial mapping platform: “We can do one flight and cover all our stockpiles. This one is such a plus, and it works well for us. We can then come down and process all that data at a go.”</p>

                    </div>

                </div>
            </div>
            <div className="container mt-3 pt-md-0">
                <div className="row  justify-content-center">

                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Process, analyze and share the data</h1>
                        <img className='img-fluid pt-5' src='https://cdn-focjb.nitrocdn.com/RzUKmdHdCIStbdZtPxHaJLNXjEKZQBeM/assets/images/optimized/rev-6e26a50/wingtra.com/wp-content/uploads/Wingtra-Mining-Page2.png'/>

                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">Make sure your aerial drone data is compatible with the software you plan to use for outputs. Check software to see what works best for sharing information, inspection, reconciliation, auditing, reporting and planning.</p>
                    </div>

                </div>
            </div>
            <div className="container mt-3 pt-md-0">
                <div className="row  justify-content-center">

                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Why is stockpile management better with drones?</h1>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">Whether for piles of Earth or rare minerals, stockpile management surveys can either cost or save a lot of money. To get the best volume information, you need an accurate measure of a surface to calculate what is inside. If that surface is inaccurate, the volume accuracy will be much moreso. For example, an inch difference across a surface is going to be hundreds of thousands of square inches of volume difference. </p>
                    </div>

                </div>
            </div>
            <div className="container mt-3 pt-md-0 pb-5">
                <div className="row  justify-content-center">

                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">What are the safety benefits of drones in mining?</h1>
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">1- Preventing accidents and downtime</h1>

                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">Mine site accidents are tragic, expensive and—thanks to mining drones—more avoidable than ever. In fact, with a PPK-equipped drone, you only need to enter a site once to place checkpoints. This boosts safety among surveyors, since the actual data collection can be done from a distance for all surveys of the same area. Surveyors stay safe, and your site stays running and incident-free.</p>
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">2- Removing risks in underground environments</h1>

                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">Drone technology in underground mining situations replaces human checking of ground conditions, tunnel walls, blasting areas and general site stability so that any risks can be discovered without exposing workers to them.</p>
                    </div>

                </div>
            </div>
            

        </div>


    )
}

export default autoarticle
