import React from 'react';
import { Link } from 'react-router-dom';

function SustainableManufacturing() {
    return (
        <div className='vid'>
            <div className="aimain container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Sustainable Manufacturing</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">
                            Sustainable manufacturing focuses on creating products through economically-sound processes that minimize negative environmental impacts while conserving energy and natural resources. This approach also enhances employee, community, and product safety.
                        </p>
                        <div className="container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/bati.png" alt="Energy-efficient Processes" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Energy-Efficient Processes</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/ecology.png" alt="Waste Reduction Techniques" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Waste Reduction Techniques</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section for detailed information about Energy-efficient Processes */}
            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3' src='https://img.processingmagazine.com/files/base/ebm/pcm/image/2023/05/16x9/dreamstime_xxl_175118703.644fce685ef57.png?auto=format,compress&fit=fill&fill=blur&w=1200&h=630' data-aos="fade-down" data-aos-duration="2500" alt="Energy-efficient Processes" />
                        </div>
                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Energy-efficient Processes</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">
                                Energy-efficient processes aim to reduce energy consumption in manufacturing operations through innovations such as regenerative braking systems in machinery and the use of low-energy consumption machinery.
                            </p>
                            <div className="row pb-3">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://themechanicalengineering.com/wp-content/uploads/2021/03/types-of-braking-system.jpg' data-aos="fade-down" data-aos-duration="2500" alt="Regenerative Braking Systems" />
                                    <p className='pt-1 text-sm'>Regenerative Braking Systems in Machinery</p>
                                </div>
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://www.crowntex.com/images/products/5-5.jpg' data-aos="fade-down" data-aos-duration="2500" alt="Low-energy Consumption Machinery" />
                                    <p className='pt-1 text-sm'>Low-energy Consumption Machinery</p>
                                </div>
                            </div>
                            <Link to='/Sustainable-Manufacturing/Energy-optimization' className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section for detailed information about Waste Reduction Techniques */}
            <div className="aidetails pt-md-0 pt-3 pb-1 pt-md-0 pb-5">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3' src='https://www.wastereduction.gov.hk/sites/default/files/2022-07/106927248_m_16x9_3.jpg' data-aos="fade-down" data-aos-duration="2500" alt="Closed-loop Recycling Systems" />
                        </div>
                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Waste Reduction Techniques</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">
                                Waste reduction techniques focus on minimizing waste through methods such as closed-loop recycling systems and efficient material utilization.
                            </p>
                            <div className="row pb-3">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://www.mchughsteel.com.au/media/102586/mchugh-steel-112.jpg?anchor=center&mode=crop&width=795&height=418&rnd=133409808180000000' data-aos="fade-down" data-aos-duration="2500" alt="Efficient Material Utilization" />
                                    <p className='pt-1 text-sm'>Efficient Material Utilization</p>
                                </div>
                            </div>
                            <Link to='/Sustainable-Manufacturing/waste-reduction' className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SustainableManufacturing;
