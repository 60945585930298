import React from 'react'

function autoarticle() {
    return (
        <div>
            <div className="aimainsp9 container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5 pb-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">AI in Supply Chain: A Real-world Case Study on Harnessing AI’s Potential</h1>
                        <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2000">In today's complex business environment, leveraging AI in the supply chain has become essential for companies aiming to maintain a competitive edge. This article highlights the critical role of AI in supply chain optimization and shares a success story demonstrating the substantial benefits AI can bring to supply chain management. A supply chain encompasses the entire network of individuals and organizations involved in producing and delivering a product or service to the end consumer. It includes every stage, from sourcing raw materials, manufacturing, and assembly, to transportation, warehousing, and finally, getting the product into the customer's hands.</p>

                    </div>
                </div>

            </div>

            <div className=" container mt-8">
                <div className="row justify-content-center">
                    <h1 className='font-semibold text-center mb-5 mt-5' style={{ color: 'black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Survey Statistics</h1>
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0 text-center">

                            <p className='pt-3 pb-5 text-xl' style={{ color: '#f77d27', textAlign: 'justify', fontSize: '69px' }}>49%</p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}><b>Respondents from the supply chain sector expressed that disruptions led to planning difficulties.</b></p>

                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0 text-center">

                            <p className='pt-3 pb-5 text-xl' style={{ color: '#f77d27', textAlign: 'justify', fontSize: '69px' }}>44%</p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}><b>Indicated challenges due to supply chain footprint, which required them to make changes per year.</b></p>

                        </div>
                    </div>
                </div>
            </div>




            <div className="container mt-3 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">

                    <div className="col-lg-8 mt-2">
                        <p className='pt-4 ' style={{ textAlign: 'justify' }} data-aos="fade-left" data-aos-duration="2000">Today, this multifaceted network faces numerous disruptions, including geopolitical tensions, economic fluctuations, climate change, and evolving regulations. At the same time, common goals such as customer satisfaction, cost optimization, resilience, and adaptability transcend industries. Addressing both these challenges and goals requires innovative solutions, making supply chain optimization a critical factor in ensuring efficiency.</p>
                    </div>

                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">

                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Brief overview of supply chain optimisation</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">Supply chain optimization (SCO) is a complex field dedicated to managing the flow of goods and services from raw materials to the end customer in the most efficient and cost-effective manner possible. It involves fine-tuning a supply chain's operations to ensure peak efficiency.</p>
                        <h1 className='font-semibold pt-3' style={{ color: 'Black', fontSize: '25px', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2000">Top Five Challenges of Supply Chain Optimization:</h1>
                        <div className="w-full md:w-4/4">
                            <ul >
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium' ><span className='text-orange-500  text-6xl'>.</span> Demand Forecasting: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Predicting customer demand accurately is crucial for optimizing inventory levels, transportation options, and production schedules.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Inventory Management:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Balancing the cost of holding inventory with the risk of stockouts. </p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Transportation Optimization:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Selecting the most efficient and cost-effective routes for transporting goods, considering factors such as traffic congestion and fuel prices.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Supplier Selection and Management:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Finding reliable suppliers who offer competitive prices and high-quality products.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Collaboration and Communication:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Ensuring efficient cooperation among all players in the supply chain.</p>
                                </div>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">

                    <div className="col-lg-8  pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Optimizing Supply Chain Management through AI Integration</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">To tackle these challenges, SCO employs data analytics, mathematical modeling, optimization algorithms, artificial intelligence (AI), and machine learning (ML). These tools collectively empower businesses to navigate the dynamic landscape of global supply chains with precision.</p>
                        <img className='img-fluid pt-4' src='https://d3lkc3n5th01x7.cloudfront.net/wp-content/uploads/2023/04/14003540/The-role-of-AI-in-logistics-and-supply-chain-banner.png' data-aos="fade-down" data-aos-duration="2500" />
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">AI plays a significant role in enhancing supply chain efficiency, with applications across various aspects of supply chain management.</p>
                    </div>

                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Enhancing Visibility and Transparency:</h1>
                        <div className="w-full md:w-4/4">
                            <ul >
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium' ><span className='text-orange-500  text-6xl'>.</span> Real-Time Data Collection and Analysis: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>AI provides real-time insights into inventory levels, shipment locations, and potential disruptions by collecting and analyzing data continuously.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Predictive Analytics:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>AI analyzes historical data and external factors like weather forecasts or economic trends to predict potential disruptions and maintenance needs. </p>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Optimizing Operations and Decision-Making:</h1>
                        {/* <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">Many smart cities start with traffic control. London uses digital signs to post speed limits that change to optimize traffic flow and employs license-plate recognition to charge tolls for entering a low-emission zone in the city center. Cities in Belgium and France are considering similar systems, and Singapore is testing autonomous shuttles on a 5.7-mile stretch of its streets. Nearby, Jakarta uses a traffic-monitoring system that taps AI to recognize license plates of vehicles with unpaid taxes.</p> */}
                        <div className="w-full md:w-4/4">
                            <ul >
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium' ><span className='text-orange-500  text-6xl'>.</span> Inventory Management: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>AI optimizes inventory levels by forecasting demand, considering lead times and seasonality patterns, and minimizing costs associated with overstocking or understocking.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Route Optimization:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>AI algorithms determine the most efficient and cost-effective routes for transportation by factoring in traffic conditions, fuel consumption, and schedules to balance transportation and inventory costs.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Warehouse Automation:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>AI-powered robots automate tasks like picking, packing, and sorting, improving speed, accuracy, and efficiency in warehouses.</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <img className='img-fluid pt-3 pb-3' src='https://miro.medium.com/v2/resize:fit:1152/1*WfdUnM6xRabe6Cf0CT9Cfw.jpeg' data-aos="fade-down" data-aos-duration="2500" />
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Enhancing supply chain logistics – AIM's solution overview</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">To meet our client's requirements, we created a system that leverages the benefits of the Integer Programming technique, which optimises supply schedules, routes, and inventory levels for five kinds of items: ceramic sinks, toilets, bidets, gas valves, and bathtub mixers (corresponding HS codes are 6910.10.0030, 6910.10.0010, 6910.10.0025, 8414.90.1080, 7323.93.0080). The system comprises three main parts (or pages): configuration, simulation, and analytics. Each of them will be explained in the following paragraphs.</p>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">To ensure the confidentiality of data and application details, we anonymised names and real locations, creating a prototype for a straightforward demo application that illustrates the efficacy of AI in addressing optimisation challenges.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Configuration page</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000">The user can select available warehouses, distribution points, and desirable seaports to take part in final optimised routing. Also, the client can specify desirable goods and their respective amount to be transported to their customers. The last thing is the date picker, in case delivery is planned. The system will calculate remains inventory at the delivery date based on forecasted demand and allocate planned shipments aligned with warehouse residuals. Clicking the 'optimise' button does all the magic! It sets optimisation tasks and launches its solution. After a few seconds, results can be observed on the remaining two pages.</p>
                        <img className='img-fluid mt-3 pt-3 pb-3 shadow-lg' src='https://eleks.com/wp-content/uploads/01.jpg' data-aos="fade-down" data-aos-duration="2500" />
                        <img className='img-fluid pt-3 pb-3 shadow-lg' src='https://eleks.com/wp-content/uploads/02.jpg' data-aos="fade-down" data-aos-duration="2500" />
                        <img className='img-fluid pt-3 pb-3 shadow-lg' src='https://eleks.com/wp-content/uploads/03.jpg' data-aos="fade-down" data-aos-duration="2500" />
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Simulation (map) page</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">This page offers the client the opportunity to view an accurate simulation of the proposed model's solution. In prior discussions, we deliberated on the suitable INCOTERMS for our client and reached a consensus on CFR. The customer can specify the number of hours for each step and press the corresponding button to see the state of the supply chain in this amount of time. First of all, there are five types of points: two for transport vehicles (ship and truck) and three for seaports (orange), warehouses (purple), and distribution points (green). The map is interactive, meaning the user can hover over any point or connection and see the information related to that object. For example, blue lines connecting warehouses and distribution points show the last-mile delivery plan and contain information about how much each good is delivered daily. If the user hovers over the ship, the information about its capacity, container types, loaded amount of each good, and routing are displayed.</p>
                        <img className='img-fluid mt-3 pt-3 pb-3 shadow-lg' src='https://eleks.com/wp-content/uploads/04.jpg' data-aos="fade-down" data-aos-duration="2500" />
                        <img className='img-fluid pt-3 pb-3 shadow-lg' src='https://eleks.com/wp-content/uploads/05.jpg' data-aos="fade-down" data-aos-duration="2500" />
                        <img className='img-fluid pt-3 pb-3 shadow-lg' src='https://eleks.com/wp-content/uploads/06.jpg' data-aos="fade-down" data-aos-duration="2500" />
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-5">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Analytics page</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">This page features all the data and statistics about the model's proposed solution. The user can observe the delivery plan, where each ship and truck are, its estimated arrival time, route, and filling. There are also a bunch of charts, starting from the pie chart with expenses altogether and ending with heat maps of delivery costs between different seaports, warehouses, and distribution points. Additionally, the client can observe a price graph per good for different distribution points and, based on that data, make corresponding business decisions.</p>
                        <img className='img-fluid mt-3 pt-3 pb-3 shadow-lg' src='https://eleks.com/wp-content/uploads/10.jpg' data-aos="fade-down" data-aos-duration="2500" />
                        <img className='img-fluid pt-3 pb-3 shadow-lg' src='https://eleks.com/wp-content/uploads/11-1-1.jpg' data-aos="fade-down" data-aos-duration="2500" />
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-5">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Performance metrics: AIM's solution vs client’s legacy system</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">The next challenge was to test our solution in real-world scenarios and compare it to the client's approach. For this purpose, the client considered a period of 1 month. The main conditions of the task (such as available seaports, location and number of warehouses and clusters) are observed in the illustrations in the previous paragraphs. As a result, our model's solution was more effective regarding operating expenses, average time of last-mile delivery, and reduced idle time of warehouses.</p>
                        
                    </div>
                </div>
            </div>
            <div className=" container pb-5">
                <div className="row justify-content-center">
                    
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0 text-center">

                            <p className='pt-3 pb-5 text-xl' style={{ color: '#f77d27', textAlign: 'justify', fontSize: '69px' }}>5.76%</p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}><b>average monthly cost savings achieved by the client</b></p>

                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0 text-center">

                            <p className='pt-3 pb-5 text-xl' style={{ color: '#f77d27', textAlign: 'justify', fontSize: '69px' }}>50%</p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}><b>reduction in delivery time for some clusters</b></p>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-5">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Conclusion</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">The integration of artificial intelligence (AI) in supply chain management emerges as a critical component for businesses aiming to navigate the complexities of global logistics effectively. The multifaceted nature of modern supply chains, coupled with challenges such as disruptions, geopolitical tensions, and economic fluctuations, necessitates innovative solutions for achieving efficiency and resilience.</p>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">The presented solution highlights AI's transformative role in streamlining supply chain managers' responsibilities and demonstrates the tangible benefits of AI and IP in supply chain logistics, showcasing a 5.76% average monthly cost savings, a 50% reduction in delivery time for some clusters. Its adaptability allows seamless adjustments, offering users access to a simulation studio, where they can execute various scenarios involving diverse items and quantities, facilitating a comparative analysis to determine optimal strategies for different situations.</p>
                        
                    </div>
                </div>
            </div>

        </div>


    )
}

export default autoarticle
