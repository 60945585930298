import React from 'react';
import { Link } from 'react-router-dom';

function VisionAI() {
    return (
        <div className='vid'>
            <div className="aimain container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">The Future of Transport with State of the Art Chinese EVs</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">The transportation industry is undergoing a significant transformation with the advent of electric vehicles (EVs). Among the leading innovators in this space are Chinese EV manufacturers, who are setting new benchmarks with their cutting-edge technologies and forward-thinking designs. </p>
                        <div className="container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/electric-car.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Passenger EVs</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="https://i.imgur.com/jSzvnXI.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Commercial EVs</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container  pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">

                            <img className='img-fluid  pt-3 ' src='https://img.cnevpost.com/2022/12/30135047/2022123005504255.jpg'  alt="3D Vision AI Technology" />

                        </div>

                        <div className="col-lg-6 mt-1 order-1 order-lg-2 pb-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Passenger EVs</h1>
                            <h1 className='font-semibold ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Li-7 : Flagship 5 Seater SUV</h1>
                            <p className='mt-3' data-aos="fade-left" data-aos-duration="2000">The flagship exterior design boasts breathtaking beauty and details. A more agile overall shape, a more dynamic stance, a more rounded and fuller rear design, paired with the halo light–a true masterpiece on the road.</p>
                            <div className="w-full md:w-4/4">
                            <div className="row">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Built for Families</strong>
                                <p className="text-sm">A long-distance ride is no longer tiring.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Four-seat Massage</strong>
                                <p className="text-sm">Spa-grade 10-point massage comes standard.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Five-Seat Heating</strong>
                                <p className="text-sm">Three levels of seat heating come standard</p>
                            </div>
                            </div>
                    </div>
                    {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000"><Link to=''>Read More</Link></button>  */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails pt-md-0 pt-3 pb-1 pt-md-0 pb-5">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-6">
                        {/* <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Energy Monitoring</h1> */}
                            <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Li-L8: Luxury 6 Seat Family SUV</h1>
                            <p className='mt-3' data-aos="fade-left" data-aos-duration="2000">Designed with Thoughtful Configurations for Convenience. Hundreds of luxury configurations, including a small table on the right side of the second row and a smart refrigerator, provide the ultimate comfort for the family in all aspects.</p>
                            <div className="row">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Relaxing Seats</strong>
                                <p className="text-sm">Embrace full relaxation every single moment.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Customized Storage</strong>
                                <p className="text-sm">The spacious trunk ensures effortless storage for luggage.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Audio-Visual Room</strong>
                                <p className="text-sm">New platinum audio system, with double folding ring.</p>
                            </div>
                            </div>
                            
                            {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000"><Link to='/3D-Vision-AI-Casestudy'>Read More</Link></button> */}
                        </div>
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-5 ' src='https://carnewschina.com/wp-content/uploads/2022/09/LI-L8-1.jpg' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-3 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container  pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">

                            <img className='img-fluid  pt-3 ' src='https://www.ddongauto.com/wp-content/uploads/2023/09/%E5%9B%BE%E5%B1%82-3.jpg'  alt="3D Vision AI Technology" />

                        </div>

                        <div className="col-lg-6 mt-1 order-1 order-lg-2 pb-2">
                            <h1 className='font-semibold ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Li L9 : Flagship 6 Seater Family SUV</h1>
                            <p className='mt-3' data-aos="fade-left" data-aos-duration="2000">Ample Space and Exceptional Comfort in Each Seat and Row. The expansive three-row design offers ample space for each family member to stretch out freely. With the Magic Carpet driver seat, brand-new queen seat, and Spa-grade 10-point massage, the vehicle offers your family a first-class riding experience.</p>
                            <div className="w-full md:w-4/4">
                            <div className="row">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Quiet Castle</strong>
                                <p className="text-sm">At high speeds, the vehicle remains exceptionally quiet.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Extra-Large Trunk</strong>
                                <p className="text-sm">Inbuilt storage compartments and an extra-large trunk.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Five-Seat Heating</strong>
                                <p className="text-sm">Three levels of seat heating come standard</p>
                            </div>
                            </div>
                    </div>
                    {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000"><Link to=''>Read More</Link></button>  */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails pt-md-0 pt-3 pb-1 pt-md-0 pb-5">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-6">
                        {/* <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Energy Monitoring</h1> */}
                        <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Commercial EVs</h1>
                            <h1 className='font-semibold ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">W4 CC: Workhorse</h1>
                            <p className='mt-3' data-aos="fade-left" data-aos-duration="2000">The W4 CC class 4 electric work truck changes the game for last-mile fleets, delivering across-the-board benefits over internal combustion commercial trucks.</p>
                            <div className="row">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Good Range</strong>
                                <p className="text-sm">Powerful 118 kWh battery delivers up to 150 miles.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Zero-Emission</strong>
                                <p className="text-sm">Bring lower operating costs & emissions to any work fleet.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Run Smarter</strong>
                                <p className="text-sm">Monitor performance, and track maintenance in-app.</p>
                            </div>
                            </div>
                            
                            {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000"><Link to='/3D-Vision-AI-Casestudy'>Read More</Link></button> */}
                        </div>
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-5 ' src='https://workhorse.com/wp-content/uploads/2024/02/W4_Gallery__Vehicle_Image.jpg' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-3 pt-md-0 pt-20 pb-1 pt-md-0 pb-5">
                <div className="container  pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">

                            <img className='img-fluid  pt-3 ' src='https://www.pritchardworkhorse.com/models/W750/w750-hero-x.jpg'  alt="3D Vision AI Technology" />

                        </div>

                        <div className="col-lg-6 mt-1 order-1 order-lg-2 pb-2">
                            <h1 className='font-semibold ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">W750 All-Electric Step Van</h1>
                            <p className='mt-3' data-aos="fade-left" data-aos-duration="2000">The W750 has everything you need to make the switch to EV deliveries with confidence; all the range you need to conquer last-mile routes with power to spare.</p>
                            <div className="w-full md:w-4/4">
                            <div className="row">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Good Range</strong>
                                <p className="text-sm">Powerful 118 kWh battery delivers up to 150 miles.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Zero-Emission</strong>
                                <p className="text-sm">Bring lower operating costs & emissions to any work fleet.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Run Smarter</strong>
                                <p className="text-sm">Monitor performance, and track maintenance in-app.</p>
                            </div>
                            </div>
                    </div>
                    {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000"><Link to=''>Read More</Link></button>  */}
                        </div>
                    </div>
                </div>
            </div>


            

            
        </div>
    );
}

export default VisionAI;
