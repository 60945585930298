import React from 'react';
import { Link } from 'react-router-dom';

function AIsolutionsMainPage() {
    return (
        <div className='vid'>
            <div className="baiduaimain container-fluid">
                <div className=" baidubg row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">AI Technology in Manufacturing</h1>
                        <p className='pt-3' style={{ color: 'black' }} data-aos="fade-down" data-aos-duration="2000">By leveraging advanced algorithms and real-time data analytics, our AI-driven systems predict maintenance needs, reduce downtime, and ensure precision in every step of the manufacturing process.</p>
                        <div className=" container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/productivity.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center"> Automation</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/quality-assurance.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Quality Control</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/vision.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">3D Vision</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/autonomous.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">AI Analysis</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/nlp.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Deep Learning</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="2000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/analytics.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Data Tracking</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="smartcity container-fluid pb-5 mb-5">
                <div className=" row mt-5 pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mt-5 pt-5 text-center text-lg-start">
                                <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Take Advantage of the Consistency of Automation Softwares</h1>
                                <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2500">Step into a world of unparalleled efficiency and innovation. Our AI solutions aren't just transformative, they're the driving force behind industry evolution.</p>
                                <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="3000">Automation software is essential for ensuring a high level of functionality and efficiency in all phases of a plant's or machine's life cycle, from the field to the corporate management level. On the basis of SIMATIC software, We help you to shorten your time-to-market through simplified engineering and simulation tools, as well as to increase performance via diagnostics and energy management systems that utilize state of the art AI technology.</p>
                                <button className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5  "data-aos="fade-down" data-aos-duration="2000"><Link to={'/Factory-Automation'}>Discover Automation Solutions</Link></button>
                            </div>
                            <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                                <img className='img-fluid' src='/siem.png' data-aos="fade-down" data-aos-duration="2500"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container mt-0 mt-md-5 pt-md-0 ">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5">
                        <img className='img-fluid' src='/3dvision.png' data-aos="fade-right" data-aos-duration="2000"/>
                        </div>
                        <div className="col-lg-6 mt-5 pt-5 text-center text-lg-start">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Artificial Intelligence & Quality Control</h1>
                            <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">3D Vision for Quality Assurance</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000" style={{textAlign:'justify'}}>In factory automation, where speed, simplicity, and reliability are crucial, the In-Sight L38 excels, offering a fast, easy-to-use 3D inspection solution for maintaining the highest quality. With embedded AI, powerful optics, and industry-proven vision technology, the In-Sight L38 solves a range of inspection, measurement, and guidance applications.</p>
                            <button className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3  "data-aos="fade-down" data-aos-duration="2000"><Link to='/AI-Vision'>Discover 3D Vision</Link></button>
                        </div>
                    </div>
                </div>

            </div>
           
            <div className="aidetails  pb-5 pt-md-0">
                <div className="container  pt-md-0">
                    <div className="row mt-5 pt-0 pt-md-5">     
                        <div className="col-lg-6 pt-0 pt-md-5 order-2 order-lg-1 text-center text-lg-start">
                        <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Automation Sensors for Quality Inspection Systems</h1>
                            <p className='pt-3' style={{ color: '#1E1E1E', textAlign: 'justify' }} data-aos="fade-right" data-aos-duration="2000">Designed for precision and reliability, these sensors seamlessly integrate into your production line to detect defects, measure dimensions, and ensure product consistency. Utilizing advanced technologies like machine vision, laser scanning, and AI-driven analytics, our sensors provide real-time feedback and data-driven insights.</p>
                            <button className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3  "data-aos="fade-down" data-aos-duration="2000"><Link to='/GreenEnergy'>Discover Automation Sensors</Link></button>
                        </div>
                        <div className="col-lg-5  me-0 me-md-5 order-1 order-lg-2 ">
                            <img className='img-fluid' src='/3dvs.png' data-aos="fade-left" data-aos-duration="2000" />
                        </div>
                    </div>
                </div>

            </div>
            <div className="aidetails mt-5 pt-md-0  pb-1 pt-md-0">
                <div className="container mt-5  pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5">
                            <img className='img-fluid' src='/data.png' data-aos="fade-left" data-aos-duration="2000"/>
                        </div>
                        <div className="col-lg-6 mt-0 mt-md-5 pt-0 pt-md-5">
                            <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Data Analysis</h1>
                            <p className='pt-2' data-aos="fade-right" data-aos-duration="2000" style={{ color: '#1E1E1E', textAlign: 'justify' }}>By leveraging machine learning and predictive analytics, we can anticipate defects, optimize inspection protocols, and ensure consistent product quality. Our solutions not only enhance accuracy but also streamline operations, reduce costs, and enable proactive decision-making. </p>
                            
                        </div>
                    </div>
                </div>

            </div>
            <div className="aidetails  pb-5 pt-md-0">
                <div className="container  pt-md-0">
                    <div className="row mt-5 pt-5">
                        <div className="col-lg-6 pt-5 order-2 order-lg-1 text-center text-lg-start">
                        <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Deep AI for Visual Recognition</h1>
                            <p className='pt-3' style={{ color: '#1E1E1E', textAlign: 'justify' }} data-aos="fade-right" data-aos-duration="2000">Our deep AI can analyze the human body gestures and movement trajectory and angle based on the information on the key points of the human body. Also, it assists the athletes in exercise and daily fitness to improve teaching and study efficiency. In the live video platform, you can use such interaction forms as body props, special effects for hand gestures, motion-sensing games, and background replacement, enriching the entertainment experience.</p>
                            <button className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3  "data-aos="fade-down" data-aos-duration="2000"><Link to='/DeepAI'>Discover DeepAI</Link></button>
                        </div>
                        <div className="col-lg-5  me-0 me-md-5 order-1 order-lg-2">
                            <img className='img-fluid' src='/deepai.png' data-aos="fade-left" data-aos-duration="2000"/>
                        </div>
                    </div>
                </div>

            </div>

            <div className="specialcont container-fluid  pt-md-0 pb-5 ">
                    <div className="row mt-5 pt-5 justify-content-center">
                        
                        <div className="col-lg-4 pe-3">
                        <h1 className='font-semibold pt-5' style={{ color: 'white', fontSize: '25px' }} data-aos="fade-left" data-aos-duration="2000">Real-Time Monitoring and Feedback</h1>
                            <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-left" data-aos-duration="2000">Our AI-driven systems provide unparalleled real-time monitoring and feedback, empowering your team to make proactive, informed decisions. By continuously analyzing production data, these systems can instantly detect deviations from set parameter and identify potential defects.</p>
                            
                        </div>
                        <div className="col-lg-4  pe-3">
                        <h1 className='font-semibold pt-5' style={{ color: 'white', fontSize: '25px' }} data-aos="fade-right" data-aos-duration="2000">Optimize Operations and Reduce Costs</h1>
                            <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-right" data-aos-duration="2000">Transform your quality control with intelligent data analysis, driving excellence and efficiency at every stage of production. Our solutions help you maintain the highest standards of quality while optimizing your operations and reducing costs.</p>
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default AIsolutionsMainPage;
