import React from 'react';
import { Link } from 'react-router-dom';

function Robotics() {
    return (
        <div className='vid'>
            <div className="aimain container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Robotics and Automation</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">
                            Robotics and Automation involve the use of robotic systems and automated processes to enhance efficiency, precision, and flexibility in various industries. From assembly lines to material handling, robotics plays a crucial role in modern manufacturing.
                        </p>
                        <div className="container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/bot.png" alt="Collaborative Robots" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Collaborative Robots</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/robotic.png" alt="Articulated Robotic Arms" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Robotic Arms</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section for detailed information about Collaborative Robots */}
            <div className="robodetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3' src='https://i.imgur.com/lTTbPHq.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="Collaborative Robots" />
                        </div>
                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Collaborative Robots (Cobots)</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">
                                Collaborative robots are designed to work alongside humans in a shared workspace safely. They can perform tasks such as assembly, packaging, and quality inspection with minimal setup and programming.
                            </p>
                            <div className="row">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://images.pexels.com/photos/9242830/pexels-photo-9242830.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load' data-aos="fade-down" data-aos-duration="2500" alt="Collaborative Robots Image 1" />
                                    <p className='pt-1 text-sm'>Easy setup and programming.</p>
                                </div>
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://i.imgur.com/ZFHqts4.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="Collaborative Robots Image 2" />
                                    <p className='pt-1 text-sm'>Safety features for human-robot collaboration.</p>
                                </div>
                            </div>
                            <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000"><Link to='/case-studies/Cobot'>Read case study</Link></button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section for detailed information about Articulated Robotic Arms */}
            <div className="robodetails pt-md-0 pt-3 pb-1 pt-md-0 pb-5 mb-5">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3' src='https://images.pexels.com/photos/19233057/pexels-photo-19233057/free-photo-of-assembling-machines-in-factory.jpeg?auto=compress&cs=tinysrgb&w=600' data-aos="fade-down" data-aos-duration="2500" alt="Articulated Robotic Arms" />
                        </div>
                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Articulated Robotic Arms</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">
                                Articulated robotic arms use multiple joints to perform complex movements with high precision. They are widely used in assembly lines, welding, and material handling applications.
                            </p>
                            <div className="row">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://images.unsplash.com/photo-1647427060118-4911c9821b82?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' data-aos="fade-down" data-aos-duration="2500" alt="Articulated Robotic Arms Image 1" />
                                    <p className='pt-1 text-sm'>Flexible and precise movement capabilities.</p>
                                </div>
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://images.unsplash.com/photo-1567789884554-0b844b597180?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' data-aos="fade-down" data-aos-duration="2500" alt="Articulated Robotic Arms Image 2" />
                                    <p className='pt-1 text-sm'>Used in various industries for automation.</p>
                                </div>
                            </div>
                            <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000"><Link to='/Robotic-arms/articulated-robotic-arms'>Read More</Link></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Robotics;
