import React from 'react';
import { Link } from 'react-router-dom';

const Services = () => {
    return (
        <div className="bg-gray-200 py-12">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-between mb-12">
                    {/* Block 1 */}
                    <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
                        <div data-aos="fade-down" data-aos-duration="1500">
                            <p className="text-orange-500 text-lg font-semibold">The Best Industry And Company Services</p>
                            <h2 className="text-4xl font-bold">Provides High Performance Services For Multiple Industries And Technologies!</h2>
                        </div>

                    </div>
                    {/* Block 2 */}

                    <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
                        <div data-aos="fade-down" data-aos-duration="2500">
                            <p className="text-gray-700 mb-4">
                                Our dedicated team of experts is driven by innovation, constantly pushing the boundaries of technology to deliver cutting-edge solutions tailored to meet the evolving needs of our clients. With a customer-centric approach, we strive to forge long-lasting partnerships built on trust, reliability, and exceptional quality.
                            </p>
                            <button className="bg-orange-500 text-white rounded-lg py-2 px-6 hover:bg-orange-600">Schedule an Appointment</button>

                        </div>
                    </div>
                </div>

                {/* Block 3 */}
                <div className="flex flex-wrap justify-center items-center text-center">
                    <div className="container justify-content-center">
                        <div className="row">
                            <div className="col-lg-4">
                                {/* Card 1 */}
                                <div data-aos="zoom-in" data-aos-duration="1000">
                                    <div className="bg-white p-8 rounded-lg shadow-md mx-4 mb-8 flex flex-col items-center" style={{ maxWidth: '400px' }}>
                                        <h3 className="text-xl font-semibold mb-2">Advanced Manufacturing</h3>
                                        <div className="flex justify-center items-center mb-4" style={{ height: '114px' }}>
                                            <img src="/industrial.png" alt="Service 1" className="w-full" style={{ width: '113px', objectFit: 'cover' }} />
                                        </div>
                                        <p className="text-gray-700 mb-4">We are at the forefront of hi-tech manufacturing, driving innovation and excellence in every aspect of our operations.</p>
                                        <button className="bg-orange-500 text-white rounded-full py-2 px-6 hover:bg-orange-600"><Link to="/Hitech-Manufacturing">Read More</Link></button>
                                        <img src="/indus.png" alt="Service 3" className="w-full" style={{ height: '200px', objectFit: 'cover' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                {/* Card 2 */}
                                <div data-aos="zoom-in" data-aos-duration="1500">
                                    <div className="bg-white p-8 rounded-lg shadow-md mx-4 mb-8 flex flex-col items-center" style={{ maxWidth: '400px' }}>
                                        <h3 className="text-xl font-semibold mb-2">Drone & UAVs</h3>
                                        <div className="flex justify-center items-center mb-4" style={{ height: '114px' }}>
                                            <img src="/drone.png" alt="Service 2" className="w-full" style={{ width: '113px', objectFit: 'cover' }} />
                                        </div>
                                        <p className="text-gray-700 mb-4">Explore the fascinating world of UAVs through our website, dedicated to showcasing the latest advancements and applications in drone technology.</p>
                                        <button className="bg-orange-500 text-white rounded-full py-2 px-6 hover:bg-orange-600"><Link to="/Solar&Ev">Read More</Link></button>
                                        <img src="/dron.png" alt="Service 3" className="w-full" style={{ height: '200px', objectFit: 'cover' }} />

                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                {/* Card 3 */}
                                <div data-aos="zoom-in" data-aos-duration="2500">
                                    <div className="bg-white p-8 rounded-lg shadow-md mx-4 mb-8 flex flex-col items-center" style={{ maxWidth: '400px' }}>
                                        <h3 className="text-xl font-semibold mb-2">Solar Panels</h3>
                                        <div className="flex justify-center items-center mb-4" style={{ height: '114px' }}>
                                            <img src="/solar-panel.png" alt="Service 3" className="w-full" style={{ width: '113px', objectFit: 'cover' }} />
                                        </div>
                                        <p className="text-gray-700 mb-4">Discover the marvels of solar cell technologies on our website. Learn how these devices harness the power of sunlight to generate electricity. </p>
                                        <button className="bg-orange-500 text-white rounded-full py-2 px-6 hover:bg-orange-600"><Link to="/Solar">Read More</Link></button>
                                        <img src="/sola.png" alt="Service 3" className="w-full" style={{ height: '200px', objectFit: 'cover' }} />

                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                {/* Card 4 */}
                                <div data-aos="zoom-in" data-aos-duration="2500">
                                    <div className="bg-white p-8 rounded-lg shadow-md mx-4 mb-8 flex flex-col items-center" style={{ maxWidth: '400px' }}>
                                        <h3 className="text-xl font-semibold mb-2">Smart EV</h3>
                                        <div className="flex justify-center items-center mb-4" style={{ height: '114px' }}>
                                            <img src="/electric-car.png" alt="Service 4" className="w-full" style={{ width: '113px', objectFit: 'cover' }} />
                                        </div>
                                        <p className="text-gray-700 mb-4">Electric vehicle (EV) batteries represent the heart of the electric revolution, enabling sustainable and efficient mobility solutions.</p>
                                        <button className="bg-orange-500 text-white rounded-full py-1 px-6 hover:bg-orange-600 mb-2"><Link to="/EV-technology">Read More</Link></button>
                                        <img src="/smev.webp" alt="Service 3" className="w-full" style={{ height: '200px', objectFit: 'cover' }} />

                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                {/* Card 4 */}
                                <div data-aos="zoom-in" data-aos-duration="2000">
                                    <div className="bg-white p-8 rounded-lg shadow-md mx-4 mb-8 flex flex-col items-center" style={{ maxWidth: '400px' }}>
                                        <h3 className="text-xl font-semibold mb-2">Hi-tech Electronics</h3>
                                        <div className="flex justify-center items-center mb-4" style={{ height: '114px' }}>
                                            <img src="/pcb-board.png" alt="Service 4" className="w-full" style={{ width: '113px', objectFit: 'cover' }} />
                                        </div>
                                        <p className="text-gray-700 mb-4">Unlock new possibilities with industry-tailored electronics solutions meticulously crafted to enhance efficiency and productivity. </p>
                                        <button className="bg-orange-500 text-white rounded-full py-1 px-6 hover:bg-orange-600 mb-2"><Link to="/Specialized-Electronics">Read More</Link></button>
                                        <img src="/pcb.png" alt="Service 3" className="w-full" style={{ height: '200px', objectFit: 'cover' }} />

                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                {/* Card 4 */}
                                <div data-aos="zoom-in" data-aos-duration="1000">
                                    <div className="bg-white p-8 rounded-lg shadow-md mx-4 mb-8 flex flex-col items-center" style={{ maxWidth: '400px' }}>
                                        <h3 className="text-xl font-semibold mb-2">AI Solutions</h3>
                                        <div className="flex justify-center items-center mb-4" style={{ height: '114px' }}>
                                            <img src="/ai1.png" alt="Service 4" className="w-full" style={{ width: '113px', objectFit: 'cover' }} />
                                        </div>
                                        <p className="text-gray-700 mb-4">Our AI solutions aren't just transformative, they're the driving force behind diverse industries and market domains.</p>
                                        <button className="bg-orange-500 text-white rounded-full py-1 px-6 hover:bg-orange-600 mb-2"><Link to="/aisolutionsmainpage">Read More</Link></button>
                                        <img src="/aoi.jpg" alt="Service 3" className="w-full" style={{ height: '200px', objectFit: 'cover' }} />

                                    </div>
                                </div>

                            </div>
                        </div>






                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
