import React from 'react';
import { Link } from 'react-router-dom';

function VisionAI() {
    return (
        <div className='vid'>
            <div className="aimainsp container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Charging Solutions for Electric Vehicles</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">Electric vehicles (EVs) rely on diverse charging solutions to maintain power levels efficiently and conveniently. These solutions include home charging systems, such as wall-mounted chargers and smart charging solutions that integrate with home energy management. Public charging infrastructure features fast charging stations for rapid power replenishment in public areas and innovative wireless charging options for added convenience. </p>
                        <div className="container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="https://i.imgur.com/q10TaNS.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Public charging</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="https://i.imgur.com/zrMVzWv.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Home charging</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container  pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">

                            <img className='img-fluid  pt-3 ' src='https://ca-times.brightspotcdn.com/dims4/default/7e33291/2147483647/strip/false/crop/6653x3742+0+346/resize/1200x675!/quality/75/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2Fe1%2F2a%2F45751df96af3caf41f8dc0c98dcb%2F84730c55f56f44e39965f307c7ccaad2' alt="3D Vision AI Technology" />
                            {/* <div className="video-container" >
                    <iframe src='/dronevid.webm' className="responsive-iframe" allowfullscreen data-aos="fade-down" data-aos-duration="2000" autoplay></iframe>
                </div> */}
                        </div>

                        <div className="col-lg-6 mt-1 order-1 order-lg-2 pb-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Public Charging Infrastructure</h1>
                            <h1 className='font-semibold ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Ultra-fast DC Charger C7 : Future of EV Charge Stations</h1>
                            <p className='mt-3' data-aos="fade-left" data-aos-duration="2000">The C7 Ultra-fast DC Charger redefines the EV charging experience with its impressive output of up to 400 kW, all housed within a standalone, compact design. This cutting-edge charger significantly reduces charging times, making it an ideal solution for busy urban areas, highways, and commercial centers. Its sleek and space-efficient design ensures easy installation and integration into various environments.</p>
                            <div className="w-full md:w-4/4">
                                <div className="row">
                                    <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                        <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> 400kW</strong>
                                        <p className="text-sm">Maximum Output Power.</p>
                                    </div>
                                    <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                        <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> 200~1000V</strong>
                                        <p className="text-sm">Optimal Output Voltage.</p>
                                    </div>
                                    <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                        <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> 120~280kW</strong>
                                        <p className="text-sm">Epic Charging Power</p>
                                    </div>
                                </div>
                            </div>
                            
                            {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000"><Link to=''>Read More</Link></button>  */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails pt-md-0 pt-3 pb-1 pt-md-0 pb-5">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-6">
                            {/* <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Energy Monitoring</h1> */}
                            <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">More power, more possibilities</h1>
                            <p className='mt-3' data-aos="fade-left" data-aos-duration="2000">The C7 Ultra-fast DC Charger delivers high output power, offering up to 400 kW, in a sleek and compact design engineered for maximum performance. This advanced charger significantly reduces charging times, making it an ideal solution for busy urban areas, highways, and commercial centers. Its streamlined design allows for easy installation and integration into various environments, while its robust engineering ensures reliability and durability.</p>
                            <div className="row">
                                <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Reduced charge time</strong>
                                    <p className="text-sm">Ready to go in minutes.</p>
                                </div>
                                <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Robust engineering</strong>
                                    <p className="text-sm">Makes the charger more durable.</p>
                                </div>
                                <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Modern demand</strong>
                                    <p className="text-sm">Meets the evolving EV specifications.</p>
                                </div>
                            </div>
                            

                            {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000"><Link to='/3D-Vision-AI-Casestudy'>Read More</Link></button> */}
                        </div>
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <div className="video-container" >
                                <iframe src='https://oss-eu.xcharge.com/final2.mp4' className="responsive-iframe" allowfullscreen data-aos="fade-down" data-aos-duration="2000" autoplay></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-3 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container  pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">

                            <img className='img-fluid  pt-3 ' src='https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEge4fDGpXCydR-lKMfTjIQenQSV_w_JWrRA8TxFfgXTkWnkrva9o7U56dv-XC7G0EHcuRJN-py9HUykt839Rghyphenhyphen7sw1Ybkyuk5XdkyjeAl0F1G05LOA-F1hgko0f_HHIM3oUg5cDkg-eR7k/s1600-rw/eichrecht_flow-diagram-2.jpg' />

                        </div>

                        <div className="col-lg-6 mt-1 order-1 order-lg-2 pb-2">
                            <h1 className='font-semibold ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">PTB Eichrecht Certified</h1>
                            <p className='mt-3' data-aos="fade-left" data-aos-duration="2000">The charger is PTB Eichrecht certified, ensuring the highest standards of accuracy and reliability. This certification guarantees that the charger meets stringent regulatory requirements for precise energy measurement and billing, providing users with confidence in the fairness and transparency of their charging sessions. PTB certification underscores the charger's commitment to quality and performance, making it a trustworthy choice for both private and public EV charging applications.</p>
                            <div className="w-full md:w-4/4">
                                <div className="row">
                                    <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                        <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Barrier free design</strong>
                                        <p className="text-sm">Guarantees wheelchair accessibility for all users.</p>
                                    </div>
                                    <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                        <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> High power output</strong>
                                        <p className="text-sm">Can deliver up to 400 kW power at max of 600A.</p>
                                    </div>
                                    <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                        <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Fully Customizable</strong>
                                        <p className="text-sm">Completely customizable in branding.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000"><Link to=''>Read More</Link></button>  */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails pt-md-0 pt-3 pb-1 pt-md-0 pb-5">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-6">
                            {/* <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Energy Monitoring</h1> */}
                            <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Home Charging Systems</h1>
                            <h1 className='font-semibold ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Vesta Wall mounted charger</h1>
                            <p className='mt-3' data-aos="fade-left" data-aos-duration="2000">StarCharge residential inverter with 4 ports to manage the energy of Solar, Battery, AC Charger, and Grid. Both single-phase and three-phase are available with power rating from 2.5 kW to 14 kW.</p>
                            <div className="row">
                                <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Intelligent</strong>
                                    <p className="text-sm">Assists in shifting into a greener lifestyle & cuts your energy bills.</p>
                                </div>
                                <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Multi-functional</strong>
                                    <p className="text-sm"> Supports off-grid and various EMS on-grid operations as well as time schedules.</p>
                                </div>
                                <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> One-stop</strong>
                                    <p className="text-sm">Provides a total solution that includes green energy charging & consumption.</p>
                                </div>
                            </div>
                            <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000"><Link to='#'>Installation Guide</Link></button>

                            {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000"><Link to='/3D-Vision-AI-Casestudy'>Read More</Link></button> */}
                        </div>
                        <div className="col-lg-5 pt-3 pt-md-5 me-0 me-md-5 order-1 order-lg-1">
                             <div className="video-container" >
                    <iframe src='https://www.youtube.com/embed/PHuyBcunrzY' className="responsive-iframe" allowfullscreen data-aos="fade-down" data-aos-duration="2000" autoplay></iframe>
                </div>
                
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-3 pt-md-0 pt-20 pb-1 pt-md-0 pb-5">
                <div className="container  pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">

                            <img className='img-fluid  pt-3 ' src='https://www.starcharge.com/wp-content/uploads/2024/05/reasssss.jpg' alt="3D Vision AI Technology" />

                        </div>

                        <div className="col-lg-6 mt-1 order-1 order-lg-2 pb-2">
                            <h1 className='font-semibold ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Time-of-Use</h1>
                            <p className='mt-3' data-aos="fade-left" data-aos-duration="2000">Time of Use mode enables you to customize the charging and discharging times of the entire energy system based on the electricity pricing scheme of your residential utility provider. It ensures that the system charges during off-peak electricity rates and supplies power to the entire house during peak pricing periods.</p>
                            <div className="row">
                                <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Custom charging</strong>
                                    <p className="text-sm">Multiple charging options</p>
                                </div>
                                <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Save cost</strong>
                                    <p className="text-sm">A budget friendly technology.</p>
                                </div>
                                <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Green energy</strong>
                                    <p className="text-sm">Save the world, go green.</p>
                                </div>
                            </div>
                            
                            {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000"><Link to=''>Read More</Link></button>  */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails pt-md-0 pt-3 pb-1 pt-md-0 pb-5">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-6">
                            {/* <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Energy Monitoring</h1> */}
                            <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Self-Powered</h1>
                            <p className='mt-3' data-aos="fade-left" data-aos-duration="2000">When this mode is activated, the entire system will prioritize supplying power to the entire house. Electricity generated by the photovoltaic panels will be directly delivered to the household appliances via the inverter. Any surplus power will be stored in the battery and made available for use in situations where solar generation is insufficient.</p>
                            

                            {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000"><Link to='/3D-Vision-AI-Casestudy'>Read More</Link></button> */}
                        </div>
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <div className="video-container" >
                            <img className='img-fluid  pt-3 ' src='https://www.starcharge.com/wp-content/uploads/2024/05/close-up-view-solar-panels-solar-farm-scaled-1.jpg' alt="3D Vision AI Technology" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-3 pt-md-0 pt-20 pb-1 pt-md-0 pb-5">
                <div className="container  pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">

                            <img className='img-fluid  pt-3 ' src='https://www.starcharge.com/wp-content/uploads/2024/05/Backup-Only.jpg' alt="3D Vision AI Technology" />

                        </div>

                        <div className="col-lg-6 mt-1 order-1 order-lg-2 pb-2">
                            <h1 className='font-semibold ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Backup-Only</h1>
                            <p className='mt-3' data-aos="fade-left" data-aos-duration="2000">When this mode is activated, the electricity generated by the photovoltaic system will be prioritized for storage in the battery, ensuring that its power reserve remains at the preset level. Only surplus electricity will be directed to power various appliances. This setup guarantees that during a power outage, there will be ample power stored in the battery to provide essential electricity for the entire house.</p>
                            <div className="row">
                                <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Custom charging</strong>
                                    <p className="text-sm">Multiple charging options</p>
                                </div>
                                <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Save cost</strong>
                                    <p className="text-sm">A budget friendly technology.</p>
                                </div>
                                <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Green energy</strong>
                                    <p className="text-sm">Save the world, go green.</p>
                                </div>
                            </div>
                            
                            {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000"><Link to=''>Read More</Link></button>  */}
                        </div>
                    </div>
                </div>
            </div>






        </div>
    );
}

export default VisionAI;
