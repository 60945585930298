import React from 'react';

function autoarticle() {
    return (
        <div>
            <div className="aimainsp16 pb-5 container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5 pb-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Revolutionary EO/IR Intelligent Sensing</h1>
                        <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2000">
                            More than 5x the detection, recognition, identification, and targeting range than existing optical imaging.
                        </p>
                        <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2000">
                            RAIVEN is a next-generation EO/IR product- and platform-agnostic family architecture harnessing the power of advanced technologies like hyperspectral, lidar, and artificial intelligence to deliver unprecedented targeting, battlespace awareness, range, and visual superiority in contested and highly contested environments.
                        </p>
                        <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2000">
                            It enables the warfighter to see farther and faster than ever before, delivering up to five times the detection, recognition, and identification range over traditional optical imaging performance – providing targeting, bolstering survivability, and combat overmatch ensuring mission success.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container mt-3 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">
                    <div className="col-lg-8 mt-2">
                        <h1 className='font-semibold  mb-2 mt-5' style={{ color: 'black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">RAIVEN Product Family</h1>
                        <p className='pt-2 ' style={{ textAlign: 'justify' }} data-aos="fade-left" data-aos-duration="2000">
                            The RAIVEN product family is platform agnostic, scalable, and tailorable to any platform or installation – from airborne to ground and sea applications. The RAIVEN architecture and technology are applicable across a wide array of mission spaces, customers, and platforms. It enables new product variants, such as turrets, pods, and embedded systems. Its open systems architecture leverages common standards and common parts enabling seamless replacement, upgrades, or updates of components.
                        </p>
                        <img src="https://www.shephardmedia.com/media/cache/images/images/article/RAIVEN/598aa3fdf9caa2cf0e624322d2c6ca45.JPG" alt="About Us Image" className="w-full shadow-lg mt-5 mb-5" />
                        <p className='pt-2 ' style={{ textAlign: 'justify' }} data-aos="fade-left" data-aos-duration="2000">
                            Built upon the successes of the combat-proven Multi-spectral Targeting System product family of sensors, the RAIVEN product family offers more integrated capability than ever before – within the same SWaP.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">RT-1000: The First Instantiation</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">
                            As part of the RAIVEN product family, the first instantiation -- RT-1000 -- can support a wide array of missions, including the Army’s Future Vertical Lift modernization effort.
                        </p>
                        <div className="w-full md:w-4/4">
                            <ul>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Multi-domain operations</strong>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Near to peer/peer to peer fights</strong>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Maritime applications, including Humanitarian/search and rescue for civilian and defense</strong>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Camouflaged threats (terrestrial or maritime)</strong>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Deep look</strong>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> GPS denied missions</strong>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> High Value Airborne Asset Protection (HVAAP)</strong>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Ground-based air defense</strong>
                                </div>
                            </ul>
                        </div>
                        <div className="video-container" >
            <iframe src='https://www.youtube.com/embed/tswAN7ICsko' className="responsive-iframe pt-5" allowfullscreen data-aos="fade-down" data-aos-duration="2000" autoplay loop></iframe>
            </div>
                    </div>
                </div>
            </div>

            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Mission Capabilities</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">
                            RAIVEN’s mission capabilities are extensive and cover various domains:
                        </p>
                        <div className="w-full md:w-4/4">
                            <ul>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Multi-domain operations</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>RAIVEN excels in multi-domain operations, providing comprehensive support across land, sea, and air environments.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Near-peer and peer-to-peer engagements</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>RAIVEN enhances capabilities in engagements with near-peer and peer adversaries, ensuring superior situational awareness and targeting accuracy.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Maritime applications</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>RAIVEN supports maritime operations, including humanitarian missions, search and rescue, and defense applications.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Detection of camouflaged threats</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>RAIVEN’s advanced sensing capabilities enable the detection of camouflaged threats in both terrestrial and maritime environments.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Deep look capabilities</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>RAIVEN provides deep look capabilities, allowing for thorough surveillance and reconnaissance over extended ranges.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> GPS-denied missions</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>RAIVEN ensures operational effectiveness in GPS-denied environments, providing reliable navigation and targeting solutions.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> High-Value Airborne Asset Protection (HVAAP)</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>RAIVEN offers robust protection for high-value airborne assets, ensuring their safety and operational integrity.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Ground-based air defense</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>RAIVEN enhances ground-based air defense capabilities, providing superior detection and targeting of airborne threats.</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Conclusion</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">
                            The RAIVEN product family represents a revolutionary advancement in EO/IR intelligent sensing. By integrating cutting-edge technologies such as hyperspectral imaging, lidar, and artificial intelligence, RAIVEN delivers unprecedented detection, recognition, and targeting capabilities. Its platform-agnostic and scalable design ensures versatility across various applications, from airborne to maritime to ground-based missions. With the introduction of the RT-1000, RAIVEN is poised to support a wide range of critical missions, enhancing situational awareness, survivability, and mission success in contested environments.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-5">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Future Prospects</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">
                            Looking ahead, the continuous evolution of RAIVEN’s technology will further enhance its effectiveness and operational capabilities. Future advancements may include improved sensor integration, enhanced AI algorithms, and expanded mission profiles. As RAIVEN continues to innovate, it will play a pivotal role in ensuring technological superiority and mission success in increasingly complex and contested environments.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default autoarticle;
