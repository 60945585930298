import React from 'react'
import { Link } from 'react-router-dom'
function Airsterilizer() {
  return (
    <div className='vid'>
       <div className="aidetails mt-0 mt-md-0 pb-2 ">
                <div className="container mt-0 pt-0 pt-md-0" >
                    <div className="row mt-0 pt-5" data-aos="fade-left" data-aos-duration="2000">
                        <div className="col-lg-6 mt-0 pt-5">   
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Hi-Tech Electronic Components</h1>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Our selection includes diodes, transistors, and integrated circuits, each meticulously designed to power a diverse range of applications, from microprocessors and memory devices to switches and power management systems.</p>
                            <p className='pt-3'style={{textAlign:'justify'}}>These components are the backbone of high-demand environments, delivering reliability, efficiency, and scalability. Whether you're developing next-generation consumer electronics, automotive systems, or industrial automation solutions, our semiconductors provide the robust foundation needed to drive innovation and success in today's fast-paced technological landscape.</p>
                            <div className="container mt-8" >
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/cpu.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-lg text-center">Semi conductors</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/pcb-board.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-lg text-center">PCB Design</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/capacitor.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-lg text-center">Capacitors</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                            <img className='img-fluid' src='/chipset.png'/>
                        </div>
                    </div>
                    <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5">
                        <img className='img-fluid pt-2' src='/semi1.png' data-aos="fade-right" data-aos-duration="2000"/>
                        </div>
                        <div className="col-lg-6 mt-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Semiconductor Chips</h1>
                            <h1 className='font-semibold pt-3' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Processor Chip</h1>
                            <h2 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '18px' }} data-aos="fade-down" data-aos-duration="2000">Kirin 9000</h2>
                            <p className='pt-2' data-aos="fade-right" data-aos-duration="2000">Cutting-edge 5 nm manufacturing and up to 15.3 billion transistors make this 5G SA solution available in the smallest footprint possible. The brand-new Arm Cortex-A77 CPU ensures superior performance while the groundbreaking 24-core Mali-G78 GPU enables uninterrupted gaming. Two big and one tiny core support AI video applications with better energy efficiency. The NPU is integrated with an embedded Kirin ISP 6.0 for camera functions – HDR video synthesis with real-time exposure to excel in lowlight and backlit conditions.</p>
                            <p className='pt-3' data-aos="fade-right" data-aos-duration="2000">Features:</p>
                            <div className="w-full md:w-3/4">
                        <ul>
                        <div data-aos="fade-right" data-aos-duration="2000">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Process</strong>
                                <p className="text-sm ">5 nm</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2500">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> CPU</strong>
                                <p className="text-sm ">1x Cortex-A77@3.13 GHz | 3x Cortex-A77@2.54 GHz | 4x Cortex-A55@2.05 GHz</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  GPU</strong>
                                <p className="text-sm ">24-core Mali-G78, Kirin Gaming+ 3.0</p>
                            </li>
                            </div>
                        </ul>
                    </div>
                        </div>
                    </div>
                </div>

            </div>
           
            <div className="aidetails  pb-5 pt-md-0">
                <div className="container  pt-md-0">
                    <div className="row mt-5 ">     
                        <div className="col-lg-6 pt-5">
                        <h1 className='font-semibold pt-3' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Processor Chip</h1>
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '18px' }} data-aos="fade-down" data-aos-duration="2000">Kirin 990 5G</h1>
                            <p className='pt-3' style={{ color: '#1E1E1E', textAlign: 'justify' }} data-aos="fade-right" data-aos-duration="2000">Cutting-edge 7 nm+ EUV process builds the world's first full-frequency 5G SoC with peak downlink rates. Another first – its HUAWEI Da Vinci Architecture NPU with all new tiny-core that marries intelligent computing power with ultra-low power consumption. Its CPU and GPU deliver a fast and smooth user experience. The camera is enhanced even in low light thanks to block-matching and 3D filtering (BM3D) and dual-domain video noise reduction. This enhanced Kirin 990 gives you a 4G mobile experience with upgraded performance, energy efficiency, AI, and photography. </p>
                            <ul>
                        <div data-aos="fade-right" data-aos-duration="2000">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Process</strong>
                                <p className="text-sm mb-1">7 nm+ EUV</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2500">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> CPU</strong>
                                <p className="text-sm mb-1">2x Cortex-A76@2.86 GHz | 2x Cortex-A76@2.36 GHz | 4x Cortex-A55@1.95 GHz</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  GPU</strong>
                                <p className="text-sm mb-1">16-core Mali-G76</p>
                            </li>
                            </div>
                        </ul>
                        </div>
                        <div className="col-lg-5  me-0 me-md-5">
                            <img className='img-fluid ' src='/semi2.png' data-aos="fade-left" data-aos-duration="2000" />
                        </div>
                    </div>
                </div>

            </div>
            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
    <div className="container mt-5 pt-20 pt-md-0">
        <div className="row">
            <div className="col-lg-6 me-0">
                <div className="video-container">
                    <iframe src='https://www.youtube.com/embed/IeGE6FYXvoo' className="responsive-iframe" allowfullscreen data-aos="fade-down" data-aos-duration="2000"></iframe>
                </div>
            </div>
            <div className="col-lg-6 mt-2">
                <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Memory Chips</h1>
                <h1 className='font-semibold pt-3' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">AMD Ryzen™ PRO Processors</h1>
                <p className='pt-2' data-aos="fade-right" data-aos-duration="2000">With AMD Ryzen™ PRO processors and Windows 11 Pro for business, you get advanced security, performance, and IT management.</p>
                <p className='pt-2' data-aos="fade-right" data-aos-duration="2000">Features:</p>
                <div className="w-full md:w-3/4">
                    <ul>
                        <div data-aos="fade-right" data-aos-duration="2000">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Reliable Performance</strong>
                                <p className="text-sm mb-1">Up to 8 high-performance cores built on leadership 4 nm process “Zen 4” architecture, and Wi-Fi 7 support.</p>
                            </li>
                        </div>
                        <div data-aos="fade-right" data-aos-duration="2500">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Power Efficiency</strong>
                                <p className="text-sm mb-1">Advanced power efficient processors enable reduced energy usage and minimize carbon footprint.</p>
                            </li>
                        </div>
                        <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Long-Lasting Battery</strong>
                                <p className="text-sm mb-1">Adaptive power management for incredible battery life and cool and near-silent operation, with up to 24 hours of battery life.</p>
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div className="aidetails  pb-5 pt-md-0">
                <div className="container  pt-md-0">
                    <div className="row mt-5 pt-5">     
                        <div className="col-lg-6 pt-5">
                        <h1 className='font-semibold pt-3' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">AMD Ryzen™ PRO Processors for Mobile Workstations</h1>
                            <p className='pt-3' style={{ color: '#1E1E1E', textAlign: 'justify' }} data-aos="fade-right" data-aos-duration="2000">With a commitment to enterprise quality, AMD provides the Radeon PRO Software graphics driver for optimizing workflows. And with AMD’s Day Zero Certification Program, over 1700-day 0 ISV application certifications have been offered since program launch.2  </p>
                            <ul>
                        <div data-aos="fade-right" data-aos-duration="2000">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Professional Graphics</strong>
                                <p className="text-sm mb-1">AMD provides the Radeon PRO Software graphics driver for optimizing workflows.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2500">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Artificial Intelligence for Mobile Workstations</strong>
                                <p className="text-sm mb-1">AMD provides the world’s first dedicated artificial intelligence engine on a Windows x86 processor.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  Enterprise-Class Security</strong>
                                <p className="text-sm mb-1">Available in every Ryzen PRO processor, AMD PRO technologies delivers enterprise-grade multi-layered security, simplified deployment and manageability and quality assurance features that business and IT decision makers demand.</p>
                            </li>
                            </div>
                        </ul>
                        </div>
                        <div className="col-lg-5  me-0 me-md-5">
                            <img className='img-fluid pt-5 mt-5' src='/amd.png' data-aos="fade-left" data-aos-duration="2000" />
                        </div>
                    </div>
                </div>

            </div>
                   
                </div>
                
                
            </div>
            <div className="smartcity container-fluid pb-5">   
                <div className=" row mt-5 pt-5">
                    <div className="container">
                        <div className="row">

                        
                        <div className="col-lg-6 mt-5 pt-5" data-aos="fade-right" data-aos-duration="2500">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }}>Electronic Components & PCB Manufacturing</h1>
                            <p className='pt-3 mb-3'style={{color:'white', textAlign:'justify'}}>Discover how PCBs (Printed Circuit Boards) are manufactured in a comprehensive process that spans from intricate design to rigorous testing. It all starts with engineers creating a detailed schematic and layout using advanced software, meticulously planning the circuitry. This digital blueprint is then translated into a physical board through processes like layering, etching, and drilling, forming precise electrical pathways. </p>

                            <Link to='/case-studies/PCB-Manufacturing' className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5 mb-5">Discover PCB Manufacturing</Link>
                        </div>
                        <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                            <img className='img-fluid' src='/pcb.png' data-aos="fade-left" data-aos-duration="2500"/>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
    </div>
  )
}

export default Airsterilizer
