import React from 'react';

function AutoArticle() {
    return (
        <div className='vid'>
            <div className="aimainsp29 container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5 pb-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Exploring High Performance Alloys: Pushing Boundaries in Material Science</h1>
                        <p className='pt-4' style={{ color: 'white', textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">High Performance Alloys (HPAs), also known as Superalloys, represent a class of materials engineered to excel under extreme conditions. These alloys are prized for their exceptional properties such as resistance to high temperatures, mechanical strength, resistance to creep, and resilience against oxidation and corrosion.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-5 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h2 className='font-semibold' style={{ color: 'Black', fontSize: '30px' }} data-aos="fade-down" data-aos-duration="2000">Historical Development and Modern Applications</h2>
                        <p className='pt-4' style={{ textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">The evolution of HPAs traces back to the early 20th century, driven by the demands of emerging technologies like jet engines, which necessitated materials capable of withstanding elevated temperatures and stress levels. Today, HPAs continue to evolve to meet the rigorous demands of aerospace, power generation, petrochemical, and industrial sectors.</p>
                        <img className='img-fluid pt-5' src='https://www.alloywire.com/wp-content/uploads/2021/05/AlloyWire_FactoryFloor_0314_Mat-Pearce_Dry-Drawing-scaled.jpg' data-aos="fade-down" data-aos-duration="2500" alt="CNC Machining" />
                    </div>
                </div>
            </div>


            

            <div className="container mt-3 pt-md-0">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Key Groups and Composition of HPAs</h1> 
                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Nickel-Based Alloys</h2>                                              
                        <p className='' style={{ textAlign:'justify' }} data-aos="fade-left" data-aos-duration="2000">Nickel-based HPAs, such as Inconels and Hastelloys, are renowned for their robustness in high-temperature environments. These alloys incorporate elements like titanium, aluminum, and molybdenum to enhance specific properties such as creep resistance and oxidation resistance. They are crucial in applications requiring durable components that maintain mechanical integrity under extreme thermal stress.</p>
                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Cobalt-Based Alloys</h2>                                              
                        <p className='' style={{ textAlign:'justify' }} data-aos="fade-left" data-aos-duration="2000">Cobalt-based HPAs, like Nimonics, excel in applications demanding high-temperature stability and corrosion resistance. These alloys are vital in sectors where reliability and longevity are paramount, such as in gas turbines and chemical processing equipment.</p>
                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Iron-Based Alloys</h2>                                              
                        <p className='' style={{ textAlign:'justify' }} data-aos="fade-left" data-aos-duration="2000">Iron-based HPAs, including various Incoloys, offer a balance of strength, ductility, and resistance to oxidation. They find application in diverse industries ranging from automotive to marine, where components must endure harsh environmental conditions without compromising performance.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-3 pt-md-0">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Applications Across Industries</h1> 
                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Aerospace and Automotive</h2>                                              
                        <p className='' style={{ textAlign:'justify' }} data-aos="fade-left" data-aos-duration="2000">In aerospace and automotive industries, HPAs like Inconel X-750 and Nimonic 90 are preferred for their ability to withstand high temperatures and retain strength, making them ideal for critical components such as engine parts, springs, and sealing rings.</p>
                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Cryogenic Environments</h2>                                              
                        <p className='' style={{ textAlign:'justify' }} data-aos="fade-left" data-aos-duration="2000">Emerging technologies in cryogenic applications demand HPAs like Phynox and MP35N, which maintain mechanical properties at extremely low temperatures. These alloys are pivotal in space exploration and scientific research where materials must endure sub-zero conditions without brittleness.</p>
                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Marine and Chemical Processing</h2>                                              
                        <p className='' style={{ textAlign:'justify' }} data-aos="fade-left" data-aos-duration="2000">In corrosive environments found in marine and chemical processing sectors, alloys such as Monel 400, Alloy 625, and Alloy C-276 offer superior resistance to corrosion and chemical attack. They are utilized in valves, pumps, and heat exchangers to ensure long-term reliability and safety.</p>
                    </div>
                </div>
            </div>

            

            <div className="container mt-3 pt-md-0">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h2 className='font-semibold' style={{ color: 'Black', fontSize: '30px' }} data-aos="fade-down" data-aos-duration="2000">Future Outlook and Innovations</h2>
                        <p className='' style={{ textAlign:'justify' }} data-aos="fade-right" data-aos-duration="2000">The future of HPAs continues to evolve as industries push for materials capable of withstanding higher temperatures, greater stress, and harsher environments. Ongoing research and development aim to enhance alloy performance, expand application possibilities, and meet the evolving needs of modern technology.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-3 pt-md-0 pb-5">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h2 className='font-semibold' style={{ color: 'Black', fontSize: '30px' }} data-aos="fade-down" data-aos-duration="2000">Conclusion</h2>
                        <p className='' style={{ textAlign:'justify' }} data-aos="fade-right" data-aos-duration="2000">High Performance Alloys represent a cornerstone of modern material science, enabling advancements across critical industries through their unparalleled durability and performance under extreme conditions. As technological boundaries expand, HPAs remain at the forefront of innovation, driving progress in aerospace, energy, and beyond.</p>
                    </div>
                </div>
            </div>

            

            

        </div>
    );
}

export default AutoArticle;
