import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [isShadow, setIsShadow] = useState(false);

    useEffect(() => {
        setIsShadow(location.pathname !== '/');
    }, [location.pathname]);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className={`navv top-0 left-0 w-full flex justify-between items-center z-50 pt-5 relative ${isShadow ? 'shadow' : ''}`}>
            <div className="flex items-center text-white">
                <div data-aos="fade-right" data-aos-duration="1500">
                    <span className="font-semibold text-md bg-orange-500 p-2 ">Advanced Industrial Materials Co Ltd.</span>
                </div>
            </div>
            <div className="flex items-center justify-end relative z-10">
                <button 
                    className="md:hidden"
                    onClick={toggleNavbar}
                >
                    {!isOpen ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    )}
                </button>
            </div>
            <div data-aos="" data-aos-duration="1500" className={`md:flex md:items-center md:space-x-4 absolute  right-0 w-auto pr-4 z-50 ${isOpen ? 'block bg-orange  top-24 p-4' : 'hidden'}`}>
                <ul className="flex flex-col md:flex-row justify-end space-y-4 md:space-y-0 text-sm font-medium md:flex md:space-x-4 pb-5" >
                    <li className={`navlink ${location.pathname !== '/' ? 'text-black' : ''}`}>
                        <Link to='/'>Home</Link>
                    </li>
                    <li className={`navlink ${location.pathname !== '/' ? 'text-black' : ''}`}>
                        <Link to='/aisolutionsmainpage'>AI Solutions</Link>
                        <div className="sub-categories">
                            <ul>
                                <li><Link to='/baiduai'>AI in Manufacturing</Link></li>
                                <li><Link to='/smartcitytech'>AI in Agriculture</Link></li>
                                <li><Link to='/3D-Vision'>AI in Defence</Link></li>
                            </ul>
                        </div>
                    </li>
                    {/* <li className={`navlink ${location.pathname !== '/' ? 'text-black' : ''}`}>
                        <Link to='/Dmssolutions'>Electronics</Link>
                        <div className="sub-categories">
                            <ul>
                                <li><Link to='/CustomizedFurniture'>Custom Electronics</Link></li>
                                <li><Link to='/Aluminium&Steel'>Hi-Tech Electronics </Link></li>
                            </ul>
                        </div>
                    </li> */}
                    <li className={`navlink ${location.pathname !== '/' ? 'text-black' : ''}`}>
                    <Link to='/Specialized-Electronics'> Electronics</Link>
                        <div className="sub-categories">
                            <ul>
                                <li><Link to='/BMS-Solutions'>Custom Electronics</Link></li>
                                <li><Link to='/AirSterilizers'>Hi-Tech Electronics</Link></li>
                                {/* <li><Link to='/AirSterilizers'>Advanced Manufacturing</Link></li>
                                <li><Link to='/AirSterilizers'>R&D Processes</Link></li> */}
                            </ul>
                        </div>
                    </li>
                    <li className={`navlink ${location.pathname !== '/' ? 'text-black' : ''}`}>
                    <Link to='/Solar&Ev'>UAV Technology</Link>
                        <div className="sub-categories">
                            <ul>
                                <li><Link to="/Automotives">Commerical Drones</Link></li>
                                <li><Link to="/Consumer-Drones">Consumer Drones</Link></li>
                                <li><Link to="/Drones-UAVs">Industrial Drones</Link></li>
                                <li><Link to="/Drone-Regulations">Regulations & Saftey</Link></li>
                                <li><Link to="/Drone-Technology">Tech & Innovation</Link></li>
                                
                            </ul>
                        </div>
                    </li>
                    <li className={`navlink ${location.pathname !== '/' ? 'text-black' : ''}`}>
                    <Link to='/Hitech-Manufacturing'>Advanced Manufacturing</Link>
                    <div className="sub-categories">
                            <ul>
                                <li><Link to="/Robotics&Automation">Robotics</Link></li>
                                <li><Link to="/3D-Manufacturing">3D Printing</Link></li>
                                <li><Link to="/Precision-Engineering">Engineering</Link></li>
                                <li><Link to="/Advanced-Materials">Advanced Materials</Link></li>
                                <li><Link to="/Digital-Manufacturing-Technologies">Digital Technology</Link></li>
                                <li><Link to="/Sustainable-Manufacturing">Sustainability</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li className={`navlink ${location.pathname !== '/' ? 'text-black' : ''}`}>
                    <Link to='/EV-technology'>EV Technology</Link>
                        <div className="sub-categories">
                            <ul>
                                <li><a href="/Electric-Vehicals">Electric Vehicals</a></li>
                                <li><Link to="/Electric-Battery">Battery Solutions</Link></li>
                                <li><Link to="/Charging-Solution">Charging Solutions</Link></li>
                                <li><Link to="/Latest-EVTech">Latest Innovations</Link></li>
                                <li><Link to="/Market-Trends">Market Trends</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li className={`navlink ${location.pathname !== '/' ? 'text-black' : ''}`}>
                    <Link to='/Solar'>Solar Technology</Link>
                        {/* <div className="sub-categories">
                            <ul>
                                <li><a href="#">Cloud Manufacturing Systems</a></li>
                                <li><a href="#">Tungsten Metal Powder</a></li>
                                <li><a href="#">Specialized Metallurgy</a></li>
                            </ul>
                        </div> */}
                    </li>
                    <li className={`navlink ${location.pathname !== '/' ? 'text-black' : ''}`}>
                    <Link to='/partners'>Our Partners</Link>
                    </li>
                    {/* <li className={`navlink ${location.pathname !== '/' ? 'text-black' : ''}`}>
                        <a href="#" className="">Blog</a>
                    </li> */}
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
