import React, { useState } from 'react';
import axios from 'axios';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    inquiry: 'Select Inquiry',
    message: ''
  });

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true
    try {
      await axios.post('http://localhost:5000/send-email', formData);
      setAlertMessage('Email sent successfully'); // Set success alert message
      setFormData({
        name: '',
        email: '',
        inquiry: 'Select Inquiry',
        message: ''
      });
    } catch (error) {
      console.error('Error sending email:', error);
      setAlertMessage('Failed to send email'); // Set error alert message
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  return (
    <div className='contactform'>
      <form onSubmit={handleSubmit}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="contactcontainer col-lg-6 p-20">
              <h1 className='intouchtxt text-center pb-3'>Get in touch.</h1>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="name@example.com"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="inquiry" className="form-label">Inquiry</label>
                <select
                  className="form-select"
                  id="inquiry"
                  name="inquiry"
                  aria-label="Default select example"
                  value={formData.inquiry}
                  onChange={handleChange}
                >
                  <option disabled>Select Inquiry</option>
                  <option value="Our Services">Our Services</option>
                  <option value="Products">Products</option>
                  <option value="Consultance">Consultance</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">Your Message</label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  placeholder="Your Message..."
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button className="submitbtn btn btn-primary mt-3" type="submit">
                {loading ? 'Sending...' : 'Submit'}
              </button>
              {alertMessage && (
                <div className={`alert ${alertMessage.includes('success') ? 'alert-success' : 'alert-danger'} mt-3`} role="alert">
                  {alertMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
