import React from 'react';
import { Link } from 'react-router-dom';

function VisionAI() {
    return (
        <div className='vid'>
            <div className="aimain container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Embarking a new Era with 3D Vision Sensors</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">Embrace the future of automation with cutting-edge 3D vision AI technology. Our innovative solutions are transforming industries, enhancing efficiency, and unlocking new possibilities. Explore the power of 3D vision AI to drive unprecedented advancements in manufacturing, logistics, and beyond.</p>
                        <div className="container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/deadline.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Speed</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/simplicity.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Simplicity</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/reliable.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Reliability </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container  pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">

                            <img className='img-fluid pt-3' src='https://i.imgur.com/0UXEaVG.png' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />

                        </div>

                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">PCB Solder Appearance Inspection</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">The information of the parts mounted on the PCB and of the solder fillet height, area, volume, and tilt is obtained as a batch. The built-in camera allows for 2D inspections at the same time that various 3D inspections are performed.</p>
                            <div className="row">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://i.imgur.com/qxljitE.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                                    <p className='pt-1 text-sm'>Able to evaluate by actual solder height and volume.</p>
                                </div>
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://i.imgur.com/f8RbHyi.png' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                                    <p className='pt-1 text-sm'>Can inspect the mounting height and part tilting in addition to 2D.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails pt-md-0 pt-3 pb-1 pt-md-0">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-6">
                            <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Transmission Assembly and Sealant Inspection</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">The 3D detection tools enable presence inspections of assembled parts while simultaneously enabling stable inspections of the presence, incompleteness, etc. of the applied sealant (liquid gasket) unaffected by the background color because 3D information is used.</p>
                            <div className="row">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://i.imgur.com/h1Td3pI.png' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                                    <p className='pt-1 text-sm'>3D inspection of sealant (the liquid gasket).</p>
                                </div>
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://i.imgur.com/WFjd6Wc.png' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                                    <p className='pt-1 text-sm'>Part presence inspection (3D capture mode)</p>
                                </div>
                            </div>
                            {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000"><Link to='/3D-Vision-AI-Casestudy'>Read More</Link></button> */}
                        </div>
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3' src='https://i.imgur.com/hRmE2Kn.png' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container  pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">

                            <img className='img-fluid pt-3' src='https://i.imgur.com/4oOocWs.png' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />

                        </div>

                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Busbar Flatness Measurement</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">The 3D camera that uses the pattern projection method allows the height data throughout the wide field of view (with a maximum size of 60.2 mm (2.370") squared) to be obtained, enabling inspections with the actual dimensions in 3D. The 3D shape of the entire target can be measured with high accuracy during the momentary stoppages as short as 0.6 seconds of an intermittent line, shortening takt time and allowing for accurate 100% shape inspections.</p>
                            <div className="row">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://i.imgur.com/yd6NpQB.png' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                                    <p className='pt-1 text-sm'>3D image (color image)</p>
                                </div>
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://i.imgur.com/KrVXc6j.png' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                                    <p className='pt-1 text-sm'>3D image (height image)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails pt-md-0 pb-5 pt-3 pb-1 pt-md-0">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-6">
                            <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Inspection of Connector Housing and Pins </h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">The 3D camera that uses the pattern projection method provides accurate, stable, and automatic 100% inspections by allowing for easy alignment. Multiple items can be inspected simultaneously as a batch including the coplanarity (flatness), pitch, and (horizontal/vertical) position of multiple pins and the 3D shape of the plastic housing. 3D measurements of the entire target within the wide field of view (with a maximum size of 60.2 mm (2.370") squared) can be performed with high accuracy during the momentary stoppages as short as 0.6 seconds of an intermittent line, shortening takt time. </p>
                            
                        </div>
                        <div className="col-lg-5 me-0 me-md-5">
                            <img className='img-fluid pt-5' src='https://i.imgur.com/BqD8vZc.png' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VisionAI;
