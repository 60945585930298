import React from 'react';
import { Link } from 'react-router-dom';

function AdditiveManufacturing() {
    return (
        <div className='vid'>
            <div className="aimain container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Additive Manufacturing (3D Printing)</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">
                            Additive Manufacturing (AM), commonly known as 3D printing, is a transformative manufacturing technology that builds three-dimensional objects layer by layer from digital models. Unlike traditional subtractive manufacturing methods that remove material from a solid block (e.g., milling) or shape it through molding or casting, AM processes add material precisely where needed, offering unprecedented design freedom and manufacturing flexibility.
                        </p>
                        <div className="container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/laserm.png" alt="DMLS" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">DMLS</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/3d-pri.png" alt="EBM" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">EBM</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section for detailed information about DMLS */}
            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3' src='https://i.imgur.com/FbFKy0A.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="DMLS Technology" />
                        </div>
                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Direct Metal Laser Sintering (DMLS)</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">
                                This technique uses a laser to selectively fuse powdered metals together, layer by layer, to create parts with complex geometries directly from CAD data.
                            </p>
                            <div className="row mb-3">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://i.imgur.com/w7cqXZJ.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="DMLS Image 1" />
                                    <p className='pt-1 text-sm'>Advanced Laser Technology.</p>
                                </div>
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://i.imgur.com/HlZOsaF.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="DMLS Image 2" />
                                    <p className='pt-1 text-sm'>Create Complex Geometry</p>
                                </div>
                            </div>
                            <Link to='/3D-printing/DMLS' className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section for detailed information about EBM */}
            <div className="aidetails pt-md-0 pt-3 pb-1 pt-md-0 pb-5">
                <div className="container pt-20 pt-md-0 pb-5">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3' src='https://i.imgur.com/87oWu5Y.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="EBM Technology" />
                        </div>
                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Electron Beam Melting (EBM)</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">
                                EBM uses an electron beam to melt and fuse metal powders in a high vacuum environment, allowing for the production of dense, complex metal parts.
                            </p>
                            <div className="row mb-3">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://i.imgur.com/2A46p6n.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="EBM Image 1" />
                                    <p className='pt-1 text-sm'>Perfect for shaping brittle metal</p>
                                </div>
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://i.imgur.com/Lf0VGkL.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="EBM Image 2" />
                                    <p className='pt-1 text-sm'>Custom metal crafting</p>
                                </div>
                            </div>
                            <Link to='/3D-printing/EBM' className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdditiveManufacturing;
