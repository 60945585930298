import React from 'react'

function autoarticle() {
    return (
        <div>
            <div className="aimainsp12 container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5 pb-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Common software platform offers flexible development options</h1>
                        <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2000">In-Sight Vision Suite software is common across all In-Sight products and includes two programming environments — spreadsheet and EasyBuilder®. Start with EasyBuilder, a wizard-like training interface designed for simplicity, and transition at your own pace to the more advanced, flexible spreadsheet programming. In-Sight Vision Suite uniquely integrates these two approaches, providing a seamless experience within the same platform that allows users to efficiently scale their automation.</p>

                    </div>
                </div>

            </div>

            {/* <div className=" container mt-8">
                <div className="row justify-content-center">
                    <h1 className='font-semibold text-center mb-5 mt-5' style={{ color: 'black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Survey Statistics</h1>
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0 text-center">

                            <p className='pt-3 pb-5 text-xl' style={{ color: '#f77d27', textAlign: 'justify', fontSize: '69px' }}>49%</p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}><b>Respondents from the supply chain sector expressed that disruptions led to planning difficulties.</b></p>

                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0 text-center">

                            <p className='pt-3 pb-5 text-xl' style={{ color: '#f77d27', textAlign: 'justify', fontSize: '69px' }}>44%</p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}><b>Indicated challenges due to supply chain footprint, which required them to make changes per year.</b></p>

                        </div>
                    </div>
                </div>
            </div> */}




            <div className="container mt-3 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">

                    <div className="col-lg-6 mt-2">
                    <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">EasyBuilder development simplifies setup</h1>
                    <img className='img-fluid pt-4' src='https://www.cognex.com/library/media/products/in-sight-l38/easybuildergui_486x900px.jpg' data-aos="fade-down" data-aos-duration="2500" />
                        <p className='pt-4 ' style={{ textAlign: 'justify' }} data-aos="fade-left" data-aos-duration="2000">With point-and-click training, the EasyBuilder interface within In-Sight Vision Suite is ideal for setting up simple or common jobs. The intuitive process guides developers step-by-step through setup, from image capture to the final result and beyond, allowing both new and experienced users to configure reliable vision applications. </p>
                    </div>
                    <div className="col-lg-6 mt-2">
                    <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Spreadsheet supports advanced applications</h1>
                    <img className='img-fluid pt-4' src='https://www.cognex.com/library/media/products/in-sight-l38/spreadsheetgui_500x700px.jpg' data-aos="fade-down" data-aos-duration="2500" />
                        <p className='pt-4 ' style={{ textAlign: 'justify' }} data-aos="fade-left" data-aos-duration="2000">The spreadsheet interface is ideal for building complex and highly customized applications. Robust in design, this development environment gives users the ability to make critical adjustments to job parameters and modify applications to address new requirements.</p>
                    </div>

                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">

                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Extensive toolset meets a wide range of needs</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">The In-Sight L38 is embedded with a full suite of 2D and 3D vision tools and AI technology. This library of tools includes industry-proven, rule-based algorithms, as well as innovative, easy-to-use edge learning tools, which can be used individually or combined for ultimate flexibility in solving error-proofing applications. With example-based training and no experience needed, these tools simplify the development of both 3D and 2D applications for fast implementation.</p>
                        <img className='img-fluid pt-4' src='https://www.cognex.com/library/media/products/in-sight-l38/full-suite-of-2d-and-3d-tools_900x500px.jpg?h=500&w=900&la=en&hash=39F0E4B0DFE240C6D30033CA3F30D026' data-aos="fade-down" data-aos-duration="2500" />

                    </div>

                </div>
            </div>

            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">

                    <div className="col-lg-8  pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Easy-to-use edge learning brings the power of AI to anyone </h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">Edge learning is a subset of AI that uses a pre-trained set of algorithms to automate vision tasks in minutes. Training is simple, requiring as few as 5 to 10 images with no advanced programming or AI expertise needed. Powered by edge learning, ViDi EL tools deliver fast processing speeds to increase throughput, keep pace with operations, and deliver fast, reliable results in real time. </p>
                    </div>

                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">ViDi EL Segment (3D)</h1>
                        <img className='img-fluid pt-4' src='https://www.cognex.com/library/media/products/in-sight-l38/vidi-el-segment_900x500px.jpg?h=500&w=900&la=en&hash=AEF73ACA389E382B7C4D868C1F6E5F76' data-aos="fade-down" data-aos-duration="2500" />
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">Advanced applications, such as those involving the detection and measurement of difficult-to-see defects, often require the use of multiple vision tools. In-Sight L38 easily solves these applications, with just one tool – 3D ViDi EL Segment. This tool detects challenging defects, gauges variances in three dimensions, and delivers results in real-world units to streamline workflows and deliver high reliability. </p>

                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">ViDi EL Segment (2D)</h1>
                        <img className='img-fluid pt-4' src='https://www.cognex.com/library/media/products/in-sight-l38/is3800-pump-sealant-inspection_croped_900x500px.jpg?sc_lang=en&h=500&w=900&la=en&hash=3F5F87DB52D37597E1D74A1025316057' data-aos="fade-down" data-aos-duration="2500" />
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">The ViDi EL Segment tool segments images to extract particular regions for targeted inspection. The region can include any type of feature on a part that users want to identify, from unwanted defects to critical components. Users train the tool by marking the region(s) of interest on an image and assigning it a class. After training just a few sample images, the tool learns to reliably segment the right features, even with varied parts and complex backgrounds. </p>

                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">ViDi EL Classify (3D/2D)</h1>
                        <img className='img-fluid pt-4' src='https://www.cognex.com/library/media/products/in-sight-l38/vidi-el-classify_900x500px.jpg?h=500&w=900&la=en&hash=57AE3D01C9CB31594C665A547DC64360' data-aos="fade-down" data-aos-duration="2500" />
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">The ViDi EL Classify tool identifies and sorts objects into classes based on user-defined features, characteristics, or defect types. Capable of discerning visually similar, but different objects, this tool classifies defects into different categories and correctly identifies parts with variation. Along with the ability to assign multiple classifications, users can set up multiple regions of interest to inspect different sections of the target object. </p>

                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pb-5 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">ViDi EL Read (2D)</h1>
                        <img className='img-fluid pt-4' src='https://www.cognex.com/library/media/products/in-sight-l38/vidi-el-read_900x500px.jpg?h=500&w=900&la=en&hash=F468926788EDA1E8B6ECCB482F6CAF47' data-aos="fade-down" data-aos-duration="2500" />
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">The ViDi EL Read tool deciphers a variety of text and font types using AI-based optical character recognition (OCR). It reads multi-line text, as well as characters against challenging backgrounds, including reflective, low-contrast, and non-flat surfaces. ViDi EL Read can also be quickly redeployed to handle new text and accommodate process variations. The tool offers two training modes to address character reading applications of varying complexity:</p>
                        <div className="w-full md:w-4/4">
                            <ul >
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium' ><span className='text-orange-500  text-6xl'>.</span> Pre-trained mode: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Reads characters right out of the box for simple applications </p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> User-trained mode:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Allows users to train characters to solve more complex applications. </p>
                                </div>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

        </div>


    )
}

export default autoarticle
