import React from 'react';
import { Link } from 'react-router-dom';

function VisionAI() {
    return (
        <div className='vid'>
            <div className="aimain container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Elevate Your Operations: Advanced Solutions with Industrial Drones</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">Industrial drones are robust and versatile unmanned aerial vehicles (UAVs) engineered to meet the rigorous demands of various industries. These high-performance drones are designed to enhance operational efficiency, safety, and accuracy in a wide range of applications. </p>
                        <div className="container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/barrel.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Energy Monitoring</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/find.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Mining Inspection</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container  pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">

                            <img className='img-fluid pt-3 ' src='https://i.imgur.com/b0JPWZW.jpeg'  alt="3D Vision AI Technology" />

                        </div>

                        <div className="col-lg-6 mt-1 order-1 order-lg-2 pb-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Mining Inspection</h1>
                            <h1 className='font-semibold ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Drones for mining: how to use and choose what’s best</h1>
                            <p className='mt-3' data-aos="fade-left" data-aos-duration="2000">The top companies in the world have already started to integrate drones for mining. In fact drones have proven to be vital tools across every part and phase of a mine site—from exploration, to drilling and blasting, to reclamation.</p>
                            <div className="w-full md:w-4/4 pb-3">
                            <div className="row">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Surveying & mapping</strong>
                                <p className="text-sm">How companies use UAVs for mapping mines.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Stockpile monitoring</strong>
                                <p className="text-sm">Discover how Stockpile inspection works with UAVs.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Safety inspections</strong>
                                <p className="text-sm">UAV inspection provides saftey for manual workers.</p>
                            </div>
                            </div>
                    </div>
                    <Link to='/Industrial-drones/applications' className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000">Read More</Link> 
                    <Link to='/Industrial-drones/how-to-survey' className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000">How to conduct a drone mining survey in 5 steps</Link> 
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails pt-md-0 pt-3 pb-1 pt-md-0 pb-5 mb-5">
                <div className="container pt-20 pt-md-0 ">
                    <div className="row ">
                        <div className="col-lg-6">
                        <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Energy Monitoring</h1>
                            <h1 className='font-semibold ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Aerial Vantage provides scalable solutions for data in Energy Management.</h1>
                            <p className='mt-3' data-aos="fade-left" data-aos-duration="2000">Our team understands the challenges the energy industry faces in its operations, from safety to high costs. Aerial Vantage supports drone use by accelerating data collection and processing to offer valuable insights on important energy use cases in real-time.</p>
                            <div className="row pb-3">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Oil & Gas Inspections</strong>
                                <p className="text-sm">Discover how UAVs help in oil & gas inspection.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Powerline Inspections</strong>
                                <p className="text-sm">UAVs are used to diagnose faults in powerlines.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Turbine Inspections</strong>
                                <p className="text-sm">Explore how wind-turbines are inspected using drones.</p>
                            </div>
                            </div>
                            <Link to='/Industrial-drones/Energy-Managment' className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000">Read More</Link>
                            {/* <button className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5" data-aos="fade-down" data-aos-duration="2000"><Link to='/3D-Vision-AI-Casestudy'>Read More</Link></button> */}
                        </div>
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1 ">
                            <img className='img-fluid pt-5 ' src='https://i.imgur.com/IttWQV6.jpeg' data-aos="fade-down" data-aos-duration="2500" alt="3D Vision AI Technology" />
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    );
}

export default VisionAI;
