import React from 'react'
import { Link, useLocation } from 'react-router-dom';
function SmartCityTech() {
  return (
    <div className='vid'>
        <div className="aimainsp13 container-fluid pb-5">   
                <div className=" row  pt-5 pb-5">
                    <div className="container">
                        <div className="row justify-content-center">
                        <div className="col-lg-6 mt-5 pt-5" data-aos="fade-right" data-aos-duration="2500">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }}>Smart Agriculture with Artificial Intelligence</h1>
                            <p className='pt-3'style={{color:'white', textAlign:'justify'}}>Leverage our precision agriculture AI solutions to optimize planting, crop management, and harvesting with data-driven insights, enhancing yield while minimizing environmental impact.</p>
                            <p className='pt-3' style={{color:'white',textAlign:'justify'}}>Utilize AI to monitor crop health through drones and satellite images, enabling early detection of disease, pest infestations, and nutrient deficiencies across vast areas.</p>
                            {/* <button className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5 ">Discover Smart City Solutions</button> */}
                        </div>
                    </div>
                    </div>
                    </div>
                </div>


            <div className="aidetails mt-0 mt-md-5  pb-5 ">
                <div className="container mt-0 mt-md-5  " >
                    <div className="row mt-0 mt-md-5 pt-0 pt-md-5" data-aos="fade-left" data-aos-duration="2000">
                        <div className="col-lg-6 mt-5 pt-5 text-center text-lg-start order-2 order-lg-1">   
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>GPS, Guidance, and Precision Agriculture</h1>
                            <p className='pt-3'style={{textAlign:'justify'}}>It’s our mission to make farmers’ lives easier with precision ag. Discover our innovative, easy‑to‑use technology can take your operation to the next level - no matter where you are or what you farm. </p>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>From software to hardware to other precision services, we offer a wide variety of technology to fit your farm. Our solutions are compatible with your existing equipment, as well as with each other, allowing you to start with one or two products and grow your capabilities over time or all at once.</p>
                            <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3" ><Link to='/Automotives'>Discover Agriculture Drones</Link></button>
                        </div>
                        <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5 order-1 order-lg-2">
                            <img className='img-fluid' src='/farmain.png'/>
                        </div>
                    </div>
                    <div className="row mt-5 pt-5" data-aos="fade-right" data-aos-duration="2000">
                        <div className="col-lg-5 mt-0 mt-md-5 pt-0 pt-md-5 me-0 me-md-5">
                            <img className='img-fluid' src='/farmer.png'/>
                        </div>
                        <div className="col-lg-6 mt-5 pt-5 text-center text-lg-start"> 
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>High-tech Farming Solutions</h1>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Our products and solutions help integrate your entire farm year-round. Use our products to seamlessly connect your equipment and tasks, ensuring your operation runs more efficiently than ever before. Experience the future of farming with our High-tech Farming Solutions, where innovation meets agriculture to drive growth and sustainability.</p>
                             <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3" ><Link to='/Agriculture-AI/applications'>Discover</Link></button>
                        </div>
                    </div>
                    <div className="row mt-5 pt-5" data-aos="fade-left" data-aos-duration="2000">
                        <div className="col-lg-6 mt-5 pt-5 order-2 order-lg-1">   
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Smart Farming Technologies</h1>
                            <p className='pt-3'style={{textAlign:'justify'}}>Integrate your entire farm operations with our comprehensive suite of smart farming technologies. Our products connect your equipment and tasks, streamlining processes and enhancing overall efficiency. Experience the benefits of a fully integrated farm, where every aspect of your operation works together harmoniously.</p>
                            
                        </div>
                        <div className="col-lg-5 mt-0 mt-md-5 pt-0 pt-md-5 ms-0 ms-md-5 order-1 order-lg-2">
                            <img className='img-fluid' src='/farmtech.png'/>
                        </div>
                    </div>
                    
                </div>
                
            </div>
            <div className="smartcity container-fluid pb-5">   
                <div className=" row mt-5 pt-5">
                    <div className="container">
                        <div className="row">

                        
                        <div className="col-lg-6 mt-5 pt-5 order-2 order-lg-1 text-center text-lg-start" data-aos="fade-right" data-aos-duration="2500">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }}>Artifical Intelligence for Crop Health Monitoring</h1>
                            <p className='pt-3'style={{color:'white', textAlign:'justify'}}>Utilize AI to monitor crop health through drones and satellite images, enabling early detection of disease, pest infestations, and nutrient deficiencies across vast areas. </p>
                            <p className='pt-3 pb-3' style={{color:'white',textAlign:'justify'}}>Our cutting-edge monitoring systems provide real-time data and actionable insights, ensuring timely and effective interventions. Drawing inspiration from UAV solutions by AeroVironment, Inc., satellite imaging by Planet Labs, and precision agriculture drone technology by DJI Agriculture, our solutions empower farmers to proactively manage crop health.</p>
                            <Link to='/case-studies/AIhealthcare' className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600  ">Discover Crop Health Monitoring AI</Link>
                        </div>
                        <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5 order-1 order-lg-2">
                            <img className='img-fluid' src='/droneagri.png' data-aos="fade-left" data-aos-duration="2500"/>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
               
    </div>
  )
}

export default SmartCityTech
