import React from 'react';

function autoarticle() {
    return (
        <div className='vid'>
            <div className="aimainsp26 container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5 pb-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">All About Electron Beam Melting (EBM) 3D Printing</h1>
                        <p className='pt-4' style={{ color: 'white',textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">Metal 3D printing has revolutionized the production of complex metal tooling and parts, offering a robust alternative to traditional methods like CNC machining and metal casting. Electron Beam Melting (EBM) stands out due to its ability to produce durable and strong metal parts swiftly, leveraging the speed of 3D printing technology. EBM is a powder bed fusion process, akin to Selective Laser Melting (SLM) and Selective Laser Sintering (SLS), where each thin layer of metal powder is deposited onto a heated bed and subsequently melted or sintered into place. However, EBM distinguishes itself by utilizing an electron beam instead of a laser beam and requiring a vacuum instead of atmospheric pressure. Common materials used in EBM include chromium-cobalt and titanium alloys.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-5 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">What Is Electron Beam Melting (EBM)?</h1>
                        <p className='pt-4' style={{textAlign:'justify'}} data-aos="fade-left" data-aos-duration="2000">Electron Beam Melting (EBM) is a 3D printing process that uses electrically conductive metal powders and electron beams to build parts layer by layer. The process necessitates a vacuum of about 0.0001 mbar in the print chamber. Without a vacuum, high-energy electrons would frequently collide with gas molecules, diminishing the beam's energy needed for the printing process. Once the vacuum is achieved, the build platform is heated to extremely high temperatures (600-700 ℃), and metal powder is precisely deposited to form the current cross-sectional layer of the part. The electron beam meticulously moves across the build platform, selectively melting and fusing the new powder layer with the previously printed layers. This process is repeated layer by layer until the entire part is printed.</p>
                            <img className='img-fluid pt-5' src='https://img.baba-blog.com/2024/01/person-holding-object-printed-on-industrial-powder-3d-printer.jpeg?x-oss-process=style%2Ffull' data-aos="fade-down" data-aos-duration="2500" alt="EBM Technology" />
                      
                    </div>
                </div>
            </div>
           

            <div className="container mt-3 pt-md-0">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">History of EBM Printing</h1>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-right" data-aos-duration="2000">Electron beam technology dates back to 1869 when Johann Wilhelm Hittorf and William Crookes experimented with cathode rays (another term for electron beams) to melt metals. Significant advancements occurred in 1952 when Dr. Karl-Heinz Steigerwald developed the first practical electron beam processes for commercial use, initially for welding applications. The principles and theory of EBM were patented by the Swedish company Arcam in 1993, in collaboration with the Chalmers University of Technology in Gothenburg, Sweden. In 1997, Arcam was reorganized into Arcam AB to further develop and commercialize the EBM 3D printing process. Arcam AB was acquired by GE in 2016 and incorporated into GE Additive.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-3 pt-md-0">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Applications of Electron Beam Melting</h1>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-right" data-aos-duration="2000">EBM 3D printing is ideal for small-batch manufacturing and proof-of-concept verification of parts with intricate geometries. The high costs associated with EBM systems and metal powders make it less suitable for mass production. Nevertheless, EBM produces high-strength metal parts extensively used in the aerospace, motorsports, and medical industries, including turbine blades, engine components, medical implants, and prostheses.</p>                        
                    </div>
                </div>
            </div>

  
           

       

            <div className="container mt-3 pt-md-0 ">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Similarities and Differences with Other 3D Printing Technologies</h1>                        
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">EBM is similar to Selective Laser Melting (SLM) and Selective Laser Sintering (SLS) as it builds parts layer by layer using a beam to fuse metal powders. However, EBM uses an electron beam instead of a laser, requires a vacuum environment, and operates at higher build platform temperatures.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-3 pt-md-0 ">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">How Does Electron Beam Melting Work?</h1>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">The EBM process involves the following steps:</p>
                        <div className="w-full md:w-4/4">
                            <ul >
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium' ><span className='text-orange-500  text-6xl'>.</span> Powder Deposition: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Metal powder is deposited onto the build platform to form the current cross-sectional layer.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Vacuum Creation: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>The chamber pressure is reduced to around 0.0001 mbar.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Heating: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>The electron beam heats the build platform to 600-700 ℃.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Melting and Fusing: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>The electron beam selectively melts and fuses the metal powder.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Layering: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>The build platform lowers by one layer's height, and a new powder layer is added.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Repetition: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Melting & fusing along with layering are repeated until the part is fully printed.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Cooling:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>After printing, the loose metal powder is removed, and support structures are taken off.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Post-Processing:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Residual powder and support structures are removed.</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-3 pt-md-0 ">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Accuracy of Electron Beam Melting</h1>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">EBM generally offers less accuracy than SLM due to thicker layers and coarser powder particles, resulting in rougher surface finishes. Post-processing is often necessary to achieve the desired tolerances and surface qualities.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-3 pt-md-0 ">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Materials Used in Electron Beam Melting</h1>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">EBM is limited to a few metals, including titanium, chromium-cobalt alloys, certain steel powders, and Inconel® 718. Non-metallic materials like polymers and ceramics cannot be used due to their lack of electrical conductivity.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-3 pt-md-0 ">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Required Temperatures for EBM</h1>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">The EBM process requires extremely high temperatures, up to 2000 ℃, to fuse high melting-point materials like titanium. Preheating the build platform to 600-700 ℃ minimizes residual stresses and enhances mechanical properties. Adequate support structures are needed to prevent warping due to the high build platform temperature.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-3 pt-md-0 ">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Advantages of EBM</h1>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">The EBM process requires extremely high temperatures, up to 2000 ℃, to fuse high melting-point materials like titanium. Preheating the build platform to 600-700 ℃ minimizes residual stresses and enhances mechanical properties. Adequate support structures are needed to prevent warping due to the high build platform temperature.</p>
                        <div className="w-full md:w-4/4">
                            <ul >
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium' ><span className='text-orange-500  text-6xl'>.</span> High-Density Parts: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>EBM produces parts with excellent mechanical properties.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Prints Brittle Parts: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Suitable for materials difficult to process with SLM.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Powder Recycling: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Unused powder can be recycled, reducing waste and costs.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Powerful Electron Beams: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Higher energy levels lead to faster print speeds compared to SLM.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Quality: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Comparable to traditional manufacturing methods like casting or CNC machining.</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-3 pt-md-0 ">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Disadvantages of EBM</h1>
                        <p className=' 'style={{textAlign:'justify'}}  data-aos="fade-left" data-aos-duration="2000">The EBM process requires extremely high temperatures, up to 2000 ℃, to fuse high melting-point materials like titanium. Preheating the build platform to 600-700 ℃ minimizes residual stresses and enhances mechanical properties. Adequate support structures are needed to prevent warping due to the high build platform temperature.</p>
                        <div className="w-full md:w-4/4">
                            <ul >
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium' ><span className='text-orange-500  text-6xl'>.</span> High Cost: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>EBM technology and metal powders are expensive.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Limited Material Selection: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Fewer compatible materials compared to SLM.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Lower Dimensional Accuracy: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Thicker layers and larger powder particles affect precision.</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-3 pt-md-0 ">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">EBM vs. DMLS 3D Printing</h1>
                        <div className="w-full md:w-4/4">
                            <ul >
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium' ><span className='text-orange-500  text-6xl'>.</span> Beam Source: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>EBM uses electrons; DMLS uses laser photons</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Printing Environment: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>EBM needs a vacuum; DMLS operates at atmospheric pressure with inert gas.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Material Range: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>DMLS has a broader range of compatible materials.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Dimensional Accuracy: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>DMLS typically offers higher precision.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Cost: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>EBM is more costly due to its technology and materials.</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default autoarticle;
