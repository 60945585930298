import React from 'react'
import { Link, useLocation } from 'react-router-dom';
function CustomizedFurniture() {
  return (
    <div>
       <div className="smartcity container-fluid">   
                <div className=" row">
                    <div className="container">
                        <div className="row">

                        
                        <div className="col-lg-6 mt-5 pt-5" data-aos="fade-right" data-aos-duration="2500">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }}>Tailored Excellence: Elevate Your Space with Customized Furniture Solutions</h1>
                            <p className='pt-3' style={{color:'white',textAlign:'justify'}}>From ergonomic desks and ergonomic chairs to bespoke storage solutions and collaborative workstations, our office furniture designs combine functionality with refined aesthetics to inspire creativity and enhance efficiency in the workplace.</p>
                            <p className='pt-3'style={{color:'white', textAlign:'justify'}}>Indulge in the luxury of personalized living with our bespoke furniture solutions, meticulously crafted to elevate every aspect of your space. From sleek modern designs to timeless classics, we specialize in creating furniture that reflects your unique style and complements the ambiance of your home or office. </p>
                        </div>
                        <div className="col-lg-5  ms-0 ms-md-5">
                            <img className='img-fluid' src='/print.png' data-aos="fade-left" data-aos-duration="2500"/>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>

            <div className=" container mt-8">
                <div className="row justify-content-center">
                <h1 className='font-semibold text-center mb-5 mt-5' style={{ color: 'black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Featured Solutions</h1>
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                            <img src="/body-scan.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                            <p className="text-sm text-center">Custom Design Consultation</p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>Embark on a collaborative journey with our expert designers, who will work closely with you to understand your vision, preferences, and functional requirements. Through personalized consultations, we'll guide you through the design process, offering insights and recommendations to bring your dream furniture to life.</p>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1500" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                            <img src="/face.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                            <p className="text-sm text-center">Tailored Craftsmanship</p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>Experience the artistry of tailored craftsmanship as our skilled artisans transform your vision into reality. With meticulous attention to detail and a commitment to excellence, we handcraft each piece of furniture using premium materials and time-honored techniques, ensuring durability, functionality, and beauty.</p>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="2000" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                            <img src="/hand.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                            <p className="text-sm text-center">Uncompromising Quality</p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>At the heart of our ethos lies an unwavering commitment to quality and craftsmanship. From the selection of finest materials to the precision of every cut and joint, we uphold the highest standards of excellence in every aspect of our work, delivering furniture that stands the test of time and surpasses expectations.</p>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="3000" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                            <img src="/gesture-control.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                            <p className="text-sm text-center">Timeless Furniture Designs</p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>Experience the timeless allure of classic furniture designs meticulously crafted to withstand the test of time and transcend fleeting trends. From graceful curves to refined detailing, our timeless pieces exude elegance and sophistication, enhancing the ambiance of any interior space.</p>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1500" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                            <img src="/chart.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                            <p className="text-sm text-center">Versatile & Cosy</p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>Whether you're furnishing a cozy apartment, a sprawling mansion, or a corporate office, our customized furniture solutions are tailored to suit your space and lifestyle. From space-saving multifunctional pieces to statement-making focal points, we offer versatile solutions that enhance functionality and style in any setting.</p>
                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1500" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                            <img src="/chart.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                            <p className="text-sm text-center">Design for Urban Living</p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}>Designed with urban living in mind, our contemporary furniture combines style with functionality to meet the demands of modern lifestyles. Whether you're furnishing a compact apartment or a bustling city loft, our pieces are crafted to maximize space efficiency without compromising on style or comfort.</p>
                        </div>
                    </div>
                   
                </div>
            </div>

            
    </div>
  )
}

export default CustomizedFurniture
