import React from 'react'
import { Link } from 'react-router-dom'
function Solar() {
  return (
    <div className='vid'>
      <div className="aimainsp7 container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500"> Solar Photovoltaic Technology</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">Discover the marvels of solar cell technologies on our website. Learn how these innovative devices harness the power of sunlight to generate clean and renewable electricity. Dive into the fascinating world of semiconductor materials, p-n junctions, and the photovoltaic effect, unraveling the science behind solar energy conversion. Explore the components and processes involved in turning sunlight into electricity, paving the way towards a sustainable energy future. Join us as we illuminate the path towards a greener tomorrow with solar power.</p>
                        <div className="container mt-8" >
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/bati.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Efficiency</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/ecology.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Sustainability</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/bio.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Green Energy</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-0 mt-md-5  pb-5 ">
                <div className="container mt-0 mt-md-0 " >
                    <div className="row mt-5 pt-5" data-aos="fade-left" data-aos-duration="2000">
                        <div className="col-lg-6 mt-5 pt-5 order-2 order-lg-1 text-center text-lg-start">   
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Going Green: The Benefits of Solar Technology</h1>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>By leveraging solar panels, you can significantly mitigate your carbon footprint, ushering in a sustainable energy paradigm. Embrace a cost-effective and environmentally conscious approach to power generation, as solar technology diminishes reliance on finite fossil fuels and curtails energy expenditures over the long term.</p>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Embrace the intricate workings of solar technology and witness its profound advantages firsthand. From its capacity to reduce greenhouse gas emissions to its robustness and minimal upkeep requirements, solar power epitomizes a sophisticated solution for modern energy challenges. Seamlessly integrating into existing infrastructure, solar technology paves the way for energy autonomy and fosters a landscape where sustainability and innovation converge.</p>
                        </div>
                        <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5 order-1 order-lg-2 text-center text-lg-start">
                            <img className='img-fluid' src='/solar.png'/>
                        </div>
                    </div>
                    <div className="row mt-5 " data-aos="fade-right" data-aos-duration="2000">
                        <div className="col-lg-5 mt-5 pt-5 me-0 me-md-5" >
                        {/* <div className="video-container" >
                    <iframe src='https://www.youtube.com/embed/KuF03h8x9Po?si=dNbRQwiXDx2ZuHw9' className="responsive-iframe" allowfullscreen data-aos="fade-down" data-aos-duration="2000" autoplay ></iframe>
                </div> */}
                <img className='img-fluid' src='/solarex.png'/>
                        </div>
                        <div className="col-lg-6 mt-5 pt-5 text-center text-lg-start"> 
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Addressing Some Common Solar Misconception</h1>
                            <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }}>Solar Panels: An Expensive Technology?</h1>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>A common misconception surrounding solar panels, regardless of type, revolves around their perceived costliness and inaccessibility. While upfront installation expenses may appear daunting, they often overshadow the long-term financial benefits and environmental advantages offered by solar energy.  </p>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Additionally, advancements in technology and increased market competition have steadily driven down the cost of solar panels in recent years, making them more affordable and accessible to a broader range of consumers. Moreover, various financial incentives, such as tax credits and rebates, further offset initial investment costs, making solar energy an increasingly viable and attractive option for homeowners and businesses alike. By dispelling the misconception of solar panels as prohibitively expensive, individuals can recognize the significant economic and environmental value they bring, ultimately accelerating the transition towards a sustainable energy future.</p>
                             <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3" ><Link to='/Solar/are-solarpanel-expensive?'>Read More</Link></button>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="smartcity container-fluid">   
                <div className=" row mt-5 pt-2 pb-5">
                    <div className="container">
                        <div className="row justify-content-center">

                        
                        <div className="col-lg-6 mt-5 pt-5 pb-5" data-aos="fade-right" data-aos-duration="2500">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }}>FAQS : Answering Common Solar Panel Queries</h1>
                        <h1 className='font-semibold pt-3' style={{ color: 'white', fontSize: '25' ,fontWeight:'bold' }}>How do solar panels work?</h1>
                            <p className='pt-3'style={{color:'white', textAlign:'justify'}}>Solar panels work by harnessing sunlight through photovoltaic cells, which convert sunlight into electricity through the photovoltaic effect. When photons from sunlight strike the solar panel, they generate an electric current by dislodging electrons from atoms in the semiconductor material.</p>
                            <h1 className='font-semibold pt-3' style={{ color: 'white', fontSize: '25' ,fontWeight:'bold' }}>Are solar panels efficient?</h1>
                            <p className='pt-3'style={{color:'white', textAlign:'justify'}}>Solar panel efficiency varies depending on factors such as the type of panel, sunlight intensity, temperature, and angle of installation. Crystalline silicon panels typically have efficiencies ranging from 15% to 22%, while thin-film panels may have efficiencies between 10% and 12%.</p>
                            <h1 className='font-semibold pt-3' style={{ color: 'white', fontSize: '25' ,fontWeight:'bold' }}>How long do solar panels last?</h1>
                            <p className='pt-3'style={{color:'white', textAlign:'justify'}}>Most solar panels come with warranties ranging from 25 to 30 years, indicating their expected lifespan. However, solar panels can continue to generate electricity beyond their warranty period, albeit with reduced efficiency.</p>
                            <h1 className='font-semibold pt-3' style={{ color: 'white', fontSize: '25' ,fontWeight:'bold' }}>What incentives are available for installing solar panels?</h1>
                            <p className='pt-3'style={{color:'white', textAlign:'justify'}}>Various financial incentives, such as federal tax credits, state rebates, and net metering programs, may be available to homeowners and businesses that install solar panels. These incentives can significantly reduce the upfront cost and accelerate the payback period of solar installations.</p>
                            <h1 className='font-semibold pt-3' style={{ color: 'white', fontSize: '25' ,fontWeight:'bold' }}>Do solar panels work on cloudy days?</h1>
                            <p className='pt-3'style={{color:'white', textAlign:'justify'}}>Yes, solar panels can still generate electricity on cloudy days, although at reduced efficiency compared to sunny days. They rely on daylight rather than direct sunlight, so they can still produce power even when the sky is overcast.</p>
                            
                        </div>
                        {/* <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                            <img className='img-fluid' src='/consumer.jpg' data-aos="fade-left" data-aos-duration="2500"/>
                        </div> */}
                    </div>
                    </div>
                    </div>
                </div>
                
                
                
    </div>
  )
}

export default Solar
