import React from 'react'

function autoarticle() {
    return (
        <div>
            <div className="aimainsp10 pb-5 container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5 pb-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Enhancing Laser Welding Quality in Electric Motor Assembly</h1>
                        <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2000">In the assembly of electric motors, ensuring the integrity and quality of welded hairpins is critical. Typically, between 160 to 220 hairpins are welded together in each motor, and the precision of these welds directly affects the overall quality of the stators and motors. Traditional methods of inspecting these welds struggle to balance the need for both safety and precision, often resulting in compromised motor performance.</p>

                    </div>
                </div>

            </div>

            




            <div className="container mt-3 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">

                    <div className="col-lg-8 mt-2">
                    <h1 className='font-semibold  mb-2 mt-5' style={{ color: 'black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">The Challenge</h1>
                        <p className='pt-2 ' style={{ textAlign: 'justify' }} data-aos="fade-left" data-aos-duration="2000">Today, this multifaceted network faces numerous disruptions, including geopolitical tensions, economic fluctuations, climate change, and evolving regulations. At the same time, common goals such as customer satisfaction, cost optimization, resilience, and adaptability transcend industries. Addressing both these challenges and goals requires innovative solutions, making supply chain optimization a critical factor in ensuring efficiency.</p>
                    </div>

                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">

                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Solution: AI-Powered 3D Inspection System</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">Our AI addresses this challenge by developing an advanced 3D inspection system, named ZScan 360, leveraging artificial intelligence (AI). This system comprises the following key components:</p>
                        <div className="w-full md:w-4/4">
                            <ul >
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium' ><span className='text-orange-500  text-6xl'>.</span> 3D Sensor Module: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Captures high-resolution data of the welding spots.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Deep Learning Algorithms:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Evaluate the captured data to assess the quality of the welds. </p>
                                </div>
                            </ul>
                        </div>
                        <p className="text-md pt-4" style={{ textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2000">The AI-based image processing system, models complex relationships within the welding process that are difficult to capture using traditional rule-based methods. By analyzing deviations from optimal weld seam characteristics, the system can also provide insights into potential issues in upstream process steps.</p>                       
                    </div>

                </div>
            </div>

            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">

                    <div className="col-lg-8  pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Application</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">The ZScan 360 is deployed as an inline measuring system in the production lines of stators and other rotationally symmetrical parts. Its capabilities include:</p>
                        <div className="w-full md:w-4/4">
                            <ul >
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium' ><span className='text-orange-500  text-6xl'>.</span> High-Resolution Data Capture: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>The 3D scanner provides detailed images of each welding point, ensuring even minute defects are detected.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Real-Time Analysis:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>EDeep learning algorithms process the data in real-time, allowing for immediate identification and rectification of any welding defects. </p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Process Optimization:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>The system not only inspects but also provides feedback for improving upstream processes, ensuring overall production quality is enhanced.</p>
                                </div>
                            </ul>
                        </div>
                        
                       
                    </div>

                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Benefits</h1>
                        <div className="w-full md:w-4/4">
                            <ul >
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium' ><span className='text-orange-500  text-6xl'>.</span> Increased Precision and Safety: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>The AI-powered inspection ensures that each weld is assessed with high accuracy, reducing the risk of defective motors.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Efficiency and Automation:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>The automation of the inspection process means that quality control is both faster and more reliable, reducing manual intervention. </p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Cost Savings:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>By minimizing defects and the need for rework, the system helps in cutting down production costs and enhancing throughput. </p>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
           
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-5">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Conclusion</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">AIM’s 3D inspection system with AI integration represents a significant advancement in the automation of laser welding processes. By ensuring high-quality welds and providing actionable insights for process improvement, it helps manufacturers maintain high standards while optimizing efficiency and reducing costs. This solution exemplifies how modern measurement technology and AI can be harnessed to address complex manufacturing challenges to increase quality inspection efficiency.</p>
                        
                    </div>
                </div>
            </div>

        </div>


    )
}

export default autoarticle
