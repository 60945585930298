import React from 'react'

function autoarticle() {
  return (
    <div> 
      <div className="aimain container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5 pb-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Essential Drone Rules for Safe and Fun Flying</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">Drones have transformed the realms of photography, recreation, and many professional fields. However, flying a drone responsibly requires understanding and adhering to specific safety guidelines. This article gives an overview of essential rules for recreational drone operators.</p>
                        
                    </div>
                </div>
                
            </div>
        

            

            <div className="container mt-3 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">
                    
                    <div className="col-lg-8 mt-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">The Altitude Limit</h1>
                        <p className='pt-4 pb-5' data-aos="fade-left" data-aos-duration="2000">One of the most critical rules for drone operators is to keep the drone below 120 meters (400 feet) above ground level. This altitude limit helps prevent conflicts with other aircraft, ensuring safe operation for both drone users and other airspace users.</p>
                    <img className='img-fluid' src='https://i.imgur.com/Oyz2dSS.jpeg' data-aos="fade-down" data-aos-duration="2500"/>
                        <p className='pt-5' data-aos="fade-left" data-aos-duration="2000">Safety around people is paramount. Always keep a minimum distance of 30 meters from others and avoid flying directly over them. This precaution helps prevent accidents and minimizes disturbances to those nearby.</p>
                    </div>
                    
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">
                    
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Visual Line of Sight</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000">Drone operators must keep their drones within their visual line of sight. This means being able to see your drone with your own eyes without using any visual aids like screens or goggles. Maintaining visual contact allows for better control and the ability to respond quickly to any unexpected situations.</p>
                    </div>
                    
                </div>
            </div>
            
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">
                    
                    <div className="col-lg-8  pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Avoiding Populous Areas</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000">Flying drones in densely populated areas such as beaches, parks, and sports events is discouraged. These areas often have high concentrations of people, increasing the risk of accidents and privacy issues. Keeping away from populous zones ensures a safer flying experience for everyone.</p>
                        <img className='img-fluid pt-4' src='https://i.imgur.com/nGBFaZY.jpeg' data-aos="fade-down" data-aos-duration="2500"/>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000">For drones weighing more than 250 grams, it's essential to avoid flying within 5.5 kilometers of controlled airports to prevent interference with manned aircraft. For lighter drones, you may fly up to 45 meters high within this radius, but it is crucial to avoid creating any hazards.</p>
                    </div>
                    
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Daylight Operations Only</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000">Operating your drone only during daylight hours is another key rule. Flying at night can significantly reduce visibility and increase the risk of losing control or causing an accident. Daylight flying ensures better visibility and safer operation.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Emergency Situations</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000">Never fly over or near emergency situations, such as those involving police, ambulance, or firefighting operations. Drones can interfere with the critical work being done by emergency responders, potentially causing delays or accidents.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                    <img className='img-fluid pt-3 pb-3' src='https://i.imgur.com/6dUNKAV.png' data-aos="fade-down" data-aos-duration="2500"/>
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Flying One Drone at a Time</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000">To ensure complete control and safety, operate only one drone at a time. Managing multiple drones simultaneously can be challenging and increases the likelihood of losing control or causing an accident.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Weather Conditions</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000">Avoid flying your drone in adverse weather conditions like clouds, fog, or smoke. Such conditions can obscure your view and make it difficult to maintain visual contact with your drone, leading to potential hazards.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Respecting Privacy</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000">Privacy is a significant concern when operating drones. Always respect others' privacy and avoid flying over private property or capturing images and videos without consent. This consideration helps maintain good relationships with your community and prevents legal issues.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-5">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Using Technology for Compliance</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000">Leveraging drone safety apps can help you stay informed about where you can and cannot fly your drone. These apps provide real-time updates on no-fly zones and other critical information, ensuring you remain compliant with regulations.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-5">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Reporting Unsafe Operations</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000">If you witness unsafe drone operations, report them to the relevant authorities. Violations of drone regulations can result in fines, starting at $1,565 per offense, with potential court-imposed fines reaching up to $15,650.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-5">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Conclusion</h1>
                        <p className='pt-4' data-aos="fade-left" data-aos-duration="2000">Flying a drone is an exciting and rewarding activity, but it comes with significant responsibilities. By adhering to these guidelines, you can ensure your drone operations are safe, legal, and respectful of others. Always stay informed, fly responsibly, and enjoy the unique perspectives that drone flying offers.</p>
                    </div>
                </div>
            </div>

        </div>
            

  )
}

export default autoarticle
