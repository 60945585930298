import React from 'react'
import { Link, useLocation } from 'react-router-dom';
function DmsMainPage() {
  return (
    <div className='vid'>
            <div className="aimain container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Effective and Reliable DMS Solutions</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">Our visionary approach is shaping the future landscape with unparalleled ingenuity. Through cutting-edge technologies and strategic foresight, we are driving unprecedented advancements across industries, revolutionizing the way we work, live, and interact.</p>
                        <div className="container mt-8" >
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/interior-design.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-lg text-center">Customized Furniture</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/sheet.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-lg text-center">Aluminum & Steel Fabrication</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/3d1.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-lg text-center">3D & Laser Printing Solutions</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aidetails mt-5 pt-md-0 pt-20 pb-5 pt-md-0">
                <div className="container mt-5 pt-20 pt-md-0" >
                    <div className="row mt-5 pt-5" data-aos="fade-left" data-aos-duration="2000">
                        <div className="col-lg-6 mt-5 pt-5">   
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Tailored Excellence: Customized Furniture Solutions</h1>
                            <p className='pt-3'style={{textAlign:'justify'}}>Our customized furniture solutions epitomize the pinnacle of precision engineering and personalized design. Leveraging advanced CAD/CAM technologies, we meticulously craft each piece to exact specifications, ensuring seamless integration with your space.</p>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>From concept to completion, our team of skilled artisans and engineers harnesses the latest in materials science and manufacturing techniques to bring your vision to life. Experience unparalleled quality and unmatched attention to detail with our bespoke furniture solutions, tailored to elevate your environment to new heights of sophistication and functionality.</p>
                            <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5 "><Link to=''>Discover</Link></button>
                        </div>
                        <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                            <img className='img-fluid' src='/furn.png'/>
                        </div>
                    </div>
                    <div className="row mt-5 pt-5" data-aos="fade-right" data-aos-duration="2000">
                        <div className="col-lg-5 mt-5 pt-5 me-0 me-md-5">
                            <img className='img-fluid' src='/work.png'/>
                        </div>
                        <div className="col-lg-6 mt-5 pt-5"> 
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Aluminum & Steel Fabrication</h1>
                            <p className='pt-3'style={{textAlign:'justify'}}>Delve into the realm of precision engineering with our aluminum and steel fabrication solutions. Through state-of-the-art techniques and unrivaled expertise, we transform raw metal into bespoke structures of enduring strength and functionality.</p>
                            <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Our team of skilled craftsmen employs advanced welding, cutting, and forming methods to realize your most intricate designs with precision and efficiency. Whether it's architectural elements, industrial components, or custom furnishings, our fabrication capabilities ensure unparalleled quality and durability. Trust in our commitment to excellence as we forge metal into masterpieces tailored to exceed your expectations.</p>
                             <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5" ><Link to=''>Discover</Link></button>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="smartcity container-fluid">   
                <div className=" row mt-5 pt-5">
                    <div className="container">
                        <div className="row">

                        
                        <div className="col-lg-6 mt-5 pt-5" data-aos="fade-right" data-aos-duration="2500">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }}>Innovative Precision: 3D and Laser Printing Solutions</h1>
                            <p className='pt-3'style={{color:'white', textAlign:'justify'}}>Experience the future of manufacturing with our cutting-edge 3D and laser printing solutions. Utilizing advanced additive manufacturing technologies, we bring your concepts to life with unparalleled precision and speed. </p>
                            <p className='pt-3' style={{color:'white',textAlign:'justify'}}>From intricate prototypes to functional components, our 3D printing capabilities enable limitless creativity and customization. Meanwhile, our state-of-the-art laser printing services offer unmatched accuracy and detail, perfect for intricate designs and engraving tasks. Harnessing the power of digital fabrication, we empower you to realize your visions with unrivaled efficiency and quality. Explore the possibilities of tomorrow today with our 3D and laser printing solutions.</p>
                            <button className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5 ">Discover 3D Printing Solutions</button>
                        </div>
                        <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                            <img className='img-fluid' src='/print.png' data-aos="fade-left" data-aos-duration="2500"/>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
        </div>
  )
}

export default DmsMainPage
