import React from 'react'

function autoarticle() {
    return (
        <div className='vid'>
            <div className="aimainsp11 pb-5 container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5 pb-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Self-Diagnosis of Disease With a Chatbot In Your Hand: The Case of "Left Hand Doctor"</h1>
                        <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2000">The advent of artificial intelligence (AI) in healthcare has opened new avenues for patient care and disease management. One groundbreaking innovation is the development of AI-powered chatbots for self-diagnosis, which are transforming the way patients engage with healthcare services. This case study explores the application of "Left Hand Doctor," an AI-driven chatbot designed for self-diagnosis, and its impact on patient care and healthcare efficiency.</p>

                    </div>
                </div>

            </div>
            <div className="container mt-3 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">

                    <div className="col-lg-8 mt-2">
                    <h1 className='font-semibold  mb-2 mt-5' style={{ color: 'black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Overview of "Left Hand Doctor"</h1>
                        <p className='pt-2 ' style={{ textAlign: 'justify' }} data-aos="fade-right" data-aos-duration="2000">"Left Hand Doctor" is a user-friendly, AI-powered chatbot accessible via smartphones and other mobile devices. It leverages natural language processing (NLP) and machine learning algorithms to interact with users, gather symptom information, and provide preliminary diagnoses. The chatbot is designed to:</p>
                        <div className="w-full md:w-4/4">
                            <ul >
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium' ><span className='text-orange-500  text-6xl'>.</span> Collect Symptom Data: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Users describe their symptoms in natural language, and the chatbot asks follow-up questions to gather detailed information.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Analyze Symptoms:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>The chatbot uses AI algorithms to analyze the symptoms and match them with a vast database of medical conditions. </p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Transportation Optimization:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Selecting the most efficient and cost-effective routes for transporting goods, considering factors such as traffic congestion and fuel prices.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Provide Preliminary Diagnosis:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Based on the analysis, the chatbot provides a list of potential diagnoses and recommendations for further action, such as seeing a healthcare professional or taking over-the-counter medication.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2600">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Educate Users:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>The chatbot offers information about the potential conditions, including causes, symptoms, and treatment options.</p>
                                </div>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">

                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Case Study: Implementing "Left Hand Doctor" in a Community Health Setting</h1>
                        <p className='pt-4' data-aos="fade-right" style={{ textAlign: 'justify' }} data-aos-duration="2000">A mid-sized community health clinic sought to improve patient engagement and reduce the burden on healthcare professionals. The clinic partnered with the developers of "Left Hand Doctor" to pilot the chatbot as a tool for self-diagnosis and patient education.</p>
                        <div className="w-full md:w-4/4">
                            <ul >
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <strong className='text-md font-medium' ><span className='text-orange-500  text-6xl'>.</span> Enhance Patient Engagement: </strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Encourage patients to take an active role in their health by using the chatbot for initial self-diagnosis.</p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Reduce Healthcare Burden:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Decrease the number of non-urgent visits to the clinic, allowing healthcare professionals to focus on more critical cases. </p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Improve Health Literacy:</strong>
                                    <p className="text-md" style={{ textAlign: 'justify' }}>Provide patients with accessible information about their health conditions. </p>
                                </div>
                            </ul>
                        </div>
                        <p className="text-md pt-4" style={{ textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2000">The clinic integrated "Left Hand Doctor" into its existing patient portal and promoted its use through various channels, including email newsletters, social media, and in-clinic signage. Patients were encouraged to use the chatbot for initial symptom checking before scheduling an appointment.</p>                       
                    </div>

                </div>
            </div>

            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">

                    <div className="col-lg-8  pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Results</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">Over six months, 70% of the clinic's patients used the chatbot at least once. The interactions revealed that users found it easy to use and helpful in understanding their symptoms. The clinic reported a 25% decrease in non-urgent visits, allowing healthcare professionals to allocate more time to patients with serious conditions. Patients who used the chatbot demonstrated a better understanding of their conditions and were more likely to follow through with recommended treatments and preventive measures.</p>

                       
                        
                       
                    </div>

                </div>
            </div>
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-2">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">User Experience</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">Jane Doe, a 45-year-old patient with recurring migraines, shared her experience using "Left Hand Doctor." Jane described her symptoms to the chatbot, which then asked specific questions about the frequency, intensity, and triggers of her migraines. Within minutes, the chatbot provided a preliminary diagnosis and suggested possible treatments, including lifestyle changes and medication options.</p>
                        <h1 className='font-semibold pt-4' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Feedback</h1>
                        <p className='mt-4 ps-3 bordercolor border-start' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000"> <i>Jane appreciated the convenience and immediacy of the chatbot. "It felt like having a doctor in my hand," she said. "I didn't have to wait for an appointment to get some initial guidance, and it gave me peace of mind."</i></p>
                    </div>
                </div>
            </div>
           
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row pt-2 justify-content-center">
                    <div className="col-lg-8 mt-2 pb-5">
                        <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Conclusion</h1>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">While "Left Hand Doctor" proved beneficial, the implementation also faced challenges. Although the chatbot provided accurate diagnoses in many cases, it occasionally misidentified symptoms, underscoring the need for human oversight. Some users were skeptical of AI-based diagnoses and preferred face-to-face consultations with healthcare professionals. Ensuring the confidentiality and security of patient data was a critical concern that required robust measures.</p>
                        <p className='pt-4' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">The success of "Left Hand Doctor" in the pilot phase has prompted plans for further development and expansion. Integrating the chatbot with patients' Electronic Health Records (EHR) will provide more personalized and accurate diagnoses. Expanding the chatbot's language capabilities will serve a more diverse patient population. Updating the chatbot's database and algorithms based on user feedback and new medical research will improve diagnostic accuracy.</p>
                    </div>
                </div>
            </div>

        </div>


    )
}

export default autoarticle
