import React from 'react'
import { Link } from 'react-router-dom'
function Airsterilizer() {
    return (
        <div className='vid'>
            <div className="aidetails mt-0 mt-md-0 pb-2 ">
                <div className="container mt-0 pt-0 pt-md-0" >
                    <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                        <div className="container mt-5 pt-20 pt-md-0">
                            <div className="row ">
                                <div className="col-lg-5 me-0 me-md-5">
                                    <img className='img-fluid pt-2' src='https://consumer.huawei.com/content/dam/huawei-cbg-site/common/mkt/pdp/routers/wifi-mesh7/imgs/kv/huawei-wifi-mesh-7-kv@2x.webp' data-aos="fade-right" data-aos-duration="2000" />
                                </div>
                                <div className="col-lg-6 mt-2">
                                    <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">HUAWEI WiFi Mesh 7</h1>
                                    <h1 className='font-semibold pt-3' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">HarmonyOS Mesh+</h1>
                                    <h2 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '18px' }} data-aos="fade-down" data-aos-duration="2000">Tri-band Super Networking for a Smoother Ride</h2>
                                    <p className='pt-2' data-aos="fade-right" data-aos-duration="2000">Create a mesh network with a 5 GHz high band, a 5 GHz low band and a 2.4 GHz simultaneously, bringing you exceptional speeds and a network efficiency boost of 17%3. The signal remains strong even against obstacles and interferences — for more stable connections all of the time.</p>
                                    <p className='pt-3' data-aos="fade-right" data-aos-duration="2000">Features:</p>
                                    <div className="w-full md:w-3/4">
                                        <ul>
                                            <div data-aos="fade-right" data-aos-duration="2000">
                                                <li className="mb-1">
                                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Whole Home Coverage</strong>
                                                    <p className="text-sm ">Delivers strong Wi-Fi to all connected devices throughout your home</p>
                                                </li>
                                            </div>
                                            <div data-aos="fade-right" data-aos-duration="2500">
                                                <li className="mb-1">
                                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Tri-band 6600 Mbps</strong>
                                                    <p className="text-sm ">Tri-band HUAWEI Wi-Fi 6 Plus boosts overall maximum speeds to 6,600 Mbps</p>
                                                </li>
                                            </div>
                                            <div data-aos="fade-right" data-aos-duration="2600">
                                                <li className="mb-1">
                                                    <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  Seamless Roaming</strong>
                                                    <p className="text-sm ">Move freely around your house with stable wifi connection.</p>
                                                </li>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="aidetails  pb-5 pt-md-0">
                        <div className="container  pt-md-0">
                            <div className="row mt-5 ">
                                <div className="col-lg-6 pt-5">
                                    <h1 className='font-semibold pt-3' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">HUAWEI WiFi AX3 Pro</h1>
                                    <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Ushering in the Era of Wi-Fi 6</h1>
                                    <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '18px' }} data-aos="fade-down" data-aos-duration="2000">Wi-Fi 6 Plus 3000 Mbps | Visualized Wi-Fi Diagnosis | One-Touch Connect</h1>

                                    <p className='pt-3' style={{ color: '#1E1E1E', textAlign: 'justify' }} data-aos="fade-right" data-aos-duration="2000">AX3 Pro and WiFi 6 Plus-enabled HUAWEI devices are the perfect match, with both supporting a 160 MHz large bandwidth. You'll enjoy an almost doubling in speed4, combined with maximum efficiency. Download large files instantly and enjoy video calls with no buffering.</p>
                                    <ul>
                                        <div data-aos="fade-right" data-aos-duration="2000">
                                            <li className="mb-1">
                                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Blazing Fast Speeds</strong>
                                                <p className="text-sm mb-1">Peak theoretical speeds of 3,000 Mbps.</p>
                                            </li>
                                        </div>
                                        <div data-aos="fade-right" data-aos-duration="2500">
                                            <li className="mb-1">
                                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Speed Up Your Wi-Fi 5 Devices</strong>
                                                <p className="text-sm mb-1">When connecting AX3 Pro to Wi-Fi 5 devices that support 160 MHz will have increased internet speeds by as much as 60%.</p>
                                            </li>
                                        </div>
                                        <div data-aos="fade-right" data-aos-duration="2600">
                                            <li className="mb-1">
                                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> High-Isolation Antenna</strong>
                                                <p className="text-sm mb-1">The antenna reduces interference to the maximum extent possible, generating pristine connections.</p>
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                                <div className="col-lg-5  me-0 me-md-5">
                                    <img className='img-fluid ' src='https://consumer.huawei.com/content/dam/huawei-cbg-site/common/mkt/pdp/routers/ax3-pro/imgs/pc/huawei-wifi-ax3-pro@2x.jpg' data-aos="fade-left" data-aos-duration="2000" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                        <div className="container mt-5 pt-20 pt-md-0">
                            <div className="row justify-content-center ">
                                <div className="col-lg-12 me-0 me-md-5">
                                    <img className='img-fluid pt-2' src='https://e.huawei.com/topic/X-GenWIFI-en/images/banner.jpg' data-aos="fade-right" data-aos-duration="2000" />
                                </div>
                                <div className="col-lg-8 mt-2 pt-5">
                                    <h1 className='font-semibold text-center' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Huawei X-Gen Wi-Fi</h1>
                                    <h1 className='font-semibold text-center pt-3' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Redefines the Agile Campus Network Era</h1>
                                    <p className='pt-3' data-aos="fade-right" data-aos-duration="2000">Huawei launched the X-Gen Wi-Fi Solution consisting of the industry's first 10G-capable access point (AP) AP7060DN and multi-gigabit Ethernet switch S6720-32C-PWH-SI. In compliance with the latest 802.11ax Wi-Fi standard, the AP7060DN leverages 8x8 MU-MIMO, OFDMA, and 1024-QAM technologies. In this manner, the AP achieves the three-fold bandwidth increase and four-fold increase in the number of concurrent access users. For the first time in the industry, the AP7060DN allows for hybrid access through both 10 Gbps capable Wi-Fi and Internet of Things (IoT) technologies, such as Bluetooth, ZigBee, and RFID, making more innovative service experience possible and redefining the agile campus network era.</p>
                                    <h1 className='font-semibold text-center pt-3' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Why 10G 802.11ax Wi-Fi?</h1>
                                    <p className='pt-3' data-aos="fade-right" data-aos-duration="2000">Emerging technologies, such as IoT, 4K/8K HD video, and Augmented Reality (AR)/Virtual Reality (VR), impose high-bandwidth, low-latency requirements for Wi-Fi networks. Additionally, the number of access terminals (including IoT terminals) in the office and business scenarios grows exponentially. Existing Wi-Fi standards and products are encountering great challenges to scale network capacity and bandwidth to meet these growing demands.</p>
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className=" container mt-8">
                <div className="row justify-content-center">
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0 text-center">

                            <p className='pt-3  text-xl' style={{ color: '#f77d27', textAlign: 'center', fontSize: '40px' }}>10 billion</p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'center' }}><b>Connections of people</b></p>

                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0 text-center">

                            <p className='pt-3  text-xl' style={{ color: '#f77d27', textAlign: 'center', fontSize: '40px' }}>90 billion</p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'center' }}><b>Connections of things</b></p>

                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-12 col-md-6">
                        <div className="bg-white p-4 rounded-lg  mb-4 mr-4 md:mb-0 md:mr-0 text-center">

                            <p className='pt-3  text-xl' style={{ color: '#f77d27', textAlign: 'center', fontSize: '40px' }}><b>100 billion connections</b></p>
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'center' }}><b>By 2025</b></p>

                        </div>
                    </div>
                    
                    
                </div>
            </div>

                   

                </div>


            </div>
            
        </div>
    )
}

export default Airsterilizer
