import React from 'react';

function AutoArticle() {
    return (
        <div className='vid'>
            <div className="aimainsp28 container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5 pb-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Shape Memory and Superelastic Alloys: Pioneering Innovation in Materials Science</h1>
                        <p className='pt-4' style={{ color: 'white', textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">Shape memory alloys (SMAs) represent a groundbreaking class of materials capable of significant deformations that revert to their original shape upon heating or cooling. This unique behavior, often referred to as a "memory effect," has revolutionized various industries by offering unparalleled versatility and reliability.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-5 pt-20 pt-md-0">
                <div className="row pt-5 justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h2 className='font-semibold' style={{ color: 'Black', fontSize: '30px' }} data-aos="fade-down" data-aos-duration="2000">Understanding the Mechanisms: Shape Memory Effect</h2>
                        <p className='pt-4' style={{ textAlign:'justify' }} data-aos="fade-down" data-aos-duration="2000">SMAs undergo a reversible martensitic transformation. At higher temperatures, they exist in an austenite phase with ordered structures. Upon cooling below a critical temperature, they transform into a martensitic phase, which can deform under low stress conditions due to its accommodating structure. Heating the alloy reverts it back to its original shape, showcasing the memory effect.</p>
                        <img className='img-fluid pt-5' src='https://media.licdn.com/dms/image/C5612AQFfgx4uZinjjw/article-cover_image-shrink_600_2000/0/1554619445388?e=2147483647&v=beta&t=MH-nP2z6iypaDA-AvrofHXcL_5PDBdb5-NYCN93u84E' data-aos="fade-down" data-aos-duration="2500" alt="CNC Machining" />
                    </div>
                </div>
            </div>

            <div className="container mt-3 pt-md-0">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h2 className='font-semibold' style={{ color: 'Black', fontSize: '30px' }} data-aos="fade-down" data-aos-duration="2000">Superelasticity: Beyond Conventional Elasticity</h2>
                        <p className='' style={{ textAlign:'justify' }} data-aos="fade-right" data-aos-duration="2000">In addition to the shape memory effect, SMAs exhibit superelasticity. This phenomenon allows the alloy to undergo extensive deformation under stress and return to its initial shape once the stress is removed, without the need for additional heating or cooling. Superelastic SMAs find applications in resilient structures such as spectacle frames and biomedical devices.</p>
                    </div>
                </div>
            </div>

            

            <div className="container mt-3 pt-md-0">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Commercial Applications and Innovations</h1> 
                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Aerospace and Industrial Applications</h2>                                              
                        <p className='' style={{ textAlign:'justify' }} data-aos="fade-left" data-aos-duration="2000">SMAs have been integral in aerospace, particularly in joining technologies where their ability to form tight seals under cryogenic conditions enhances structural integrity. They also serve in fire safety valves and anti-scald devices, demonstrating their reliability in critical environments.</p>
                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Biomedical and Consumer Electronics</h2>                                              
                        <p className='' style={{ textAlign:'justify' }} data-aos="fade-left" data-aos-duration="2000">In biomedical fields, SMAs are employed in biomedical implants, such as orthodontic wires and stents, which adjust to physiological conditions. Consumer electronics benefit from SMA technology in devices like smart eyeglass frames and adaptive smartphone antennas, enhancing user experience through shape adaptability.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-3 pt-md-0">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h1 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Alloy Systems: Advancements and Properties</h1> 
                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Copper-Aluminum-Nickel (CuAlNi) Alloys</h2>                                              
                        <p className='' style={{ textAlign:'justify' }} data-aos="fade-left" data-aos-duration="2000">CuAlNi alloys have emerged as preferred choices due to their wide range of transformation temperatures and stability at elevated temperatures. These alloys offer low hysteresis and are cost-effective, making them suitable for applications above 100°C, where other systems may not perform as effectively.</p>
                        <h2 className='font-semibold pt-2 pb-3 ' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Nickel-Titanium (NiTi) Alloys</h2>                                              
                        <p className='' style={{ textAlign:'justify' }} data-aos="fade-left" data-aos-duration="2000">NiTi alloys, known for their corrosion resistance and extensive shape memory capabilities, are prominent in biomedical applications. Despite their processing challenges and higher cost, NiTi alloys remain unmatched in applications requiring precise shape memory and durability.</p>
                    </div>
                </div>
            </div>

            

            <div className="container mt-3 pt-md-0">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h2 className='font-semibold' style={{ color: 'Black', fontSize: '30px' }} data-aos="fade-down" data-aos-duration="2000">Future Prospects: Smart Materials and Beyond</h2>
                        <p className='' style={{ textAlign:'justify' }} data-aos="fade-right" data-aos-duration="2000">The future of SMAs lies in "Smart Materials," where embedded sensors and actuators could revolutionize infrastructure and aerospace applications. These materials could autonomously monitor and respond to environmental changes, reducing the need for excessive reinforcement and enhancing structural longevity.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-3 pt-md-0 pb-5">
                <div className="row justify-content-center">
                    <div className="col-lg-8 pb-5">
                        <h2 className='font-semibold' style={{ color: 'Black', fontSize: '30px' }} data-aos="fade-down" data-aos-duration="2000">Conclusion</h2>
                        <p className='' style={{ textAlign:'justify' }} data-aos="fade-right" data-aos-duration="2000">Shape memory and superelastic alloys continue to redefine material science with their adaptive properties and diverse applications. As research and technological advancements propel their development, SMAs promise to lead innovations across industries, offering sustainable solutions and unprecedented functionality.</p>
                    </div>
                </div>
            </div>

            

            

        </div>
    );
}

export default AutoArticle;
