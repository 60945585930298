import React from 'react';
import './style.css';
function Footer() {
  return (
    <div className="footer container-fluid">
  <footer className="py-5 text-center text-sm-start"> {/* Added text-center and text-sm-start classes */}
    <div className="row">
      <div className="col-md-6 offset-md-1 mb-3">
        <h1 className='footerlogo text-4xl'>Advanced Industrial Materials Co Ltd.</h1>
        <div className="container mt-2">
          <p className='text-sm '><img src='/pin.png' className='pe-1' style={{display:'inline'}} width={20} /> 07-713 Nathan Road, Mongkok Kowloon, Hongkong.</p>
          <p className='text-sm pt-1' ><img src='/mail.png' className='pe-1' style={{display:'inline'}}  width={25} />info@aimmanufacturer.com</p>
          <p className='text-sm pt-1'><img src='/phone.png' className='pe-1' style={{display:'inline'}}  width={20} />0852-8291 9909</p>
        </div>
        <div className="d-flex flex-column flex-sm-row w-100 gap-2">
          <button className="newsbtn btn btn-primary ms-2 mt-3" type="button">Join Our Newsletter</button>
        </div>
      </div>

      <div className="col-6 col-md-2 mb-3">
        <h5>Company</h5>
        <ul className="nav flex-column pt-3">
          <li className="items nav-item mb-2"><a href="#" className="items nav-link p-0 text-body-secondary text-sm">About Us</a></li>
          <li className="items nav-item mb-2"><a href="#" className="items nav-link p-0 text-body-secondary text-sm">Meet Our Team</a></li>
          <li className="items nav-item mb-2"><a href="#" className="items nav-link p-0 text-body-secondary text-sm">News & Media</a></li>
          <li className="items nav-item mb-2"><a href="#" className="items nav-link p-0 text-body-secondary text-sm">Our Projects</a></li>
          <li className="items nav-item mb-2"><a href="#" className="items nav-link p-0 text-body-secondary text-sm">Careers</a></li>
          <li className="items nav-item mb-2"><a href="#" className="items nav-link p-0 text-body-secondary text-sm">Contact</a></li>
        </ul>
      </div>

      <div className="col-6 col-md-2 mb-3">
        <h5>Industry</h5>
        <ul className="nav flex-column pt-3">
          <li className="items nav-item mb-2"><a href="#" className="items nav-link p-0 text-body-secondary text-sm">Retail & Customer</a></li>
          <li className="items nav-item mb-2"><a href="#" className="items nav-link p-0 text-body-secondary text-sm">Heavy Industry</a></li>
          <li className="items nav-item mb-2"><a href="#" className="items nav-link p-0 text-body-secondary text-sm">Green Energy</a></li>
          <li className="items nav-item mb-2"><a href="#" className="items nav-link p-0 text-body-secondary text-sm">AI & Robotics</a></li>
          <li className="items nav-item mb-2"><a href="#" className="items nav-link p-0 text-body-secondary text-sm">Drones & SUVs</a></li>
          <li className="items nav-item mb-2"><a href="#" className="items nav-link p-0 text-body-secondary text-sm">3D Vision</a></li>
        </ul>
      </div>
    </div>
    <p className='items pt-5 text-sm text-center '>© 2024 Company, Inc. All rights reserved.</p>
  </footer>
</div>

  );
}

export default Footer;
