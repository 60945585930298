import React from 'react';

const Special = () => {
    return (
        <div className="special flex justify-center items-center mt-5 pt-5 pb-5 ">
            <div className="text-center">
            <div data-aos="fade-down" data-aos-duration="1500">
                <h2 className="text-4xl font-bold mb-4 pt-5">Specialized Manufacturing & Sourcing</h2>
                
                <p className="text-md text-gray-600 mb-8 text-center">Manufacturing & sourcing involving a critical<br></br> level of knowledge.</p>
                </div>
                <div className="flex flex-wrap justify-center">
                    {/* Card 1 */}
                    <div data-aos="zoom-in" data-aos-duration="1000">
                    <div className="bg-white p-8 rounded-lg shadow-md mx-4 mb-8" style={{ maxWidth: '330px' }}>
                        <h3 className="text-xl font-semibold mb-2">Business Consulting</h3>
                        <div className="flex justify-center items-center mb-4">
                            <img src="/consult.png" alt="Image 1" className="w-full" style={{ width: '113px', height: '114px', objectFit: 'cover' }} />
                        </div>
                        <p className="text-gray-700 mb-4 text-center">Our team of experienced professionals provides strategic guidance and practical solutions to optimize your business.</p>
                        {/* <button className="text-orange-500 py-2 px-6 rounded-full hover:bg-gray-300">Read More</button> */}
                    </div>
                    </div>
                    {/* Card 2 */}
                    <div data-aos="zoom-in" data-aos-duration="2000">
                    <div className="bg-white p-8 rounded-lg shadow-md mx-4 mb-8" style={{ maxWidth: '330px' }}>
                        <h3 className="text-xl font-semibold mb-2">Tech & Material</h3>
                        <div className="flex justify-center items-center mb-4">
                            <img src="/tec.png" alt="Image 2" className="w-full" style={{ width: '113px', height: '114px', objectFit: 'cover' }} />
                        </div>
                        <p className="text-gray-700 mb-4 text-center">we excel in providing state-of-the-art technology and high-quality materials essential for innovative solutions.</p>
                        {/* <button className="text-orange-500 py-2 px-6 rounded-full hover:bg-gray-300">Read More</button> */}
                    </div>
                    </div>
                    {/* Card 3 */}
                    <div data-aos="zoom-in" data-aos-duration="3000">
                    <div className="bg-white p-8 rounded-lg shadow-md mx-4 mb-8" style={{ maxWidth: '330px' }}>
                        <h3 className="text-xl font-semibold mb-2">AI Advantage</h3>
                        <div className="flex justify-center items-center mb-4">
                            <img src="/AI.png" alt="Image 3" className="w-full" style={{ width: '113px', height: '114px', objectFit: 'cover' }} />
                        </div>
                        <p className="text-gray-700 mb-4 text-center">Our offerings encompass advanced AI and tech products, electronics, and comprehensive tech business consulting services.</p>
                        {/* <button className="text-orange-500 py-2 px-6 rounded-full hover:bg-gray-300">Read More</button> */}
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Special;
