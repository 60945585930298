import React from 'react'

function Automotives() {
  return (
    <div className='vid'>
        <div className="baiduaimain container-fluid">
                <div className="  row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Step into the future with commercial Drones</h1>
                        <p className='pt-3' style={{ color: 'black' }} data-aos="fade-down" data-aos-duration="2000">From streamlining logistics and delivery operations to optimizing agriculture practices and conducting thorough infrastructure inspections, our drones empower businesses to thrive in the digital age. Experience the transformative power of drone technology and unlock new opportunities for growth and success in modern commerce.</p>
                        <div className=" container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/eg.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center ">Agriculture Drones</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/delivery.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Delivery Drones</p>
                                    </div>
                                </div>
                                {/* <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/pic.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">LPDDR5</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/autonomous.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">LPDDR4 </p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/nlp.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">UFS</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="2000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/gear.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">e.MMC</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="agri container-fluid pb-5 mb-5">
                <div className=" row mt-5 pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mt-5 pt-5">
                                <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Agriculture Drones: The Modern Farming</h1>
                                <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2500">Our agriculture drones utilize cutting-edge technology such as multispectral imaging and GPS navigation to provide farmers with real-time data on crop health, soil moisture levels, and pest infestations. This precision farming approach allows for targeted interventions, reducing the need for chemical inputs and maximizing crop productivity.</p>
                                <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="3000">With high-resolution cameras and advanced sensors, our drones enable comprehensive crop monitoring from above. Farmers can quickly identify areas of concern such as disease outbreaks, nutrient deficiencies, or irrigation issues, allowing for timely intervention and improved crop management.</p>
                            </div>
                            <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                                <img className='img-fluid' src='/agri.png' data-aos="fade-down" data-aos-duration="2500"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           

            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5">
                        <img className='img-fluid' src='/mavrik.png' data-aos="fade-down" data-aos-duration="2500"/>
                        {/* <div className="video-container" >
                    <iframe src='/dronevid.webm' className="responsive-iframe" allowfullscreen data-aos="fade-down" data-aos-duration="2000" autoplay></iframe>
                </div> */}
                        </div>
                        <div className="col-lg-6 mt-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Crop Monitoring</h1>
                            <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">DJI Mavic 3M</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">Effective aerial surveying needs to see the invisible. That's why Mavic 3 Multispectral has two forms of sight. It combines an RGB camera with a multispectral camera to scan and analyze crop growth with total clarity. Agricultural production management requires precision and data, and Mavic 3M delivers both.</p>
                            <div className="w-full md:w-4/4">
                            <div className="row">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Compact</strong>
                                <p className="text-sm">Foldable for easy storage</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Multispectral</strong>
                                <p className="text-sm">4 × 5MP | G/R/RE/NIR</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  RGB camera</strong>
                                <p className="text-sm">20 MP | 4/3 CMOS, mechanical shutter</p>
                            </div>
                            </div>
                    </div>
                        </div>
                    </div>
                </div>

            </div>
           
            <div className="aidetails mt-5 pt-md-0 pt-5 pb-1 pt-md-0">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row pt-5">
                        
                        <div className="col-lg-6 mt-2">
                            <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">DJI Terra</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">Generate realistic 3D models and point cloud results quickly from photos. AI technology now automatically optimizes water surfaces in the model. Users can now use Customized Mesh Simplification to simplify model meshes. Users can export formats commonly used in multiple industries for image reconstruction in diverse scenarios, such as surveying and mapping, accident sites, power grid equipment, and progress-tracking for construction projects.</p>
                            <div className="w-full md:w-4/4">
                            <div className="row">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Scale</strong>
                                <p className="text-sm">6,000 photos/1GB | (cluster processing)</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Efficiency</strong>
                                <p className="text-sm">30,000 photos/21 hours</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  Accuracy</strong>
                                <p className="text-sm">Centimeter-level accuracy</p>
                            </div>
                            </div>
                    </div>
                        </div>
                        <div className="col-lg-5 me-0 me-md-5">
                       
                        <div className="video-container" >
                    <iframe src='/terra.mp4' className="responsive-iframe" allowfullscreen data-aos="fade-down" data-aos-duration="2000" autoplay></iframe>
                </div>
                        </div>
                    </div>
                </div>

            </div>
            
            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0 pb-5">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5">
                        <div className="video-container" >
                    <iframe src='/dronevid.webm' className="responsive-iframe" allowfullscreen data-aos="fade-down" data-aos-duration="2000" autoplay></iframe>
                </div>
                        </div>
                        <div className="col-lg-6 ">
                        <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Spraying Pesticides</h1>
                            <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">DJI AGRAS T50</h1>
                            <p className='pt-2' data-aos="fade-right" data-aos-duration="2000">DJI AGRAS T50 elevates drone agricultural operations to new heights. It inherits a powerful coaxial twin-rotor propulsion system and a split-type torque resistant structure for next level stability when carrying 40 kg spraying or 50 kg spreading payloads.</p>
                            <div className="w-full md:w-4/4">
                            <div className="row">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Heavy Payload</strong>
                                <p className="text-sm">40 kg spraying | 50 kg spreading</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> High Flow Rate</strong>
                                <p className="text-sm">Spraying 16 L/min | Spreading 108 kg/min</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Signal Stability</strong>
                                <p className="text-sm">Offline operations | 2 km O3 Transmission</p>
                            </div>
                            </div>
                    </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="aidetails mt-5 pt-md-0 pt-5 pb-1 pt-md-0">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row pt-5">
                        
                        <div className="col-lg-6 mt-2">
                            <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">DJI AGRAS T25</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">DJI AGRAS T25 redefines standards for compact agricultural drones. Light and nimble, DJI AGRAS T25 can easily be handled by one person. It can carry a spraying payload up to 20 kg or a spreading payload up to 25 kg.</p>
                            <div className="w-full md:w-4/4">
                            <div className="row">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> 25 kg Payload</strong>
                                <p className="text-sm">35 L max capacity and an expanded loading gate for quick refills.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Uniform Spreading</strong>
                                <p className="text-sm">Switch to smaller hopper gates for more accurate low flow rate spreading. </p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  Real-Time Weighing</strong>
                                <p className="text-sm">DJI AGRAS T25 is equipped with a weighing sensor.</p>
                            </div>
                            </div>
                    </div>
                        </div>
                        <div className="col-lg-5 me-0 me-md-5">
                       
                        <div className="video-container" >
                    <iframe src='/vid2.webm' className="responsive-iframe" allowfullscreen data-aos="fade-down" data-aos-duration="2000" autoplay></iframe>
                </div>
                        </div>
                    </div>
                </div>

            </div>
            
            

            
            
      
    </div>
  )
}

export default Automotives
