import React from 'react';
import './style.css'; // Importing CSS file
import { Link } from 'react-router-dom';

import { RiArrowRightSLine } from 'react-icons/ri'; // Importing React Icons

const Header = () => {
    return (
        <div className="header container">
            <img src="/header.png" alt="Background" className="header-container absolute top-0 left-0 w-full object-cover" style={{height:'800px'}} />
            <div className="row">
                <div className="col-lg-8 ">
                    <div className=" mt-48 mb-10 flex flex-wrap justify-between items-center relative">
                {/* Block 1 */}
                <div className="w-full md:w-2/4 mb-12 md:mb-0 ml-10">
                
                    <div className="text-white">
                    <div data-aos="fade-down" data-aos-duration="1500">
                        <h2 className="text-6xl font-bold mb-4">Effective & Reliable</h2>
                        </div>
                        <div data-aos="fade-down" data-aos-duration="2000">
                        <p className="mb-4" style={{textAlign:'justify'}}>We specialize in cutting-edge AI and tech products, innovative electronics, and tailored solutions designed to meet the demands of today's fast-evolving technological landscape</p>
                        </div>
                        <div>
                        <div data-aos="fade-down" data-aos-duration="1500">
                            <button className="bg-orange-500 text-white rounded-full py-2 px-6 mr-4 hover:bg-orange-600"><Link to='/aisolutionsmainpage'>Discover</Link></button>
                            
                            <button className="rdbtn border border-orange-500 text-orange-500 rounded-full py-2 px-6 hover:bg-orange-100"><Link href='/Solar&Ev'>Read more</Link></button>
                            </div>
                        </div>
                    </div>
                   
                </div>     
            </div>
                </div>
                <div className="col-lg-4 mt-5 text-end justify-content-center">
  <div data-aos="fade-down" data-aos-duration="1500">
    {/* Use d-none d-lg-block to hide the image on screens smaller than large (lg) */}
    <img src="/Group.png" className='img-fluid mt-5 pt-5 ' alt="" />
  </div>
</div>


            </div>
            
        </div>

    );
};

export default Header;
