import React from 'react';
import { Link } from 'react-router-dom';

function PrecisionEngineering() {
    return (
        <div className='vid'>
            <div className="aimain container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Precision Engineering</h1>
                        <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">
                            Precision Engineering involves the use of advanced techniques to produce high-precision components and products, often in small scales. This field includes micro-machining, ultra-precision CNC machining, laser micromachining, and nano-manufacturing.
                        </p>
                        <div className="container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/micro.png" alt="Micro-Machining" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">Micro-Machining</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/milling-machine.png" alt="Ultra-Precision CNC Machining" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-md text-center">CNC Machining</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section for detailed information about Micro-Machining */}
            <div className="pedetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3' src='https://www.makino.com.sg/makino-us/media/general/micromachining-crop.jpg?ext=.jpg&maxsidesize=960&resizemode=force' data-aos="fade-down" data-aos-duration="2500" alt="Micro-Machining" />
                        </div>
                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Micro-Machining</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">
                                Micro-machining involves the fabrication of tiny components with high precision using specialized tools and techniques. It is essential for industries requiring miniaturization and intricate designs.
                            </p>
                            <div className="row pb-3">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://www.endmills-wotek.com/uploads/images/7cce88780cf32683933d0ef340d46405.jpg' data-aos="fade-down" data-aos-duration="2500" alt="Micro-Machining Image 1" />
                                    <p className='pt-1 text-sm'>Tools and machines used for micro-scale operations.</p>
                                </div>
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://images.kennametal.com/is/image/Kennametal/kendrill-micro-2xd-with-coolant?$ImageLarge$&fit=constrain' data-aos="fade-down" data-aos-duration="2500" alt="Micro-Machining Image 2" />
                                    <p className='pt-1 text-sm'>High precision manufacturing processes.</p>
                                </div>
                            </div>
                            <Link to='/Precision-Engineering/Micromachining' className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" data-aos="fade-down" data-aos-duration="2000">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section for detailed information about Ultra-Precision CNC Machining */}
            <div className="pedetails pt-md-0 pt-3 pb-1 pt-md-0 pb-5">
                <div className="container pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5 order-1 order-lg-1">
                            <img className='img-fluid pt-3' src='https://images.unsplash.com/photo-1615378809998-afc205e73bad?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzB8fENOQyUyME1hY2hpbmluZ3xlbnwwfHwwfHx8MA%3D%3D' data-aos="fade-down" data-aos-duration="2500" alt="Ultra-Precision CNC Machining" />
                        </div>
                        <div className="col-lg-6 mt-1 order-1 order-lg-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Ultra-Precision CNC Machining</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">
                                Ultra-precision CNC machining utilizes advanced CNC systems to achieve extremely tight tolerances and surface finishes in manufacturing. It is critical for industries requiring high accuracy and repeatability.
                            </p>
                            <div className="row pb-3">
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://images.unsplash.com/photo-1615551795304-56971412a00b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjh8fENOQyUyME1hY2hpbmluZ3xlbnwwfHwwfHx8MA%3D%3D' data-aos="fade-down" data-aos-duration="2500" alt="Ultra-Precision CNC Machining Image 1" />
                                    <p className='pt-1 text-sm'>High precision CNC machines in operation.</p>
                                </div>
                                <div className="col-lg-5 me-0 me-md-5 order-2 order-lg-1">
                                    <img className='img-fluid pt-3' src='https://images.unsplash.com/photo-1625464659832-8acde32a2d6f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzR8fENOQyUyME1hY2hpbmluZ3xlbnwwfHwwfHx8MA%3D%3D' data-aos="fade-down" data-aos-duration="2500" alt="Ultra-Precision CNC Machining Image 2" />
                                    <p className='pt-1 text-sm'>Precision tools used for CNC machining.</p>
                                </div>
                            </div>
                            <Link to='/Precision-Engineering/CNC' className="mainbtn border rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-1" >Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrecisionEngineering;
