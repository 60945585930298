import React from 'react';


const AboutUs = () => {
    return (
        <div className="container-fluid pb-5 mb-5">
            
        <div className="flex justify-center">
            <div className="p-5 ms-4 ">
            <div data-aos="fade-down" data-aos-duration="1000">
                <h2 className="text-4xl font-bold mb-4 text-center pt-5 mt-5 pb-5">About Us</h2>
                </div>
                <div data-aos="fade-down" data-aos-duration="1500">
                <p className="text-lg mb-4 text-orange-500 font-bold ">Manufacturing a variety of high-quality products</p>
                
                <h3 className="text-4xl font-semibold mb-8 ">A Leading Industry & Manufacturing <br/> Company, Serving Since 1997</h3>
                </div>
                <div className="flex flex-wrap justify-between">
                    {/* Block 1 */}
                    <div className="w-full md:w-2/5 mb-8 md:mb-0 pr-8">
                    <div data-aos="fade-down" data-aos-duration="2000">
                        <p className="text-sm mb-4 pt-2">
                            <b>Advanced Industrial Materials Co. Ltd. is based in the manufacturing hub of the world's largest industrial base. With a presence on five continents and the confidence of customer satisfaction, AIM steps ahead to a glorious future.</b>
                            <br /><br />
                            Our dedicated team of experts is driven by innovation, constantly pushing the boundaries of technology to deliver cutting-edge solutions tailored to meet the evolving needs of our clients. With a customer-centric approach, we strive to forge long-lasting partnerships built on trust, reliability, and exceptional quality. From conceptualization to execution, we are your trusted partner every step of the way, ensuring seamless integration and unparalleled support.
                        </p>
                        <button className="bg-orange-500 text-white rounded-full py-2 px-6 hover:bg-white hover:text-orange-500 border border-orange-500">Read More</button>
                    </div>
                    </div>
                    {/* Block 2 */}
                    <div className="w-full md:w-1/4 relative">
                    <div data-aos="fade-down" data-aos-duration="2500">
                        <img src="/about.png" alt="About Us Image" className="w-full" />
                        </div>
                        <p className='absolute bottom-0 left-0 text-white ps-3 pb-3 text-sm'>
                            <img src='/play.png' width={20} className="inline-block mr-1 pe-1"/>watch video
                        </p>
                    </div>


                    {/* Block 3 */}
                    <div className="w-full md:w-1/4">
                        <ul>
                        <div data-aos="fade-right" data-aos-duration="2000">
                            <li className="mb-4">
                                <strong className='text-xl font-medium'><span className='text-orange-500  text-6xl'>.</span> Quality</strong>
                                <p className="text-gray-700 mb-2">Committed to delivering exceptional quality and reliability.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2500">
                            <li className="mb-4">
                                <strong className='text-xl font-medium'><span className='text-orange-500  text-6xl'>.</span> Reliability</strong>
                                <p className="text-gray-700 mb-2">We pride ourselves on delivering dependable tech and material solutions that businesses can trust.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="">
                                <strong className='text-xl font-medium'><span className='text-orange-500  text-6xl'>.</span> Innovation</strong>
                                <p className="text-gray-700 mb-2">Unlocking tomorrow's possibilities through groundbreaking innovation.</p>
                            </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
        </div>
    );
};

export default AboutUs;
