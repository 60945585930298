import React from 'react'
import { Link } from 'react-router-dom'
function BMS() {
  return (
    <div className='vid'>
       <div className="baiduaimain container-fluid">
                <div className=" baidubg row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Specialized & Custom Electronic Solutions</h1>
                        <p className='pt-3' style={{ color: 'black' }} data-aos="fade-down" data-aos-duration="2000">Unveil a world of tailored electronic solutions designed to meet your unique needs and requirements. From specialized electronics to custom-built systems, we offer a comprehensive range of innovative products and services to empower your projects.</p>
                        <div className=" container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/technology.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center ">Raspberry Pi </p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/computer.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Arduino </p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/intel.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Intel</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/wifi-router.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Router Solutions </p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/bluetooth.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Bluetooth</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="2000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/iot.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">RB5 Development Kit</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="smartcity container-fluid pb-5 mb-5">
                <div className=" row mt-5 pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mt-5 pt-5">
                                <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">State of the Art Router Technology</h1>
                                <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2500">Experience the pinnacle of connectivity with our state-of-the-art router technology. Designed to meet the demands of today's digital landscape, our routers redefine performance, reliability, and security standards. </p>
                                <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="3000">Whether you're a home user seeking seamless streaming and gaming experiences or a business requiring robust network infrastructure, our routers deliver unparalleled speed, coverage, and functionality. Stay ahead of the curve with our cutting-edge router solutions, empowering you to connect, communicate, and innovate with confidence.</p>
                                <Link to='/case-studies/5G-technology' className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-3 mb-5 " data-aos="fade-down" data-aos-duration="2500">Discover 5G Router Technology</Link>
                            </div>
                            <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                                <img className='img-fluid' src='https://i.imgur.com/tooWgjS.jpeg' data-aos="fade-down" data-aos-duration="2500"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           

            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5">
                        <img className='img-fluid pt-5' src='/berry.png' data-aos="fade-right" data-aos-duration="2000"/>
                        </div>
                        <div className="col-lg-6 mt-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Discover Embedded Solutions</h1>
                            <h1 className='font-semibold pt-3' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Raspberry Pi Versatile Embedded boards</h1>
                            <p className='pt-2' data-aos="fade-right" data-aos-duration="2000">With 2–3× the speed of the previous generation, and featuring silicon designed in‑house for the best possible performance which redefines the Raspberry Pi experience.</p>
                            <p className='pt-2' data-aos="fade-right" data-aos-duration="2000">Features:</p>
                            <div className="w-full md:w-3/4">
                        <ul>
                        <div data-aos="fade-right" data-aos-duration="2000">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Build</strong>
                                <p className="text-sm mb-1">Raspberry Pi 5 features RP1 I/O controller, USB 3 for faster transfer speeds, and interchangeable camera/DSI display connectors.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2500">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Performance</strong>
                                <p className="text-sm mb-1">Raspberry Pi 5 features a Broadcom BCM2712 quad-core Arm Cortex A76 processor @ 2.4GHz, up to three times faster than its predecessor, with RAM variants up to 8GB, ensuring the fastest Raspberry Pi experience yet.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  Benefits</strong>
                                <p className="text-sm mb-1">This addition to Raspberry Pi allows you to connect an M.2 SSD to your Raspberry Pi, giving you speedy data transfer and super-fast boot.</p>
                            </li>
                            </div>
                        </ul>
                    </div>
                        </div>
                    </div>
                </div>

            </div>
           
            <div className="aidetails  pb-5 pt-md-0">
                <div className="container  pt-md-0">
                    <div className="row mt-5 pt-5">     
                        <div className="col-lg-6 pt-5">
                        <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Discover Microcontroller platforms</h1>
                        <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Arduino Nano 33 IoT</h1>
                            <p className='pt-3' style={{ color: '#1E1E1E', textAlign: 'justify' }} data-aos="fade-right" data-aos-duration="2000">The Arduino Nano 33 IoT is the easiest and cheapest point of entry to enhance existing devices (and creating new ones) to be part of the IoT and designing pico-network applications. </p>
                            <ul>
                        <div data-aos="fade-right" data-aos-duration="2000">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Processor and Connectivity</strong>
                                <p className="text-sm mb-1">Powered by a low-power Arm® Cortex®-M0 32-bit SAMD21 processor, the board features WiFi and Bluetooth® connectivity via the u-blox NINA-W10 module operating in the 2.4GHz range.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2500">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Security</strong>
                                <p className="text-sm mb-1">Ensures secure communication with the Microchip® ECC608 crypto chip.</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  Additional Features</strong>
                                <p className="text-sm mb-1">Includes a 6-axis IMU, ideal for applications such as vibration alarm systems, pedometers, and robot positioning.</p>
                            </li>
                            </div>
                        </ul>
                        </div>
                        <div className="col-lg-5  me-0 me-md-5">
                            <img className='img-fluid pt-5 mt-5' src='/ard.png' data-aos="fade-left" data-aos-duration="2000" />
                        </div>
                    </div>
                </div>

            </div>
            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5">
                        <img className='img-fluid pt-5' src='/intelimg.png' data-aos="fade-right" data-aos-duration="2000"/>
                        </div>
                        <div className="col-lg-6 mt-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Advanced Computing Solutions</h1>
                            <h1 className='font-semibold pt-3' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Intel Core Ultra Processing Family</h1>
                            <p className='pt-2' data-aos="fade-right" data-aos-duration="2000">Created for premium laptops, Intel® Core™ Ultra processors include built-in AI acceleration to boost productivity while maintaining privacy and flexibility.</p>
                            <p className='pt-2' data-aos="fade-right" data-aos-duration="2000">Features:</p>
                    <div className="w-full md:w-3/4">
                        <ul>
                        <div data-aos="fade-right" data-aos-duration="2000">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Core Configuration</strong>
                                <p className="text-sm mb-1">Performance-cores: 2, Efficient-cores: 8, Low Power Efficient-cores: 2, Total Threads: 14</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2500">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Graphics Processor</strong>
                                <p className="text-sm mb-1">GPU Name: Intel® Graphics, Graphics Max Dynamic Frequency: 1.95 GHz, Xe-cores: 4, Ray Tracing: Yes</p>
                            </li>
                            </div>
                            <div data-aos="fade-right" data-aos-duration="2600">
                            <li className="mb-1">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  AI Capabilities</strong>
                                <p className="text-sm mb-1">Intel® Deep Learning Boost (Intel® DL Boost) on CPU: Yes, AI Datatype Support on CPU: Int8, FP16, BF16, FP32, AI Software Frameworks Supported by CPU: OpenVINO™, WindowsML, ONNX RT</p>
                            </li>
                            </div>
                        </ul>
                    </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="specialcont container-fluid  pt-md-0 pb-5 ">
                    <div className="row mt-5 pt-5 justify-content-center">
                        
                        <div className="col-lg-4 pe-3">
                        <h1 className='font-semibold pt-5 text-center' style={{ color: 'white', fontSize: '25px' }} data-aos="fade-left" data-aos-duration="2000">Quality & Reliability</h1>
                            <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-left" data-aos-duration="2000">Explore our specialized electronics designed to excel in niche applications, delivering unmatched performance and reliability.</p>
                            
                        </div>
                        
                    </div>
                </div>

            
    </div>
  )
}

export default BMS
