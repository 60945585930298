import React from 'react'

function autoarticle() {
    return (
        <div className='vid'>
            <div className="aimainsp14 container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5 pb-5">
                        <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Easy-to-use precision farming software for year-round decision making</h1>
                        <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2000">Precision farming is about answering questions that can make you more profitable in all your field activities. SMS Software is an easy-to-use, decision-making tool that helps you get the most out of every acre.</p>

                    </div>
                </div>

            </div>

            <div className=" container mt-8">
                <div className="row justify-content-center">
                <h2 className="text-4xl font-bold mb-4 text-center pt-5 mt-5 pb-5">SMS Features</h2>
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-3 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0 text-center">

                        <img src="https://i.imgur.com/q7b5RA7.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}><b>Choose seed based on historic yield performance. Create planter performance reports and Satisfy government reporting needs.</b></p>

                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-3 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0 text-center">

                        <img src="https://i.imgur.com/YoLR64v.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}><b>Track variable rate application operations. Record application operations for record keeping and create variable rate prescriptions.</b></p>

                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-3 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0 text-center">

                        <img src="https://i.imgur.com/uFFYtSa.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}><b>Analyze harvest data and discover yield trends using multiple years’ data to create harvest reports and charts or use query tools.</b></p>

                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-3 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0 text-center">

                        <img src="https://i.imgur.com/bz9Eo3F.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                            <p className='pt-3 text-sm' style={{ color: '#1E1E1E', textAlign: 'justify' }}><b>Manage soil sampling and create field tile plans. Download soil survey maps and view aerial and road background maps.</b></p>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid pb-5 mb-5 ">
            
            <div className="flex justify-center">
                <div className="p-5 ms-4 ">
                <div data-aos="fade-down" data-aos-duration="1000">
                    <h2 className="text-4xl font-bold mb-4 text-center pt-5 mt-5 pb-5">SMS Basic</h2>
                    </div>
                    <div data-aos="fade-down" data-aos-duration="1500">
                    
                    
                    
                    </div>
                    <div className="flex flex-wrap justify-between">
                        {/* Block 1 */}
                        <div className="w-full md:w-3/12 mb-8 md:mb-0 pr-8">
                        <div data-aos="fade-down" data-aos-duration="2000">
                        <ul>
                            <div data-aos="fade-right" data-aos-duration="2000">
                                <li className="mb-4">
                                    <strong className='text-xl font-medium'><span className='text-orange-500  text-6xl'>.</span> Save Time</strong>
                                    <p className="text-gray-700 mb-2">Simplify Guidance Line Management</p>
                                </li>
                                </div>
                                <div data-aos="fade-right" data-aos-duration="2500">
                                <li className="mb-4">
                                    <strong className='text-xl font-medium'><span className='text-orange-500  text-6xl'>.</span> Soil Samples</strong>
                                    <p className="text-gray-700 mb-2">Manage Soil Sample Activities.</p>
                                </li>
                                </div>
                                <div data-aos="fade-right" data-aos-duration="2600">
                                <li className="">
                                    <strong className='text-xl font-medium'><span className='text-orange-500  text-6xl'>.</span> Accuracy</strong>
                                    <p className="text-gray-700 mb-2">Precisely Place Inputs.</p>
                                </li>
                                </div>
                            </ul>
                        </div>
                        </div>
                        {/* Block 2 */}
                        <div className="w-full md:w-6/12  relative">
                        <div data-aos="fade-down" data-aos-duration="2500">
                            <img src="https://www.agleader.com/wp-content/uploads/2019/06/SMS-Basic-Guidance-Editor.png" alt="About Us Image" className="w-full shadow-lg" />
                            </div>
                            
                        </div>
    
    
                        {/* Block 3 */}
                        <div className="w-full md:w-3/12 ps-5">
                            <ul>
                            <div data-aos="fade-right" data-aos-duration="2000">
                                <li className="mb-4">
                                    <strong className='text-xl font-medium'><span className='text-orange-500  text-6xl'>.</span> Soil Sampling</strong>
                                    <p className="text-gray-700 mb-2">Download Soil Survey Maps.</p>
                                </li>
                                </div>
                                <div data-aos="fade-right" data-aos-duration="2500">
                                <li className="mb-4">
                                    <strong className='text-xl font-medium'><span className='text-orange-500  text-6xl'>.</span> Yield Analysis</strong>
                                    <p className="text-gray-700 mb-2">Discover Yield Influencers.</p>
                                </li>
                                </div>
                                <div data-aos="fade-right" data-aos-duration="2600">
                                <li className="">
                                    <strong className='text-xl font-medium'><span className='text-orange-500  text-6xl'>.</span> Tracking</strong>
                                    <p className="text-gray-700 mb-2">Easily Track Financials.</p>
                                </li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            </div>

            <div className=" container mt-8">
                <div className="row justify-content-center">
                <h2 className="text-4xl font-bold mb-4 text-center pt-5 mt-5 pb-5">SMS Feature Comparison</h2>
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0 ">

                        <h1 className='font-semibold pt-2 text-center' style={{ color: '#f77d27', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">SMS Basic </h1>
                        <h1 className='font-semibold pt-2 text-center' style={{ color: 'gray', fontSize: '15px' }} data-aos="fade-down" data-aos-duration="2000">$995/$260 Yearly Maintenance Fee</h1>
                        <div className="w-full md:w-4/4">
                            <ul >
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
                                    <p className='text-md font-medium' ><span className='text-orange-500  text-6xl'>.</span> File Processing from Most Precision Displays. </p>
                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <p className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Sorts Data by Geography.</p>

                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <p className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Generate Crop Plans.</p>

                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <p className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Create, Manage and Export Guidance Lines.</p>

                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <p className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Print Summary Maps, Charts and Reports.</p>

                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <p className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Aerial/Road Backgrounds.</p>

                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <p className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Write Simple Prescriptions.</p>

                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <p className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Prescription Budget Tool.</p>

                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <p className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Query Tools to Analyze Specific Areas.</p>

                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <p className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Check for Updates.</p>

                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <p className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> View Current and Historical Weather for Your Fields.</p>

                                </div>
                                <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
                                    <p className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Utilize Satellite (Sentinel) Imagery to Identify Healthy Trends in Your Fields.</p>

                                </div>
                            </ul>
                        </div>

                        </div>
                    </div>
                    <div data-aos="zoom-in" data-aos-duration="1000" className="col-lg-4 col-md-6">
                        <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0 ">

                        <h1 className='font-semibold pt-2 text-center' style={{ color: '#f77d27', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">SMS Advanced </h1>
                        <h1 className='font-semibold pt-2 text-center' style={{ color: 'gray', fontSize: '15px' }} data-aos="fade-down" data-aos-duration="2000">$2995/$775 Yearly Maintenance Fee</h1>
                        <div className="w-full md:w-4/4">
                        <ul>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2000">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Project Management (5)</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Calendar View</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Dataset Playback</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> 3D Plotting and Terrain Viewing</p>
    </div>
    <div class same="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Comparison Analysis</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Correlation Analysis</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Terrain Analysis</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> NDVI Analysis</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Profit/Loss Mapping</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Backup Scheduling</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Zone Analysis</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Equation Writing</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Multi-Year Averaging</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Display Elevation Surface Contours</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Multiple Storage Locations (No Limits)</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Booklet Printing**</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Water Management**</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Field Trials**</p>
    </div>
    <div className="col-lg-12" data-aos="fade-right" data-aos-duration="2500">
        <p className='text-md font-medium'><span className='text-orange-500 text-6xl'>.</span> Plot Prescription**</p>
    </div>
</ul>

                        </div>

                        </div>
                    </div>
                    
                </div>
            </div>




           
            <div className="container mt-2 pt-20 pt-md-0">
                <div className="row  justify-content-center">

                    <div className="col-lg-8 mt-2 pb-2">
                        <h2 className="text-4xl font-bold mb-4 text-center pt-5 " >View Your Data Anywhere</h2>
                        <p className='' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">View your data anywhere, from any device when using SMS in conjunction with the AgFiniti! Export your data from SMS and view your row-by-row planting, application, harvest data, and more! Utilize your favorite mobile device whether it’s an iPad, tablet, laptop, or smartphone.</p>
                       

                    </div>

                </div>
            </div>

            <div className="container mt-2 pt-20 pt-md-0 pb-5">
                <div className="row  justify-content-center pb-5">

                    <div className="col-lg-8  pb-2">
                        <h1 h2 className="text-4xl font-bold mb-4 text-center pt-5 ">AgFiniti Connections </h1>
                        <p className='' data-aos="fade-left" style={{ textAlign: 'justify' }} data-aos-duration="2000">AgFiniti connects with other digital platforms to enable you to seamlessly map, report, and analyze all your information in one place anytime, anywhere, and on any device. Combined with the power of SMS Software, it’s the most versatile platform for gaining insights and decision making across your entire farm. </p>
                    </div>

                </div>
            </div>
            
        </div>


    )
}

export default autoarticle
