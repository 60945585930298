import React from 'react';
import { Link } from 'react-router-dom';

function DmsMainPage() {
  return (
    <div className='vid'>
      <div className="aimainsp18 container-fluid pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-6 pt-5 mt-5">
            <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Autonomous Defense Systems</h1>
            <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">
            Immerse yourself in captivating virtual environments and interactive experiences with our 3D vision solutions. From augmented reality applications that overlay digital content onto the physical world to virtual reality simulations that transport you to new realms, the possibilities are endless.
            </p>
          </div>
        </div>
      </div>

      <div className="aidetails mt-5 pt-md-0 pt-20 pb-5 pt-md-0 ">
        <div className="container mt-5 pt-20 pt-md-0">
          <div className="row mt-5 pt-5" data-aos="fade-left" data-aos-duration="2000">
            <div className="col-lg-6 mt-5 pt-5">
              <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>ScanEagle</h1>
              <p className='pt-3' style={{ textAlign: 'justify' }}>
                ScanEagle is a long-endurance unmanned aerial system (UAS) designed and manufactured by Insitu Inc., a wholly owned subsidiary of The Boeing Company. It redefines intelligence, surveillance, and reconnaissance services for Group 2 UAS.
              </p>
              <p className='pt-3' style={{ color: '#1E1E1E', textAlign: 'justify' }}>
                ScanEagle delivers persistent imagery on land or at sea for a variety of commercial and defense missions at a fraction of the cost of other surveillance methods. It can operate up to 19,500 feet and loiter over a battlefield for extended missions of 24+ hours, launching autonomously by a catapult launcher without a runway and retrieved via a patented SkyHook recovery system.
              </p>
            </div>
            <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5 ">
              <img className='img-fluid' src='https://www.boeing.com/content/theboeingcompany/us/en/defense/autonomous-systems/scaneagle/_jcr_content/root/container/container_copy_1668228877/container/carousel/item_1698189066807.coreimg.85.1600.jpeg/1702307460894/scaneagle-gallery1-960x600.jpeg' alt="ScanEagle" />
            </div>
          </div>
          <div className="row mt-5 pt-5" data-aos="fade-right" data-aos-duration="2000">
            <div className="col-lg-5 mt-5 pt-5 me-0 me-md-5">
              <img className='img-fluid' src='https://www.boeing.com/content/theboeingcompany/us/en/defense/mq25/_jcr_content/root/container/container_858146469_/container/carousel/item_1698099406426.coreimg.85.1600.jpeg/1702306903164/mq-25-1060-960x600.jpeg' alt="MQ-25" />
            </div>
            <div className="col-lg-6 mt-5 pt-5">
              <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>MQ-25</h1>
              <p className='pt-3' style={{ textAlign: 'justify' }}>
                The MQ-25 is an unmanned aircraft system designed for the U.S. Navy, providing robust refueling capability to extend the combat range of deployed Boeing F/A-18 Super Hornet, Boeing EA-18G Growler, and Lockheed Martin F-35C fighters.
              </p>
              <p className='pt-3' style={{ color: '#1E1E1E', textAlign: 'justify' }}>
                Boeing’s carrier heritage stretches back to the 1930s. The MQ-25 brings the best of Boeing's expertise, with the MQ-25 T1 test asset demonstrating successful integration into the carrier environment and refueling capabilities, making it the first unmanned aircraft in history to refuel another aircraft.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="aimainsp17 container-fluid pb-5">
        <div className="row  mt-5 ">
          <div className="container pb-5">
            <div className="row justify-content-center">
              <div className="col-lg-6 mt-5 pt-5" data-aos="fade-right" data-aos-duration="2500">
                <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }}>MQ-28</h1>
                <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }}>
                  The Boeing MQ-28 is a new uncrewed aircraft using artificial intelligence to complement and extend airborne missions. Designed to provide a disruptive advantage for allied forces, it offers fighter-like performance and can integrate sensor packages to support various missions.
                </p>
                <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }}>
                  The MQ-28 is Boeing’s first uncrewed system designed and developed in Australia, offering transformational capability for global defense customers. It leverages advanced manufacturing and digital production capabilities to support national prosperity and sovereignty objectives.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="aidetails mt-5 pt-md-0 pt-20 pb-5 pt-md-0">
        <div className="container mt-5 pt-20 pt-md-0">
          <div className="row mt-5 pt-5" data-aos="fade-left" data-aos-duration="2000">
            <div className="col-lg-6 mt-5 pt-5">
              <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>RQ-21A Blackjack</h1>
              <p className='pt-3' style={{ textAlign: 'justify' }}>
                The RQ-21A Blackjack is the U.S. Navy and Marine Corps’ Small Tactical Unmanned Aircraft System (STUAS), offering a larger payload mass, volume, and power than other systems in its class.
              </p>
              <p className='pt-3' style={{ color: '#1E1E1E', textAlign: 'justify' }}>
                The expeditionary RQ-21A Blackjack delivers day and night intelligence, surveillance, and reconnaissance capabilities, providing unique advantages in situational awareness. It is designed for launch and recovery without dedicated runways, making it highly versatile for various operational environments.
              </p>
            </div>
            <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
              <img className='img-fluid' src='https://www.boeing.com/content/theboeingcompany/us/en/defense/autonomous-systems/rq-21a-blackjack/_jcr_content/root/container/container_copy_1668228877/container/carousel/item_1698189066807.coreimg.85.1600.jpeg/1702307471361/rq21a-blackjack-gallery1-960x600.jpeg' alt="RQ-21A Blackjack" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DmsMainPage;
