import React from 'react'
import { Link } from 'react-router-dom'

function ev() {
  return (
    <div className='vid'>
        <div className="baiduaimain container-fluid">
                <div className="  row justify-content-center">
                    <div className="col-lg-6 pt-5 mt-5">
                        <h1 className='font-semibold' style={{ color: 'black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Electric Vehicles (EVs) and EV Batteries</h1>
                        <p className='pt-3' style={{ color: 'black' }} data-aos="fade-down" data-aos-duration="2000">Electric vehicle (EV) batteries represent the heart of the electric revolution, enabling sustainable and efficient mobility solutions. Understanding the intricacies of EV battery technology and the key players in battery manufacturing is essential to grasp the full potential of this burgeoning industry.</p>
                        <div className=" container mt-8">
                            <div className="row justify-content-center">
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/electric-car.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center ">Electric Vehicles </p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-lg mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/lighting.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Electric Batteries</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-4">
                                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                        <img src="/charging.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                        <p className="text-sm text-center">Charging Solutions</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="aimain container-fluid pb-5 mb-5">
                <div className=" row mt-5 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mt-5 pt-5 text-center text-lg-start">
                                <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Electric Vehicles and Related Technologies</h1>
                                <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2500">Chinese brands excel in producing a diverse range of passenger EVs, including sedans and SUVs. These vehicles combine sleek design with advanced technology, providing a smooth and sustainable driving experience for consumers. Additionally, the commercial EV sector offers electric buses and trucks, contributing to reduced emissions and operational costs in urban and logistics transportation.</p>
                                <button className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5" data-aos="fade-down" data-aos-duration="2500" ><Link to='/Electric-Vehicals'>Discover</Link></button>
                            </div>
                            <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                                <img className='img-fluid' src='/ev1.jpg' data-aos="fade-down" data-aos-duration="2500"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           

            <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5">
                        <img className='img-fluid' src='/ec1.png' data-aos="fade-down" data-aos-duration="2500"/>
                        {/* <div className="video-container" >
                    <iframe src='/dronevid.webm' className="responsive-iframe" allowfullscreen data-aos="fade-down" data-aos-duration="2000" autoplay></iframe>
                </div> */}
                        </div>
                        <div className="col-lg-6 mt-2">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Premium Smart Electric Executive Sedan </h1>
                            <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">An Unmistakably Executive Look</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">Featuring a sleak and modern EV design we have developed a state of the art vehical that is energy efficient and acts as an alternative to traditional carbon fuel vehicals.</p>
                            
                        </div>
                    </div>
                </div>

            </div>
           
            <div className="aidetails mt-5 pt-md-0 pt-5 pb-1 pt-md-0">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row pt-5">
                        
                        <div className="col-lg-6 mt-2">
                            <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Business Class-Inspired Lounge Design</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">Experience unparalleled comfort and luxury in our EVs with a Business Class-inspired cabin. Every detail has been meticulously crafted to provide a serene and sophisticated environment, reminiscent of high-end airline cabins. Sink into plush, ergonomically designed seats that offer ample legroom and adjustable settings to cater to your personal comfort preferences. </p>
                            <div className="w-full md:w-4/4">
                            <div className="row">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> PanoDisplay</strong>
                                <p className="text-sm">Each seat display offers video conferencing, travel notifications, immersive cinema, etc.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> NIO Link</strong>
                                <p className="text-sm">With car and NIO Phone connectivity, meetings will be automatically transferred to the display.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  Diverse Infotainment</strong>
                                <p className="text-sm">With abundant embedded applications, NIO Link ensures seamless wired and wireless projection.</p>
                            </div>
                            </div>
                    </div>
                        </div>
                        <div className="col-lg-5 me-0 me-md-5">
                       
                       <img src="/class.png" className='img-fluid'data-aos="fade-right" data-aos-duration="2600" />
                        </div>
                    </div>
                </div>

            </div>
            
            <div className="aidetails mt-5 pt-md-5 pt-20 pb-1  pb-5">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row pt-5 mt-5">
                        <div className="col-lg-5 me-0 me-md-5">
                        <div className="video-container" >
                    <iframe src='/charge.mp4' className="responsive-iframe" allowfullscreen data-aos="fade-down" data-aos-duration="2000" autoplay></iframe>
                </div>
                        </div>
                        <div className="col-lg-6 ">
                        <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Chargeable, Swappable, Upgradable</h1>
                            <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Recharging on the go.</h1>
                            <p className='pt-2' data-aos="fade-right" data-aos-duration="2000">Enjoy the ease of charging your vehicle with our efficient and widespread charging network, ensuring you’re always ready for the road. Need a quick power boost? Our innovative battery-swapping technology allows you to replace your battery in minutes, minimizing downtime and maximizing your journey.</p>
                            <div className="w-full md:w-4/4">
                            <div className="row">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Ready to go in 3 mins</strong>
                                <p className="text-sm">Battery swap in 3 mins</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Flexible Battery</strong>
                                <p className="text-sm">Swappable Long-term | Short-term battery</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> One-Click Service</strong>
                                <p className="text-sm">One click car service through our app.</p>
                            </div>
                            </div>
                    </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="aidetails mt-5 pt-md-0 pt-5 pb-1 pt-md-0">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row pt-5">
                        
                        <div className="col-lg-6 mt-2">
                            <h1 className='font-semibold pt-2' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">Powerful Hardware for Better Driving Experience</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">As a pioneering AI intelligent chassis in the industry, the system perceives road conditions ahead, get the suspension to the appropriate height in advance with active damping adjustments. </p>
                            <div className="w-full md:w-4/4">
                            <div className="row">
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2000">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span> Smart Air Suspension</strong>
                                <p className="text-sm">The Continous Damping Control (CDC) allows for 500 calculations per second.</p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2500">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  Smooth Stop</strong>
                                <p className="text-sm">Enabled by brake force adjustments the system can achieve smooth stops during traffic. </p>
                            </div>
                            <div className="col-lg-4" data-aos="fade-right" data-aos-duration="2600">
                                <strong className='text-md font-medium'><span className='text-orange-500  text-6xl'>.</span>  6-Pistons Calipers</strong>
                                <p className="text-sm">Enables better thermal stability and ensures higher saftey at high speeds or mountain roads. </p>
                            </div>
                            </div>
                    </div>
                        </div>
                        <div className="col-lg-5 me-0 me-md-5">
                       
                        <div className="video-container" >
                    <iframe src='/nav.mp4' className="responsive-iframe" allowfullscreen data-aos="fade-down" data-aos-duration="2000" autoplay></iframe>
                </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* <div className="smartcity container-fluid pb-5 mb-5">
                <div className=" row mt-5 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mt-5 pt-5">
                                <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">Explore Our SUV Lineup</h1>
                                <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="2500">Elevate your driving experience with our selection of stylish and spacious SUVs. With ample cargo space, advanced technology features, and exceptional safety ratings, our SUVs are designed to exceed your expectations.</p>
                                <p className='pt-3' style={{ color: 'white', textAlign: 'justify' }} data-aos="fade-down" data-aos-duration="3000">Whether you're navigating bustling city streets or conquering rugged terrain, our SUVs offer the perfect balance of power, versatility, and comfort. With advanced technology features, responsive handling, and spacious interiors, our SUVs are equipped to handle any journey with ease. Discover the freedom to roam and unleash your adventurous spirit with our exceptional SUV lineup.</p>
                            </div>
                            <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                                <img className='img-fluid' src='/cam.png' data-aos="fade-down" data-aos-duration="2500"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className="aidetails mt-5 pt-md-0 pt-20 pb-1 pt-md-0">
                <div className="container mt-5 pt-20 pt-md-0">
                    <div className="row ">
                        <div className="col-lg-5 me-0 me-md-5">
                        <img className='img-fluid' src='/securityimg.png' data-aos="fade-right" data-aos-duration="2000"/>
                        </div>
                        <div className="col-lg-6 mt-5 pt-5">
                            <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2000">SUVs powered by GAC Motors</h1>
                            <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">GS3 EMZOOM</h1>
                            <p className='pt-2' data-aos="fade-left" data-aos-duration="2000">Introducing the GS3 EMZOOM, where power meets precision and style seamlessly blends with performance. With a maximum output of 130kW and a robust maximum torque of 270N·m, this SUV is engineered to elevate your driving experience to new heights.
Equipped with a fuel tank capacity of 47 liters, the GS3 EMZOOM ensures you can embark on journeys with confidence, whether it's cruising through city streets or venturing off the beaten path. Its lightweight design, ranging from 1370kg to 1415kg curb weight, enhances agility without compromising on stability.</p>
                        </div>
                    </div>
                </div>

            </div>
           
            <div className="aidetails  pb-5 pt-md-0">
                <div className="container  pt-md-0">
                    <div className="row mt-5 pt-5">     
                        <div className="col-lg-6 pt-5">
                        <h1 className='font-semibold pt-5' style={{ color: 'Black', fontSize: '25px' }} data-aos="fade-down" data-aos-duration="2000">EMKOO</h1>
                            <p className='pt-3' style={{ color: '#1E1E1E', textAlign: 'justify' }} data-aos="fade-right" data-aos-duration="2000">With an impressive fuel consumption of 20.8 kilometers per liter, the Emkoo ensures you go farther on every drop of fuel, making it a sustainable choice for eco-conscious drivers.
Featuring a maximum output of 138kW and a robust maximum torque of 180N·m, the Emkoo delivers dynamic acceleration and seamless power delivery, whether navigating urban landscapes or exploring winding country roads.</p>
                        </div>
                        <div className="col-lg-5  me-0 me-md-5">
                            <img className='img-fluid' src='/recrtimg.png' data-aos="fade-left" data-aos-duration="2000" />
                        </div>
                    </div>
                </div>

            </div> */}

            
            
      
    </div>
  )
}

export default ev
