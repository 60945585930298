import React from 'react'
import { Link, useLocation } from 'react-router-dom';
function automationsolutions() {
  return (
    <div className='aisolutionsmainpage'>
    <div className="aimain container-fluid">
        <div className="row justify-content-center">
            <div className="col-lg-6 pt-5 mt-5">
                <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }} data-aos="fade-down" data-aos-duration="2500">Artificial Intelligence Automation Systems</h1>
                <p className='pt-3' style={{ color: 'white' }} data-aos="fade-down" data-aos-duration="2000">Our visionary approach is shaping the future landscape with unparalleled ingenuity. Through cutting-edge technologies and strategic foresight, we are driving unprecedented advancements across industries, revolutionizing the way we work, live, and interact.</p>
                <div className="container mt-8" >
                    <div className="row justify-content-center">
                        <div data-aos="zoom-in" data-aos-duration="1000" className="col-6 col-md-4">
                            <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                <img src="/ai1.png" alt="Project 1" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                <p className="text-lg text-center">TIA Portal</p>
                            </div>
                        </div>
                        <div data-aos="zoom-in" data-aos-duration="1500" className="col-6 col-md-4">
                            <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                <img src="/smart.png" alt="Project 2" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                <p className="text-lg text-center">SIMATIC AX</p>
                            </div>
                        </div>
                        <div data-aos="zoom-in" data-aos-duration="3000" className="col-6 col-md-4">
                            <div className="bg-white p-4 rounded-lg shadow-md mb-4 mr-4 md:mb-0 md:mr-0">
                                <img src="/vision.png" alt="Project 3" className="w-24 h-24 mb-2 rounded-lg mx-auto" />
                                <p className="text-lg text-center">Process Control</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="aidetails mt-5 pt-md-0 pt-20 pb-5 pt-md-0">
        <div className="container mt-5 pt-20 pt-md-0" >
            <div className="row mt-5 pt-5" data-aos="fade-left" data-aos-duration="2000">
                <div className="col-lg-6 mt-5 pt-5">   
                    <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Totally Integrated Automation Portal – Always ready for tomorrow</h1>
                    <p className='pt-3'style={{textAlign:'justify'}}>Regardless of what you need to engineer innovative machines, you’ll find it in Totally Integrated Automation Portal (TIA Portal).</p>
                    <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>It gives you unrestricted access to the complete range of digitalized automation services, from digital planning to integrated engineering and transparent operation. With TIA Portal you gain valuable competitive advantages!</p>
                </div>
                <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                    <img className='img-fluid' src='/Pattern2.png'/>
                </div>
            </div>
            <div className="row mt-5 pt-5" data-aos="fade-right" data-aos-duration="2000">
                <div className="col-lg-5 mt-5 pt-5 me-0 me-md-5">
                    <img className='img-fluid' src='/Pattern.png'/>
                </div>
                <div className="col-lg-6 mt-5 pt-5"> 
                    <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>Leading Industry with Baidu AI</h1>
                    <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Building upon the robust foundation of Baidu Brain 6.0, we offer comprehensive end-to-end solutions encompassing every facet of AI technology. From harnessing AI computing power and refining algorithms to delivering AI capabilities and facilitating seamless production, deployment, and integration, we provide a holistic approach to AI innovation. Our focus is on creating a standardized, intelligent, and modular technology-enabling platform that empowers businesses to thrive in the digital era. Whether it's optimizing workflows, enhancing customer experiences,
                     or driving strategic decision-making, our solutions are designed to unleash the full potential of AI and propel organizations towards sustainable growth and success.</p>
                     <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5" ><Link to='/baiduai'>Discover BaiduAI</Link></button>
                </div>
            </div>
        </div>
        
    </div>
    <div className="smartcity container-fluid">   
        <div className=" row mt-5 pt-5">
            <div className="container">
                <div className="row">

                
                <div className="col-lg-6 mt-5 pt-5" data-aos="fade-right" data-aos-duration="2500">
                <h1 className='font-semibold' style={{ color: 'white', fontSize: '35px' }}>Smart City Solutions powered by Citizen Friendly AI</h1>
                    <p className='pt-3'style={{color:'white', textAlign:'justify'}}>Step into a world of unparalleled efficiency and innovation. Our AI solutions aren't just transformative, they're the driving force behind industry evolution.</p>
                    <p className='pt-3' style={{color:'white',textAlign:'justify'}}>Experience the power of data-driven insights, streamlined operations, and precision decision-making. From optimizing workflows to unlocking hidden opportunities, our AI solutions are tailored to elevate your business to new heights. Join the ranks of industry leaders who trust us to deliver tangible results and unlock the full potential of AI technology. Welcome to a future where every challenge is met with a solution, and success is inevitable.</p>
                    <button className="mainbtn2  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5 ">Discover Smart City Solutions</button>
                </div>
                <div className="col-lg-5 mt-5 pt-5 ms-0 ms-md-5">
                    <img className='img-fluid' src='/smartimg.png' data-aos="fade-left" data-aos-duration="2500"/>
                </div>
            </div>
            </div>
            </div>
        </div>
        <div className="aidetails mt-5 pt-md-0 pt-20 pb-5 pt-md-0">
        <div className="container mt-5 pt-20 pt-md-0">
            <div className="row mt-5 pt-5" data-aos="fade-left" data-aos-duration="2500">
                <div className="col-lg-5 mt-5 pt-5 me-0 me-md-5">
                    <img className='img-fluid' src='/3dimg.png'/>
                </div>
                <div className="col-lg-6 mt-5 pt-5 "> 
                    <h1 className='font-semibold' style={{ color: 'Black', fontSize: '35px' }}>3D Vision & Machine Learning</h1>
                    <p className='pt-3'style={{textAlign:'justify'}}>Step into a world of unparalleled efficiency and innovation. Our AI solutions aren't just transformative, they're the driving force behind industry evolution.</p>
                    <p className='pt-3' style={{color:'#1E1E1E',textAlign:'justify'}}>Building upon the robust foundation of Baidu Brain 6.0, we offer comprehensive end-to-end solutions encompassing every facet of AI technology. From harnessing AI computing power and refining algorithms to delivering AI capabilities and facilitating seamless production, deployment, and integration, we provide a holistic approach to AI innovation. Our focus is on creating a standardized, intelligent, and modular technology-enabling platform that empowers businesses to thrive in the digital era. Whether it's optimizing workflows, enhancing customer experiences,
                     or driving strategic decision-making, our solutions are designed to unleash the full potential of AI and propel organizations towards sustainable growth and success.</p>
                     <button className="mainbtn  border  rounded-full py-2 px-6 mr-4 hover:bg-orange-600 mt-5">Discover Our 3D</button>
                </div>
            </div>
        </div>
        
    </div>
</div>
  )
}

export default automationsolutions
